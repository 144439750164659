import React, { Fragment, useState } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, H2, P, Image } from '../../../AbstractElements';
import { CURRENT_APP, EmailAddress, EmailPassword, ForgotPassword, Password, RememberPassword, signinaccount, USER_ROLES, USER_SUBSCRIPTION } from '../../Constant';
import { useDispatch } from 'react-redux';
import { USER_TYPE, USER_TOKEN, USER_DETAILS } from '../../Constant';
import { Eye, EyeOff } from 'react-feather';
import { useForm } from 'react-hook-form';
import fetchWrapper from '../../api/fetchWrapper';
import jwtDecode from 'jwt-decode';
import { API_URL } from '../../Config/Config';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Spinner, Toast } from 'react-bootstrap';
import ButtonLoader from '../ButtonLoader';
import { landingPagePreferences, logoutAction } from '../../api/helper';
import { addHours, addMinutes } from 'date-fns';
import { toast, ToastContainer } from 'react-toastify';
import SocialAuth from './SocialAuth';
import appLogo from '../../../assets/images/logo/airoi.png';
import najhumLogo from '../../../assets/images/logo/najhum.png';

const LoginForm = () => {
    const [togglePassword, setTogglePassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [checked, setChecked] = useState(false);
    const { register, formState: { errors }, handleSubmit, setError } = useForm()
    const [value, setValue] = useState(
        sessionStorage.getItem('profileURL' || man)
    );
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState(
        sessionStorage.getItem('Name')
    );
    const landingPageMenuItems = {
        1: 'projects',
        2: 'group-list',
        3: 'forecasted-dimming-dashboard',
        4: 'hvac-dashboard',
        5: 'aqi-dashboard'
    }


    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const getSavedPreference = async (userType) => {
        await fetchWrapper(`${API_URL}/preferences`)
            .then(async (res) => {
                if (res?.json?.length > 0) {
                    const data = res?.json[0];
                    console.log(res?.json[0]?.moduleId, 'check pref')
                    dispatch({
                        type: CURRENT_APP,
                        payload: res?.json[0]?.moduleId
                    })
                    navigate(`${process.env.PUBLIC_URL}/${landingPagePreferences[data?.screenId]}`);
                } else {
                    await getSubscribeMenu(userType)
                }
            }, async (err) => {
                console.log(err)
                if (err.status === 404) {
                    await getSubscribeMenu(userType)
                }
            })
    }

    const getSubscribeMenu = async (userType) => {
        await fetchWrapper(`${API_URL}/subscriptions/customer`)
            .then((res) => {
                const data = res?.json;
                dispatch({
                    type: USER_SUBSCRIPTION,
                    payload: res?.json?.filter(ele => ele?.issubscribed === true)?.map(item => ({ moduleId: item?.moduleId, name: item?.name }))
                })
                let detailObj = {};
                const result = data.reduce((acc, { moduleId, personaType }) => {
                    if (!acc[moduleId]) {
                        acc[moduleId] = [];
                    }
                    if (!acc[moduleId].includes(personaType)) {
                        acc[moduleId].push(personaType);
                    }
                    return acc;
                }, {});
                detailObj = {
                    ...result,
                    7: [...new Set(Object?.values(result)?.flat())]
                }
                dispatch({
                    type: USER_ROLES,
                    payload: detailObj
                })
                if (res?.json?.filter(ele => ele?.issubscribed === true && ele?.moduleId !== 6)?.length > 2) {
                    navigate(`${process.env.PUBLIC_URL}/apps`)
                } else {
                    dispatch({
                        type: CURRENT_APP,
                        payload: res?.json?.find((ele) => ele?.issubscribed === true)?.moduleId
                    })
                    navigate(`${process.env.PUBLIC_URL}/settings/application-settings`)
                }
            }, (err) => console.log(err))
    }

    const defaulPage = {
        ASR_USER: '/assessor/projects',
        ASM_ADMIN: '/asm-admin/projects',
        ASM_USER: '/asm-user/projects',
        ASR_ADMIN: '/asr-admin/projects',
        ASO_USER: '/aso-user/projects',
        ASO_ADMIN: '/aso-admin/projects',
        SYS_ADMIN: '/admin/projects',
        SYS_USER: '/back-office/projects'
    }

    const loadUserDetails = async () => {
        let data = {};
        await fetchWrapper(`${API_URL}/users/userProfile`)
            .then((res) => {
                if (res.status === 200) {
                    sessionStorage.setItem('suborgsEnabled', res.json?.subOrgsEnabled)
                    sessionStorage.setItem('targetEmissionsExists', res.json?.targetEmissionsExists);
                    dispatch({
                        type: USER_DETAILS,
                        payload: res.json
                    })
                    data = res?.json
                }
            }, (err) => {
                navigate(`${process.env.PUBLIC_URL}/login`);
            })
        return data;
    }

    const onSubmit = async (data) => {
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: ({
                username: data.email,
                password: data.password
            })
        };
        await fetchWrapper(`${API_URL}/sign-in`, requestOptions)
            .then(async (res) => {
                const data = res.json;
                const decodedidToken = jwtDecode(data?.idToken);
                sessionStorage.setItem('accessToken', data.accessToken);
                sessionStorage.setItem('refreshToken', data.refreshToken);
                sessionStorage.setItem('expiresIn', decodedidToken.exp);
                let userData = await loadUserDetails()
                console.log(userData?.personaType)
                sessionStorage.setItem('userType', userData?.personaType)
                dispatch({
                    type: USER_TOKEN,
                    payload: data.accessToken
                })
                dispatch({
                    type: USER_TYPE,
                    payload: userData?.personaType
                });
                sessionStorage.setItem('auth_profile', userData?.personaType);
                if (['SYS_USER', 'SYS_ADMIN', 'ASM_USER', 'ASR_ADMIN', 'ASR_USER', 'ASM_ADMIN']?.includes(userData?.personaType)) {
                    navigate(`${process.env.PUBLIC_URL}${defaulPage[userData?.personaType]}`);
                } else {
                    dispatch({
                        type: CURRENT_APP,
                        payload: null
                    })
                    await getSubscribeMenu()
                    await getSavedPreference(userData?.personaType);
                }
            }, (error) => {
                setLoading(false);
                if (error.status === 401 && error.json.errorCode === 'NEW_PASSWORD_REQUIRED') {
                    navigate(`${process.env.PUBLIC_URL}/reset-password/${data.email}`);
                } else if (error.status === 401 && error.json.errorCode === "INVALID_CREDENTIAL") {
                    setError('password', { type: 'passwordValidation', message: 'Incorrect Email ID/User ID and Password' })
                } else {
                    toast.error("Someting went wrong")
                }
            })
    };

    const handleCheckbox = () => {
        var value = checked ? false : true;
        setChecked(value);
    }

    return (
        <Fragment>
            <ToastContainer />
            <div>
                <div className='login-main'>
                    {
                        // <div className='logo'>
                        //     <Image attrImage={{ className: 'img-fluid for-light', src: najhumLogo }} />
                        // </div>
                    }
                    <Form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                        <H2 attrH2={{ className: "text-center" }}>{signinaccount}</H2>
                        <P attrPara={{ className: 'text-center' }}>{EmailPassword}</P>
                        <FormGroup className='form-group'>
                            <Label className="col-form-label">{'Email ID / User ID'} <span className='required'>*</span></Label>
                            <input
                                className="form-control"
                                name='email'
                                type="text" onChange={e => setEmail(e.target.value)}
                                {...register('email', { required: true })}
                            />
                            <span className='text-danger'>{errors.email && errors.email.type === 'required' && 'Email / User name is required'}</span>
                            {/* <span className='text-danger'>{errors.email && errors.email.type === 'validate' &&  'Enter valid Email'}</span> */}
                        </FormGroup>
                        <FormGroup className='form-group'>
                            <Label className="col-form-label">{Password} <span className='required'>*</span></Label>
                            <div className='form-input position-relative'>
                                <input className="form-control"
                                    onChange={e => setPassword(e.target.value)}
                                    type={togglePassword ? 'text' : 'password'}
                                    name='password'
                                    {...register('password', {
                                        required: true,
                                    })}
                                />
                                <div className="show-hide new_show_hide" onClick={() => setTogglePassword(!togglePassword)}>
                                    <span>{togglePassword ? <Eye className='show-icon' /> : <EyeOff className='show-icon' />}</span>
                                </div>
                                {console.log(errors)}
                                <span className='text-danger'>{errors.password && errors.password.type === 'required' && 'Password is required'}</span>
                                <span className='text-danger'>{errors.password && errors.password.type === 'passwordValidation' && errors.password.message}</span>

                            </div>
                        </FormGroup>
                        {/* <Toast onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide className='toast-width'>
                                <Toast.Body>  <span className='text-danger'> {toastMsg} </span> </Toast.Body>
                            </Toast> */}
                        <FormGroup className="form-group mb-0">
                            <div className=" p-0">
                                {/* <Input id="checkbox1" type="checkbox" checked={checked} onChange={handleCheckbox} /> */}
                                <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                            </div>
                            <Link className="link" to={`${process.env.PUBLIC_URL}/forgot-password`}>{ForgotPassword}</Link>


                            <div className='text-end mt-3' >
                                {
                                    loading ?
                                        <ButtonLoader loaderText={'Submitting'} className="btn-block" color="primary w-100" /> :
                                        <Btn attrBtn={{ color: 'primary w-100', className: 'btn-block', type: 'submit' }}>{'Log In'}</Btn>
                                }
                            </div>
                        </FormGroup>
                        {/* <SocialAuth /> */}
                    </Form>
                </div>
            </div>
        </Fragment>
    );
};

export default LoginForm;