import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Badge } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import fetchWrapper from '../../api/fetchWrapper';
import { API_URL } from '../../Config/Config';
import { Edit, Trash2 } from 'react-feather';
import AddGroupModal from './AddGroups';
import DeleteGroup from './DeleteGroup';
import { toast } from 'react-toastify';
import AgGridTable from '../AgGridTable';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const GroupList = () => {
    const [tableData, setTableData] = useState([]);
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [dataId, setDataId] = useState();
    const [asoList, setAsoList] = useState([])
    const [selectedAsoOrg, setSelectedAsoOrg] = useState();
    const [addStatus, setAddStatus] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const groupsRef = useRef(null);

    const userType = sessionStorage.getItem('userType');
    const rolesObject = useSelector(state => state?.user?.userRoles);

    const loadData = async (val) => {
        groupsRef?.current?.api?.showLoadingOverlay();
        const url = ['ASM_ADMIN', 'ASM_USER'].includes(userType) ? `/group?assetOwnerOrgId=${val}` : `/group`;
        await fetchWrapper(`${API_URL}${url}`)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    groupsRef?.current?.api?.hideOverlay();
                    setTableData(res.json)
                }
            }, (error) => {
                groupsRef?.current?.api?.hideOverlay();
                console.log(error)
                if (err.status === 404) {
                    groupsRef?.current?.api?.showNoRowsOverlay();
                }
            })
    }

    const loadAsoOrgList = async () => {
        await fetchWrapper(`${API_URL}/organization/assetmanager/mappedOrgs`)
            .then((res) => {
                const data = res.json;
                setAsoList(data?.mappedAssetOwners)
            }, (err) => { console.log(err) })
    }

    const handleDelete = (id) => {
        setDataId(id)
        setDeleteOpen(true)
    }

    const handleStatus = async (id, status) => {
        let updateStatus = status === true ? false : true;
        const options = {
            method: 'PUT'
        }
        await fetchWrapper(`${API_URL}/group/status/${id}/${updateStatus}`, options)
            .then((res) => {
                if (res.status === 200) {
                    if (res?.json?.isactive === true)
                        toast.success('Group Status enabled Successfully!')
                    else
                        toast.error('Group Status disabled Successfully!')
                    loadData()
                }
            })
            .catch(err => console.log(err))
    }

    const tableColumns = [
        {
            headerName: 'Group Code',
            field: 'groupCode',
            filter: true,
            flex: 1,
            sortable: true,
            suppressMovable: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Group Name',
            field: 'name',
            filter: true,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            }
        },
        {
            headerName: 'Description',
            field: 'description',
            filter: true,
            flex: 3,
            sortable: false,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            valueGetter: (params) => {
                return params.data.description ? params.data.description : '-'
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 0,
                buttons: ['reset']
            },
            cellClass: 'cell-wrap'
        },
        ...(rolesObject[2][0] === 'EMISSIONS_ADMIN' ? [{
            headerName: 'Status',
            filter: false,
            width: 100,
            sortable: true,
            suppressMovable: true,
            cellRenderer: (params) => {
                return (
                    <div className='mr-36'>
                        <label className="switch">
                            <input type="checkbox" id="togBtn" checked={params.data.isactive}
                                onChange={() => handleStatus(params.data.id, params.data.isactive)}
                            />
                            <div className="slider round">
                                <span className="on"></span>
                                <span className="off"></span>
                            </div>
                        </label>
                    </div>
                )
            }
        }] : []),
        ...(rolesObject[2][0] === 'EMISSIONS_ADMIN' ? [{
            headerName: 'Edit',
            width: 60,
            cellRenderer: params => {
                return (
                    <Link to={`${process.env.PUBLIC_URL}/groups/${params.data.id}`} >
                        <Edit className={`edit-icon `} />
                    </Link>
                )
            },
            suppressMovable: true,
        }] : []),
        ...(rolesObject[2][0] === 'EMISSIONS_ADMIN' ? [{
            headerName: 'Delete',
            width: 70,
            cellRenderer: params => {
                return (
                    <div onClick={() => handleDelete(params.data.id)} >
                        <Trash2 className={`trash-icon`} />
                    </div>
                )
            },
            suppressMovable: true,
        }] : [])
    ]

    const handleClick = () => {
        setOpen(true)
    }

    const loadGroupByAso = (val) => {
        setSelectedAsoOrg(val)
        loadData(val)
    }

    useEffect(() => {
        if ((addStatus === true) || (deleteStatus === true)) {
            loadData();
        }
        loadAsoOrgList();
    }, [addStatus, deleteStatus])

    const onGridReady = useCallback(async (params) => {
        loadData();
    })

    return (
        <Fragment>
            {deleteOpen && <DeleteGroup value={deleteOpen} setOpenModal={setDeleteOpen} dataId={dataId} setDelete={setDeleteStatus} />}
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <div className="d-flex mtop-1">
                            <Breadcrumbs parent="Groups" title="List" mainTitle="Groups" />
                            {
                                rolesObject[2][0] === 'EMISSIONS_ADMIN' && <div className="text-right action_button mr-13">
                                    <div className="link" onClick={handleClick}>
                                        <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                        &nbsp;&nbsp;{"Add Group"}
                                    </div>
                                </div>
                            }
                        </div>
                        <Card className="newtable">
                            <CardBody className='data-tables responsive-view ptop-0'>
                                <div>
                                    {
                                        (['ASM_ADMIN', 'ASM_USER'].includes(userType)) && <div className="d-flex aso-org-dropdown">
                                            <select className="form-select"
                                                onChange={(e) => loadGroupByAso(e.target.value)}
                                            >
                                                <option value=''>Select Asset Owner Organization</option>
                                                {
                                                    asoList?.length > 0 && asoList?.map((item) => {
                                                        return (
                                                            <option value={item?.assetOwnerId}>{item?.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    }
                                </div>
                                <div className="groups-table">
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={tableColumns}
                                            tableData={tableData}
                                            agRef={groupsRef}
                                            onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {
                    open && <AddGroupModal value={open} setOpenModal={setOpen} asoOrgId={selectedAsoOrg} setAdd={setAddStatus} />
                }
            </Container>
        </Fragment>
    )
}

export default GroupList;