import React, { Fragment, useContext, useEffect, useState } from 'react';
import { AlignCenter } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { Image, UL, LI } from '../../../AbstractElements';
import CustomizerContext from '../../../Common/_helper/Customizer';
import appLogo from '../../../assets/images/logo/app-logo@3x1.png';
import greenCarbon from '../../../assets/images/logo/gc-app.png'
import netTrace from '../../../assets/images/logo/net04.png';
import aqiLogo from '../../../assets/images/logo/aqi.png';
import decarbbonLogo from '../../../assets/images/logo/decarb.png';
import airoiLogo from '../../../assets/images/logo/airoi.png';
import netLogo from '../../../assets/images/logo/net3.jpg';
import aqi from '../../../assets/images/logo/aqiLight.png';
import decarbon from '../../../assets/images/logo/decarbonLight.png';
import { useDispatch, useSelector } from 'react-redux';
import { TbGridDots } from "react-icons/tb";
import { CURRENT_APP } from '../../Constant';
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import net0white from '../../../assets/images/logo/net0trace-white.svg';
import nettraceGreen from '../../../assets/images/logo/net0trace-home.svg';
import najhumLogo from '../../../assets/images/logo/najhum.png';
import gcWallet from '../../../assets/images/logo/gcwallet.png';

const Leftbar = () => {
    const { setToggleIcon, toggleIcon, width } = useContext(CustomizerContext);
    const app = useSelector(state => state?.user?.appName);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const subscriptionDetails = useSelector(state => state?.user?.subscriptionDetails);
    const dispatch = useDispatch();
    const notAllowedTypes = ['SYS_USER', 'SYS_ADMIN', 'ASM_USER', 'ASR_ADMIN', 'ASR_USER', 'ASM_ADMIN'];
    const userType = useSelector(state => state?.user?.userType);

    useEffect(() => {
        console.log(open)
    }, [open])

    const handleApps = (id) => {
        dispatch({
            type: CURRENT_APP,
            payload: id
        })
        navigate(`${process.env.PUBLIC_URL}/settings/application-settings`)
        setOpen(false)
    }

    return (
        <Fragment>
            <div className={`header-logo-wrapper col-auto p-0`}>
                <div className={`toggle-sidebar ${(width - 4) > 1199 && 'd-none'}`} onClick={() => setToggleIcon(!toggleIcon)}>
                    {
                        toggleIcon && <AiOutlineMenuUnfold className="status_toggle middle sidebar-toggle" id="sidebar-toggle" />
                    }
                    {
                        !toggleIcon && <AiOutlineMenuFold className="status_toggle middle sidebar-toggle" id="sidebar-toggle" />
                    }
                </div>
                <div className="logo-wrapper d-flex">
                    {
                        !notAllowedTypes?.includes(userType) && <LI attrLI={{ className: "profile-nav onhover-dropdown p-0" }} >
                            <TbGridDots className={`${(open) ? 'grid-dots-active' : 'grid-dots'} mr-1`} onClick={() => setOpen(!open)} />
                            {
                                open && <div>
                                    <UL attrUL={{ className: "profile-dropdown onhover-app-list" }}>
                                        {
                                            <LI attrLI={{ className: 'd-flex border-none pad-bot-75rem', onClick: () => navigate(`${process.env.PUBLIC_URL}/apps`) }}>
                                                <AiOutlineHome className='home-icon' /> <div className='ml-1 app-tile-font mtop-0'>Home</div>
                                            </LI>
                                        }
                                        <div className='app-dd'>
                                            <h3 className='f-s-14 mb-0'>Your Apps</h3>
                                        </div>
                                        {
                                            subscriptionDetails?.some(ele => ele?.moduleId == 1) && <LI attrLI={{ className: 'd-flex border-none border-top-none', onClick: () => handleApps(1) }}>
                                                <img src={gcWallet} width={20} height={20} /> <div className='ml-1 app-tile-font mtop-0'>Green Carbon Wallet</div>
                                            </LI>
                                        }
                                        {
                                            subscriptionDetails?.some(ele => ele?.moduleId == 2) && <LI attrLI={{ className: 'd-flex border-none border-top-none', onClick: () => handleApps(2) }}>
                                                <img src={nettraceGreen} style={{ width: '45px' }} width={25} height={25} /> <div className='ml-1 app-tile-font mtop-0'>Net0Trace</div>
                                            </LI>
                                        }
                                        {
                                            subscriptionDetails?.some(ele => ele?.moduleId == 5) && <LI attrLI={{ className: 'd-flex border-none border-top-none', onClick: () => handleApps(5) }}>
                                                <img src={aqiLogo} width={20} height={20} /> <div className='ml-1 app-tile-font mtop-0'>AQI Insights</div>
                                            </LI>
                                        }
                                        {
                                            subscriptionDetails?.some(ele => ele?.moduleId == 3) && <LI attrLI={{ className: 'd-flex border-none border-top-none', onClick: () => handleApps(3) }}>
                                                <img src={decarbbonLogo} width={20} height={20} /> <div className='ml-1 app-tile-font mtop-0'>Decarbonization Analystics</div>
                                            </LI>
                                        }
                                    </UL>
                                </div>

                            }
                        </LI>
                    }

                    <div>
                        <Image attrImage={{
                            className: `img-fluid for-light ${!app ? 'bg-fff airoi-logo' : ''}`, style: { width: (app !== 1 && app !== null) ? 50 : (app == null) ? 80 : app == 1 ? 70 : '' },
                            src: app == 1 ? appLogo : app == 2 ? net0white : app == 3 ? decarbon : app == 5 ? aqi : airoiLogo, alt: ""
                        }} />
                    </div>
                </div>

            </div>
        </Fragment >
    );
};
export default Leftbar;