import { Grid, File, UserPlus, BarChart2 } from 'react-feather';
import { HiOutlineShoppingCart, HiUsers, HiOutlineLocationMarker } from 'react-icons/hi'
import { IoWalletOutline } from 'react-icons/io5'
import { HiOutlineOfficeBuilding, HiOutlineCurrencyDollar } from 'react-icons/hi';
import { FiGrid, FiBox, FiBriefcase, FiSlack, FiUserPlus, FiWind, FiCloudLightning, FiMapPin, FiSettings, FaChartLine } from 'react-icons/fi';
import { GrCloudUpload, GrLineChart, GrPlan, GrUserSettings } from 'react-icons/gr';
import { SlEnergy, SlBriefcase } from 'react-icons/sl';
import { AiOutlineLineChart, AiOutlineGlobal, AiOutlineThunderbolt } from 'react-icons/ai'
import {
    IoBarChartOutline, IoLayersOutline, IoLeafOutline,
    IoBagHandleOutline, IoCloudUploadOutline, IoSettingsOutline, IoCloudDownloadOutline, IoCartOutline
} from 'react-icons/io5';
import { GiGreenhouse, GiChart } from "react-icons/gi";
import { HiOutlineBolt } from "react-icons/hi2";
import { FaRegChartBar, FaClipboardList, FaRegHandshake } from "react-icons/fa";
import { TfiStatsUp } from "react-icons/tfi";
import { IoListOutline, IoWaterOutline } from "react-icons/io5";
import { FiList, FiFilePlus, FiFileText } from "react-icons/fi";
import { FaUncharted, FaRegFileImage, FaRecycle } from "react-icons/fa";
import { TbFileStar } from "react-icons/tb";
import { TbFileReport } from "react-icons/tb";
import { HiOutlineBuildingLibrary, HiOutlineClipboardDocumentCheck, HiOutlineClipboardDocumentList, HiOutlineDocumentChartBar } from "react-icons/hi2"
import { GrInProgress } from "react-icons/gr";
import { TbBuildingFactory, TbBuildingFactory2 } from "react-icons/tb";
import { PiListChecksLight, PiListBulletsLight } from "react-icons/pi";
import { PiCar } from "react-icons/pi";
import { TbSunElectricity } from "react-icons/tb";
import { FiTruck } from "react-icons/fi";
import { SlNotebook } from "react-icons/sl";
import { IoMdSwitch } from "react-icons/io";
import { FaUsersGear } from "react-icons/fa6";


const CarbonCredits = {
    title: 'Carbon Credits', id: 1, icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
        {
            title: 'Projects', icon: FiBox, badge: 'true', type: 'sub', active: false, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], children: [
                { path: `${process.env.PUBLIC_URL}/projects`, icon: HiOutlineClipboardDocumentList, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], title: 'Project List', type: 'link' },
                { path: `${process.env.PUBLIC_URL}/project-initiation/Internal`, icon: FiFilePlus, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], title: 'New Project', type: 'link' },
                { path: `${process.env.PUBLIC_URL}/project-initiation/External`, icon: FiFileText, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], title: 'Existing Project', type: 'link' },
            ]
        },
        {
            title: 'Wallet', icon: IoWalletOutline, badge: 'true', type: 'link', allowedRoles: ['ASO_ADMIN', 'ASO_USER'], active: false, path: `${process.env.PUBLIC_URL}/wallet-summary`
        },
        {
            title: 'Trading', icon: HiOutlineCurrencyDollar, badge: 'true', type: 'sub', allowedRoles: ['ASO_ADMIN', 'ASO_USER'], active: false, children: [
                { path: `${process.env.PUBLIC_URL}/carbon-trading/trading`, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], icon: GiChart, title: 'Trading', type: 'link' },
                { path: `${process.env.PUBLIC_URL}/carbon-trading/orders`, allowedRoles: ['ASO_ADMIN', 'ASO_USER'], icon: IoCartOutline, title: 'Orders', type: 'link' },
            ]
        },
    ]
}

const Net0TraceMenu = {
    title: 'Net0Trace', id: 2, icon: FiWind, badge: 'true', type: 'sub', active: false, children: [
        {
            title: 'Define', icon: FiGrid, badge: 'true', type: 'sub', allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER'], active: false, children:[
                {
                    title: 'Groups', icon: IoLayersOutline, type: 'link', active: false, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER'], path: `${process.env.PUBLIC_URL}/group-list`
                },
                {
                    title: 'Locations', icon: HiOutlineLocationMarker, type: 'link', active: false, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER'], path: `${process.env.PUBLIC_URL}/location-list`
                },
                {
                    title: 'Scope Preferences', icon: IoMdSwitch, type: 'link', active: false, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER'], path: `${process.env.PUBLIC_URL}/scope-configurations`
                },
                {
                    title: 'Users', icon: GrUserSettings, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/user-list`, allowedRoles: ['EMISSIONS_ADMIN'],
                },
                {
                    title: 'Subsidiaries', icon: HiOutlineOfficeBuilding, type: 'link', active: false,
                    path: `${process.env.PUBLIC_URL}/ownership-management`, allowedRoles: ['EMISSIONS_ADMIN']
                },
            ]
        },
        {
            title: 'Capture', icon: FiGrid, badge: 'true', type: 'sub', allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST'], active: false, children: [
                {
                    title: 'Data Entry', icon: IoCloudUploadOutline, badge: 'true', type: 'link', allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST'], active: false, path: `${process.env.PUBLIC_URL}/data-capture`
                },
                {
                    title: 'Approval Queue', icon: PiListChecksLight, badge: 'true', type: 'link', allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER'], active: false, path: `${process.env.PUBLIC_URL}/data-capture/queue/approval`
                },
                {
                    title: 'Submit Queue', icon: PiListBulletsLight, badge: 'true', type: 'link', allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_ANALYST'], active: false, path: `${process.env.PUBLIC_URL}/data-capture/queue/submit`
                },
                {
                    title: 'Progress Tracker', icon: GrInProgress, badge: 'true', type: 'link', allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST'], active: false, path: `${process.env.PUBLIC_URL}/data-capture/progress`
                },
            ]
        },
        {
            title: 'Plan', icon: FiGrid, badge: 'true', type: 'sub', allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST'], active: false, children: [
                {
                    title: 'Net Zero Planner', icon: GrPlan, type: 'link', active: false, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST'], path: `${process.env.PUBLIC_URL}/target-settings`
                },
                {
                    title: 'Action Plan', icon: SlNotebook, type: 'link', active: false, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST'], path: `${process.env.PUBLIC_URL}/ghg-action-plan`
                },
            ]
        },
        {
            title: 'Analytics', icon: FiGrid, badge: 'true', type: 'sub', allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST', 'EMISSIONS_REPORT_USER'], active: false, children: [
                {
                    title: 'Executive Dashboard', icon: FaRegFileImage, type: 'link', badge: 'true', active: false, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST', 'EMISSIONS_REPORT_USER'], path: `${process.env.PUBLIC_URL}/ghg-accounting`
                },
                {
                    title: 'Target vs Actual', icon: FaUncharted, type: 'link', path: `${process.env.PUBLIC_URL}/target-net-zero-emission`, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST', 'EMISSIONS_REPORT_USER'],
                },
                {
                    title: 'Emission Insights', icon: TbFileReport, type: 'link', badge: 'true', active: false, allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST', 'EMISSIONS_REPORT_USER'], path: `${process.env.PUBLIC_URL}/emission-insights`
                }
            ]
        },
        {
            title: 'Validation', icon: FiGrid, badge: 'true', type: 'sub', allowedRoles: ['EMISSIONS_ADMIN', 'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST', 'EMISSIONS_REPORT_USER'], active: false, children: [
                {
                    title: 'Scope 1', icon: PiCar, type: 'link', badge: 'true', active: false, allowedRoles: ['EMISSIONS_ADMIN',
                        'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST', 'EMISSIONS_REPORT_USER'], path: `${process.env.PUBLIC_URL}/validation-report/scope-1`
                },
                {
                    title: 'Scope 2', icon: TbSunElectricity, type: 'link', badge: 'true', active: false, allowedRoles: ['EMISSIONS_ADMIN',
                        'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST', 'EMISSIONS_REPORT_USER'], path: `${process.env.PUBLIC_URL}/validation-report/scope-2`
                },
                {
                    title: 'Scope 3', icon: FiTruck, type: 'link', badge: 'true', active: false, allowedRoles: ['EMISSIONS_ADMIN',
                        'EMISSIONS_APPROVER', 'EMISSIONS_ANALYST', 'EMISSIONS_REPORT_USER'], path: `${process.env.PUBLIC_URL}/validation-report/scope-3`
                },
            ]
        }
    ]
}

const SupplyChainsMenu = {
    title: 'Supply Chain', id: 2, icon: FiWind, badge: 'true', type: 'sub', active: false, children: [
        {
            title: 'Suppliers Mgmt', icon: IoLayersOutline, type: 'link', active: false,
            allowedRoles: ['EMISSIONS_ADMIN'], path: `${process.env.PUBLIC_URL}/supplier-list`
        },
        {
            title: 'Approval Queue', icon: PiListChecksLight, badge: 'true', type: 'link',
            allowedRoles: ['EMISSIONS_ADMIN'], active: false, path: `${process.env.PUBLIC_URL}/supplier-mgmt/queue/approval`
        },
        {
            title: 'Progress', icon: GrInProgress, badge: 'true', type: 'link',
            allowedRoles: ['EMISSIONS_ADMIN'], active: false, path: `${process.env.PUBLIC_URL}/supplier-mgmt/progress/supplier`
        },
    ]
}


const SustainabilityReportingMenu = {
    title: 'Sustainability Reporting', id: 6, badge: 'true', type: 'sub', active: false, children: [
        {
            title: 'Business Info', icon: SlBriefcase, path: `${process.env.PUBLIC_URL}/sustainability-reporting/business-info`,
            type: 'link', allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST']
        },
        {
            title: 'Stakeholders', icon: FaUsersGear, path: `${process.env.PUBLIC_URL}/sustainability-reporting/stakeholders`,
            type: 'link', allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST']
        },
        {
            title: 'Environmental', icon: IoLeafOutline, path: `${process.env.PUBLIC_URL}/sustainability-reporting/environmental`,
            type: 'link', allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST']
        },
        {
            title: 'Social', icon: FaRegHandshake, path: `${process.env.PUBLIC_URL}/sustainability-reporting/social`,
            type: 'link', allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST']
        },
        {
            title: 'Governance', icon: HiOutlineBuildingLibrary, path: `${process.env.PUBLIC_URL}/sustainability-reporting/governance`,
            type: 'link', allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST']
        },
        {
            title: 'GRI Dashboards', icon: FiGrid, badge: 'true', type: 'sub', allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST', 'SUSTAINABILITY_REPORT_USER'], active: false, children: [
                {
                    title: 'Energy', icon: AiOutlineThunderbolt, type: 'link', badge: 'true', active: false,
                    path: `${process.env.PUBLIC_URL}/sustainability-reporting/energy`, allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST', 'SUSTAINABILITY_REPORT_USER']
                },
                {
                    title: 'Water', icon: IoWaterOutline, type: 'link', badge: 'true', active: false,
                    path: `${process.env.PUBLIC_URL}/sustainability-reporting/water`, allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST', 'SUSTAINABILITY_REPORT_USER']
                },
                {
                    title: 'Emission', icon: TbBuildingFactory, type: 'link', badge: 'true', active: false,
                    path: `${process.env.PUBLIC_URL}/sustainability-reporting/emission`, allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST', 'SUSTAINABILITY_REPORT_USER']
                },
                {
                    title: 'Effluents & Waste', icon: FaRecycle, type: 'link', badge: 'true', active: false,
                    path: `${process.env.PUBLIC_URL}/sustainability-reporting/waste`, allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST', 'SUSTAINABILITY_REPORT_USER']
                }
            ]
        },
        {
            title: 'Reporting', icon: HiOutlineDocumentChartBar, type: 'link', allowedRoles: ['SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST'], path: `${process.env.PUBLIC_URL}/sustainability-reporting/reporting`
        },
    ]
}

const DecarbonizationMenu = {
    title: 'Decarbonization Analytics', id: 3, icon: FiCloudLightning, badge: 'true', type: 'sub', active: false, children: [
        {
            title: 'Forecasted Dimming', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/forecasted-dimming-dashboard`, allowedRoles: ['DECARB_ADMIN', 'DECARB_ANALYST']
        },
        {
            title: 'Saved Energy', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/saved-energy-dashboard`, allowedRoles: ['DECARB_ADMIN', 'DECARB_ANALYST']
        },
        {
            title: 'Dimming Manager', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/dimming-manager-dashboard`, allowedRoles: ['DECARB_ADMIN', 'DECARB_ANALYST']
        },
        {
            title: 'Forecasted Lifespan', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/forecasted-lifespan-dashboard`, allowedRoles: ['DECARB_ADMIN', 'DECARB_ANALYST']
        }
    ]
}


const HVACInsight = {
    title: 'HVAC Insights', id: 4, icon: BarChart2, badge: 'true', type: 'sub', active: false, allowedRoles: ['HVAC_ADMIN', 'HVAC_ANALYST'], children: [
        {
            title: 'Dashboard', icon: AiOutlineLineChart, type: 'link', path: `${process.env.PUBLIC_URL}/hvac-dashboard`, allowedRoles: ['HVAC_ADMIN', 'HVAC_ANALYST']
        },
    ]
}

const AQIInsights = {
    title: 'AQI Insights', id: 5, icon: TfiStatsUp, badge: 'true', type: 'sub', active: false, allowedRoles: ['AQI_ADMIN', 'AQI_ANALYST'], children: [
        {
            title: 'Dashboard', icon: IoBarChartOutline, type: 'link', path: `${process.env.PUBLIC_URL}/aqi-dashboard`, allowedRoles: ['AQI_ADMIN', 'AQI_ANALYST']
        },
    ]
}

const defaultMenu = {
    title: 'Settings', id: 7, icon: IoSettingsOutline, badge: 'true', type: 'sub', active: false, children: [
        {
            title: 'Application', icon: FiSettings, path: `${process.env.PUBLIC_URL}/settings/application-settings`, moduleId: [1, 2, 3, 5],
            type: 'link', allowedRoles: ['ASO_ADMIN', 'ASO_USER', 'EMISSIONS_ADMIN', 'EMISSIONS_ANALYST', 'EMISSIONS_APPROVER',
                'DECARB_ADMIN', 'DECARB_ANALYST', 'AQI_ADMIN', 'AQI_ANALYST', 'HVAC_ADMIN', 'HVAC_ANALYST', 'SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST', 'EMISSIONS_REPORT_USER', 'SUSTAINABILITY_REPORT_USER']
        },
        {
            title: 'Downloads', icon: IoCloudDownloadOutline, type: 'link', active: false, moduleId: [2, 3, 5], path: `${process.env.PUBLIC_URL}/downloads`, allowedRoles: ['ASO_ADMIN', 'ASO_USER', 'EMISSIONS_ADMIN', 'EMISSIONS_ANALYST', 'EMISSIONS_APPROVER',
                'DECARB_ADMIN', 'DECARB_ANALYST', 'AQI_ADMIN', 'AQI_ANALYST', 'HVAC_ADMIN', 'HVAC_ANALYST', 'SUSTAINABILITY_ADMIN', 'SUSTAINABILITY_ANALYST', 'EMISSIONS_REPORT_USER', 'SUSTAINABILITY_REPORT_USER']
        },
        {
            title: 'Users', icon: GrUserSettings, type: 'link', moduleId: [1, 3, 5], active: false, path: `${process.env.PUBLIC_URL}/user-list`, allowedRoles: ['ASO_ADMIN', 'EMISSIONS_ADMIN', 'DECARB_ADMIN', 'AQI_ADMIN', 'HVAC_ADMIN', 'SUSTAINABILITY_ADMIN'],
        },
    ]
}

const SYS_USER = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Dashboard', icon: FiGrid, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/back-office/dashboard`
            },
            {
                title: 'Projects', icon: FiBox, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/back-office/projects`
            },
            {
                title: 'Organizations', icon: HiOutlineOfficeBuilding, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/back-office/customer-list`
            },
            {
                title: 'Orders', icon: HiOutlineShoppingCart, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/back-office/orders`
            },
            {
                title: 'Subscriptions', icon: FiBriefcase, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/back-office/module-origanization`
            },
        ]
    },
];

const SYS_ADMIN = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            // {
            //     title: 'Customer Projects', icon: FiGrid, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/dashboard`
            // },
            {
                title: 'Carbon Credit Report', icon: FiGrid, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/carbon-credits-summary`
            },
            {
                title: 'Projects', icon: FiBox, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/projects`
            },
            {
                title: 'Organizations', icon: HiOutlineOfficeBuilding, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/customer-list`
            },
            {
                title: 'Customer Mgmt', icon: HiOutlineOfficeBuilding, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/customer-management`
            },
            {
                title: 'Carbon Trades', icon: HiOutlineShoppingCart, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/orders`
            },
            {
                title: 'Subscriptions', icon: FiBriefcase, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/module-origanization`
            },
            {
                title: 'Credit Rates', icon: FiSlack, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/credit-rates`
            },
            {
                title: 'Exchange Rates', icon: HiOutlineCurrencyDollar, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/exchange-rate-list`
            },
            {
                title: 'Emission Factors', icon: IoListOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/admin/emission-factor-list`
            },
        ]
    }
]

const ASM_ADMIN = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Carbon Credits', id: 1, icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Projects', icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-admin/projects`, title: 'Project List', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-admin/project-initiation/Internal`, title: 'New Project', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-admin/project-initiation/External`, title: 'Existing Project', type: 'link' },
                        ]
                    },
                    {
                        title: 'Wallet Details', icon: IoWalletOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/wallet-details`

                    },
                    {
                        title: 'Trading', icon: HiOutlineCurrencyDollar, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-admin/carbon-trading/trading`, title: 'Trading', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-admin/carbon-trading/orders`, title: 'Orders', type: 'link' },
                        ]
                    },
                    {
                        title: 'Dashboard', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-admin/baseline-calculation`, title: 'Baseline Calculation', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-admin/monthly-reports`, title: 'Monthly Reports', type: 'link' },
                        ]
                    }
                ]
            },
            {
                title: 'GHG Emission', id: 2, icon: FiWind, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Groups', icon: IoLayersOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/group-list`
                    },
                    {
                        title: 'Location', icon: FiMapPin, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/location-list`
                    },
                    {
                        title: 'Data Upload', icon: GrCloudUpload, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/carbon-emission-list`
                    },
                    {
                        title: 'Target settings', icon: FiSettings, type: 'link', active: false
                    },
                    {
                        title: 'Buy Offsets', icon: IoBagHandleOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/buy-offsets`
                    },
                    {
                        title: 'Dashboard', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            // { path: `${process.env.PUBLIC_URL}/asm-admin/dashboard`, title: 'ESG', type: 'link' },
                            {
                                title: 'GHG Accounting', icon: GiGreenhouse, type: 'link', badge: 'true', active: false, path: `${process.env.PUBLIC_URL}/user/ghg-accounting`
                            },
                            { path: `${process.env.PUBLIC_URL}/asm-admin/gri-dashboard`, title: 'GRI Reports', type: 'link' },
                        ]
                    },
                ]
            },
            {
                title: 'Decarbonization Analytics', id: 3, icon: FiCloudLightning, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Forecasted Dimming', icon: SlEnergy, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/forecasted-dimming-dashboard`
                    },
                    {
                        title: 'Saved Energy', icon: SlEnergy, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/saved-energy-dashboard`
                    },
                    {
                        title: 'Dimming Manager', icon: SlEnergy, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/dimming-manager-dashboard`
                    },
                    {
                        title: 'Forecasted Lifespan', icon: SlEnergy, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/forecasted-lifespan-dashboard`
                    }
                ]
            },
            {
                title: 'HVAC Insights', id: 4, icon: GrLineChart, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: AiOutlineLineChart, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/hvac-dashboard`
                    },
                ]
            },
            {
                title: 'AQI Insights', id: 5, icon: FaRegChartBar, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: IoBarChartOutline, type: 'link', path: `${process.env.PUBLIC_URL}/asm-admin/aqi-dashboard`
                    },
                ]
            },
            {
                title: 'Users', icon: UserPlus, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-admin/user-list`
            }
        ]
    }
]


const ASM_USER = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            {
                title: 'Carbon Credits', id: 1, icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Projects', icon: FiBox, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-user/projects`, title: 'Project List', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/project-initiation/Internal`, title: 'New Project', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/project-initiation/External`, title: 'Existing Project', type: 'link' },
                        ]
                    },
                    {
                        title: 'Wallet', icon: IoWalletOutline, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-user/wallet-summary`, title: 'Summary', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/wallet-details`, title: 'Detail View', type: 'link' },
                        ]
                    },
                    {
                        title: 'Trading', icon: HiOutlineCurrencyDollar, badge: 'true', type: 'sub', active: false, children: [
                            { path: `${process.env.PUBLIC_URL}/asm-user/carbon-trading/trading`, title: 'Trading', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/carbon-trading/orders`, title: 'Orders', type: 'link' },
                        ]
                    },
                ]
            },
            {
                title: 'GHG Emission', id: 2, icon: FiWind, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Groups', icon: IoLayersOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-user/group-list`
                    },
                    {
                        title: 'Location', icon: HiOutlineLocationMarker, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-user/location-list`
                    },
                    {
                        title: 'Data Upload', icon: GrCloudUpload, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-user/carbon-emission-list`
                    },
                    {
                        title: 'Target settings', icon: FiSettings, type: 'link', active: false
                    },
                    {
                        title: 'Buy Offsets', icon: IoBagHandleOutline, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asm-user/buy-offsets`
                    },
                    {
                        title: 'Dashboard', icon: FiGrid, badge: 'true', type: 'sub', active: false, children: [
                            // { path: `${process.env.PUBLIC_URL}/asm-user/dashboard`, title: 'ESG', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/ghg-emission-dashboard`, title: 'ESG', type: 'link' },
                            { path: `${process.env.PUBLIC_URL}/asm-user/gri-dashboard`, title: 'GRI Reports', type: 'link' },
                        ]
                    },
                ]
            },
            {
                title: 'Decarbonization Analytics', id: 3, icon: FiCloudLightning, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Forecasted Dimming', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/forecasted-dimming-dashboard`
                    },
                    {
                        title: 'Saved Energy', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/saved-energy-dashboard`
                    },
                    {
                        title: 'Dimming Manager', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/dimming-manager-dashboard`
                    },
                    {
                        title: 'Forecasted Lifespan', icon: HiOutlineBolt, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/forecasted-lifespan-dashboard`
                    }
                ]
            },
            {
                title: 'HVAC Insights', id: 4, icon: GrLineChart, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: AiOutlineLineChart, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/hvac-dashboard`
                    },
                ]
            },
            {
                title: 'AQI Insights', id: 5, icon: FaRegChartBar, badge: 'true', type: 'sub', active: false, children: [
                    {
                        title: 'Dashboard', icon: IoBarChartOutline, type: 'link', path: `${process.env.PUBLIC_URL}/asm-user/aqi-dashboard`
                    },
                ]
            },
        ]
    }
]

const ASR_USER = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            // {
            //     title: 'Dashboard', icon: FiGrid, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/dashboard`
            // },
            {
                title: 'Project List', icon: FiBox, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/assessor/projects`
            }
        ]
    }
]




const ASR_ADMIN = [
    {
        menutitle: '',
        menucontent: 'Dashboards,Widgets',
        Items: [
            // {
            //     title: 'Dashboard', icon: FiGrid, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asr-admin/dashboard`
            // },
            {
                title: 'Project List', icon: FiBox, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asr-admin/projects`
            },
            {
                title: 'Users', icon: UserPlus, type: 'link', active: false, path: `${process.env.PUBLIC_URL}/asr-admin/user-list`
            }
        ]
    }
]

export const MenuItem = {
    SYS_USER, SYS_ADMIN, ASM_USER, ASR_ADMIN, ASR_USER, ASM_ADMIN, CarbonCredits, Net0TraceMenu,
    SustainabilityReportingMenu, DecarbonizationMenu, HVACInsight, AQIInsights, defaultMenu, SupplyChainsMenu
}