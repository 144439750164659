import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import { Button } from "reactstrap";
import moment from "moment";
import { ESimpactData, greivanceData, materialData, ruralAreaWage, supplierMaterial } from "../../Settings/settingshelper";
import fetchWrapper from "../../../api/fetchWrapper";
import { API_URL } from "../../../Config/Config";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const InclusiveEquitable = ({ setActive, financialYear, previousYear, previous2Years, mode }) => {
    const [socialImpactData, setSocialImpactData] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })))
    const [rehabilitationData, setRehabilitation] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })))
    const currency = useSelector(state => state?.user?.userDetails?.currency);
    const [grievanceDetails, setGrievanceDetails] = useState();
    const [supplierMaterialData, setSupplierMaterial] = useState(supplierMaterial);
    const [ruralAreaWageData, setRuralAreaWageData] = useState(ruralAreaWage);
    const [socialImpactMitigationData, setSocialImpactMitigation] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })));
    const [csrInitiativeData, setCsrInitiativeData] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })))
    const [procurementPolicy, setProcurementPolicy] = useState()
    const [procurableGroups, setProcurableGroups] = useState();
    const [procurementPercentage, setProcurementPercentage] = useState();
    const [traditionalKnowledgeData, setTraditionalKnowledge] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })));
    const [regulatoryComplianceData, setRegulatoryData] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })))
    const [csrProjectBeneficiaryData, setCsrProjectData] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })))

    const getSocialImpactAssessmentData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/socialImpactAssessmentData/${financialYear}`)
            .then((res) => {
                setSocialImpactData(res.json)
            }, (err) => {
                console.log(err)
            })
    }

    const getRehabilitationResettlementData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/rehabilitationResettlementData/${financialYear}`)
            .then((res) => {
                setRehabilitation(res.json)
            }, (err) => {
                console.log(err)
            })
    }

    const getSupplierMaterialPercentageData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/supplierMaterialPercentageData/${financialYear}`)
            .then((res) => {
                const tempData = supplierMaterial?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                })
                setSupplierMaterial(tempData);
            }, (err) => {
                console.log(err)
            })
    }

    const getRuralAreaWagePercentageData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/ruralAreaWagePercentageData/${financialYear}`)
            .then((res) => {
                const tempData = ruralAreaWage?.map(ele => {
                    const temp = res?.json?.find(item => item?.location === ele?.location);
                    return temp ? temp : ele;
                })
                console.log(tempData)
                setRuralAreaWageData([...tempData]);
            }, (err) => {
                console.log(err)
            })
    }

    const getSocialImpactMitigationData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/socialImpactMitigationData/${financialYear}`)
            .then((res) => {
                setSocialImpactMitigation(res.json)
            }, (err) => {
                console.log(err)
            })
    }

    const getCsrInitiativesDistrictsData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/csrInitiativesDistrictsData/${financialYear}`)
            .then((res) => {
                setCsrInitiativeData(res.json)
            }, (err) => {
                console.log(err)
            })
    }

    const getTraditionalKnowledgeData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/traditionalKnowledgeData/${financialYear}`)
            .then((res) => {
                setTraditionalKnowledge(res.json)
            }, (err) => {
                console.log(err)
            })
    }

    const getRegulatoryComplianceData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/regulatoryComplianceData/${financialYear}`)
            .then((res) => {
                setRegulatoryData(res.json)
            }, (err) => {
                console.log(err)
            })
    }

    const getCsrProjectBeneficiariesData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/csrProjectBeneficiariesData/${financialYear}`)
            .then((res) => {
                setCsrProjectData(res.json)
            }, (err) => {
                console.log(err)
            })
    }

    const getAttributesData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/attributesKeyValueData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const data = res.json?.filter(ele => ele.groupName === 'inclusiveEquitableDevelopment');
                setGrievanceDetails(data?.find(ele => ele.key === 'Q49')?.value);
                setProcurementPolicy(data?.find(ele => ele.key === 'Q50')?.value);
                setProcurableGroups(data?.find(ele => ele.key === 'Q51')?.value);
                setProcurementPercentage(data?.find(ele => ele.key === 'Q52')?.value);
            }, (err) => console.log(err))
    }

    useEffect(() => {
        if (financialYear) {
            getSocialImpactAssessmentData()
            getRehabilitationResettlementData()
            getSupplierMaterialPercentageData()
            getRuralAreaWagePercentageData()
            getSocialImpactMitigationData()
            getCsrInitiativesDistrictsData()
            getTraditionalKnowledgeData()
            getRegulatoryComplianceData()
            getCsrProjectBeneficiariesData()
            getAttributesData()
        }
    }, [financialYear])

    const socialImpactAssessmentColumn = [
        {
            headerName: 'Name and brief details of project',
            field: 'projectDetails',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'SIA Notification No',
            field: 'siaNotificationNo',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agTextCellEditor',
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Date of Notification',
            field: 'notificationDate',
            flex: 1,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agDateCellEditor',
            valueGetter: (params) => {
                if (params.data.notificationDate) {
                    params.data.notificationDate = moment(params.data?.notificationDate).format('DD MMM, YYYY')
                    return params.data?.notificationDate;
                }
            },
        },
        {
            headerName: 'Independent External Agency(Y/N)',
            field: 'conductedByExternalAgency',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            filter: false,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            flex: 1.5,
            sortable: false
        },
        {
            headerName: 'Publicly communicated results?',
            field: 'resultsPublicDomain',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            filter: false,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            flex: 1.5,
            sortable: false
        },
        {
            headerName: 'Relevant Web link',
            field: 'webLink',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agTextCellEditor',
            filter: false,
            flex: 1,
            cellRenderer: (params) => {
                if (params.data.webLink) {
                    return (
                        <a href={params.data.webLink} target="_blank">{params.data.webLink}</a>
                    )
                }
            },
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
    ];

    const rehabilitationResettlementColumns = [
        {
            headerName: 'Name of Project for which R&R is ongoing  ',
            field: 'projectName',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'State',
            field: 'state',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agTextCellEditor',
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'District',
            field: 'district',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agTextCellEditor',
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: `No. of Project Affected Families`,
            field: 'numberOfPafs',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.numberOfPafs ?
                    thousandSeparator(params?.data?.numberOfPafs) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
        {
            headerName: `% of PAFs covered`,
            field: 'percentagePafsCovered',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.percentagePafsCovered ?
                    thousandSeparator(params?.data?.percentagePafsCovered) : ''}</div>;
            },
            flex: 1,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
        {
            headerName: `Amounts paid to PAF(in ${currency})`,
            field: 'amountsPaidInFY',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.amountsPaidInFY ?
                    thousandSeparator(params?.data?.amountsPaidInFY) : ''}</div>;
            },
            flex: 1,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
    ];

    const supplierMaterialColumns = [
        {
            headerName: '',
            field: 'particulars',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear} (%)` : 'FY _____ (%)',
            field: 'currentFYValue',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.currentFYValue ?
                    thousandSeparator(params?.data?.currentFYValue) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
        {
            headerName: financialYear ? `${previousYear} (%)` : 'FY _____ (%)',
            field: 'previousFYValue',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.previousFYValue ?
                    thousandSeparator(params?.data?.previousFYValue) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        }
    ]

    const ruralAreaWageColumns = [
        {
            headerName: '',
            field: 'location',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear} (%)` : 'FY _____ (%)',
            field: 'currentFYValue',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.currentFYValue ?
                    thousandSeparator(params?.data?.currentFYValue) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
        {
            headerName: financialYear ? `${previousYear} (%)` : 'FY _____ (%)',
            field: 'previousFYValue',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.previousFYValue ?
                    thousandSeparator(params?.data?.previousFYValue) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        }
    ]

    const csrInitiativeColumns = [
        {
            headerName: 'State',
            field: 'state',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agTextCellEditor',
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'District',
            field: 'aspirationalDistrict',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agTextCellEditor',
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: `Amount spent(in ${currency})`,
            field: 'amountSpent',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.amountSpent ?
                    thousandSeparator(params?.data?.amountSpent) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
    ];

    const traditionalKnowledgeColumns = [
        {
            headerName: 'Intellectual Property based on traditional knowledge',
            field: 'intellectualProperty',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Owned/ Acquired (Yes/No)',
            field: 'ownedAcquired',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            filter: false,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            flex: 1,
            sortable: false
        },
        {
            headerName: 'Benefit shared (Yes / No)',
            field: 'benefitShared',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            filter: false,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            flex: 1,
            sortable: false
        },
        {
            headerName: 'Basis of calculating benefit share',
            field: 'basisOfCalculatingBenefitShare',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
    ]

    const regulatoryComplianceColumns = [
        {
            headerName: 'Name of authority',
            field: 'authorityName',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Brief of the case',
            field: 'caseBrief',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Corrective action taken',
            field: 'correctiveAction',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
    ];

    const csrProjectBeneficiaryColumns = [
        {
            headerName: 'CSR Project',
            field: 'csrProject',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: `No. of persons benefitted from CSR Projects`,
            field: 'beneficiaries',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.beneficiaries ?
                    thousandSeparator(params?.data?.beneficiaries) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
        {
            headerName: `% of beneficiaries from vulnerable and marginalized groups`,
            field: 'inclusionRate',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                max: 100
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.inclusionRate ?
                    thousandSeparator(params?.data?.inclusionRate) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
    ]

    const onSubmit = async () => {
        const bodyData = {
            financialYear: financialYear,
            socialImpactAssessmentData: socialImpactData
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            rehabilitationResettlementData: rehabilitationData
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            supplierMaterialPercentageData: supplierMaterialData
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            ruralAreaWagePercentageData: ruralAreaWageData
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            socialImpactMitigationData: socialImpactMitigationData
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            csrInitiativesDistrictsData: csrInitiativeData
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            traditionalKnowledgeData: traditionalKnowledgeData
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            regulatoryComplianceData: regulatoryComplianceData
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            csrProjectBeneficiariesData: csrProjectBeneficiaryData
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            attributesKeyValueData: [
                {
                    serialNo: 8,
                    groupName: 'inclusiveEquitableDevelopment',
                    key: 'Q49',
                    value: grievanceDetails
                },
                {
                    serialNo: 8,
                    groupName: 'inclusiveEquitableDevelopment',
                    key: 'Q50',
                    value: procurementPolicy
                },
                {
                    serialNo: 8,
                    groupName: 'inclusiveEquitableDevelopment',
                    key: 'Q51',
                    value: procurableGroups
                },
                {
                    serialNo: 8,
                    groupName: 'inclusiveEquitableDevelopment',
                    key: 'Q52',
                    value: procurementPercentage
                },
            ]
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
                if (err.status === 200) {
                    setActive(7);
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    const socialImpactMitigationColumn = [
        {
            headerName: 'Details of negative social impact identified',
            field: 'negativeImpactIdentified',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Corrective action taken',
            field: 'correctiveActionTaken',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        }
    ];

    return (
        <Fragment>
            <h3>Inclusive Equitable Development</h3>
            <h4 className="mtop-1">Essential Indicators</h4>
            <div className="mtop-1 company-profile">
                <h5>1. Details of Social Impact Assessments (SIA) of projects undertaken
                    by the entity based on applicable laws, in the current financial year.</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={socialImpactData}
                        columnData={socialImpactAssessmentColumn}
                    />
                    <CustomFooter handleAdd={() => setSocialImpactData([...socialImpactData, { serialNo: socialImpactData?.length + 1 }])} />
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>2. Provide information on project(s) for which ongoing Rehabilitation and
                    Resettlement (R&R) is being undertaken by your entity, in the following format</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={rehabilitationData}
                        columnData={rehabilitationResettlementColumns}
                    />
                    <CustomFooter handleAdd={() => setRehabilitation([...rehabilitationData, { serialNo: rehabilitationData?.length + 1 }])} />
                </div>
            </div>
            <div className="mtop-1 d-flex">
                <h5 className="col-6">
                    3. Describe the mechanisms to receive and redress grievances of the community.
                </h5>
                <textarea className="form-control ml-1" value={grievanceDetails} rows={3}
                    onChange={(e) => setGrievanceDetails(e.target.value)} />
            </div>
            <div className="mtop-1 company-profile">
                <h5>4. Percentage of input material (inputs to total inputs by value) sourced from suppliers</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={supplierMaterialData}
                        columnData={supplierMaterialColumns}
                    />
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>5. Job creation in smaller towns – Disclose wages paid to persons employed (including employees or
                    workers employed on a permanent or non-permanent / on contract basis) in the following locations, as % of total wage cost.</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={ruralAreaWageData}
                        columnData={ruralAreaWageColumns}
                    />
                </div>
            </div>
            <h4 className="mtop-1">Leadership Indicators</h4>
            <div className="mtop-1 company-profile">
                <h5>1. Provide details of actions taken to mitigate any negative social impacts identified in the Social Impact Assessments (Reference: Question 1 of Essential Indicators above):</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={socialImpactMitigationData}
                        columnData={socialImpactMitigationColumn}
                    />
                    <CustomFooter handleAdd={() => setSocialImpactMitigation([...socialImpactMitigationData, { serialNo: socialImpactMitigationData?.length + 1 }])} />
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>2. Provide the following information on CSR projects undertaken by your entity in designated aspirational districts as identified by government bodies</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={csrInitiativeData}
                        columnData={csrInitiativeColumns}
                    />
                    <CustomFooter handleAdd={() => setCsrInitiativeData([...csrInitiativeData, { serialNo: csrInitiativeData?.length + 1 }])} />
                </div>
            </div>
            <div className="d-flex mtop-1">
                <h5 className="col-6">3. (a) Do you have a preferential procurement policy where you give preference to purchase
                    from suppliers comprising marginalized /vulnerable groups? (Yes/No)</h5>
                <div className="col-6">
                    <select className="form-select" value={procurementPolicy} disabled={mode === 'Edit' ? false : true}
                        onChange={(e) => setProcurementPolicy(e.target.value)}>
                        <option value={''}>-Please select-</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
            </div>
            <div className="d-flex mtop-1">
                <h5 className="col-6">(b) From which marginalized /vulnerable groups do you procure?</h5>
                <div className="col-6">
                    <input type="text" className="form-control" value={procurableGroups} disabled={mode === 'Edit' ? false : true}
                        onChange={(e) => setProcurableGroups(e.target.value)} />
                </div>
            </div>
            <div className="d-flex mtop-1">
                <h5 className="col-6">(c) What percentage of total procurement (by value) does it constitute?  </h5>
                <div className="col-6">
                    <input type="number" className="form-control" value={procurementPercentage} disabled={mode === 'Edit' ? false : true}
                        onBlur={(e) => (e.target.value > 100 || e.target.value < 0) ? setProcurementPercentage(0) : setProcurementPercentage(e.target.value)}
                        onChange={(e) => setProcurementPercentage(e.target.value)} />
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>4. Details of the benefits derived and shared from the intellectual properties owned or acquired by your entity (in the current financial year), based on traditional knowledge</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={traditionalKnowledgeData}
                        columnData={traditionalKnowledgeColumns}
                    />
                    <CustomFooter handleAdd={() => setTraditionalKnowledge([...traditionalKnowledgeData, { serialNo: traditionalKnowledgeData?.length + 1 }])} />
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>5. Details of corrective actions taken or underway, based on any adverse order in intellectual property related disputes wherein usage of traditional knowledge is involved</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={regulatoryComplianceData}
                        columnData={regulatoryComplianceColumns}
                    />
                    <CustomFooter handleAdd={() => setRegulatoryData([...regulatoryComplianceData, { serialNo: regulatoryComplianceData?.length + 1 }])} />
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>6. Details of beneficiaries of CSR Projects</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={csrProjectBeneficiaryData}
                        columnData={csrProjectBeneficiaryColumns}
                    />
                    <CustomFooter handleAdd={() => setCsrProjectData([...csrProjectBeneficiaryData, { serialNo: csrProjectBeneficiaryData?.length + 1 }])} />
                </div>
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(5)}>Back</Button>
                <Button color="primary" disabled={(!financialYear || (mode !== 'Edit')) ? true : false} className="ml-1" onClick={onSubmit}>Next</Button>
            </div>
        </Fragment>
    )
}

export default InclusiveEquitable;