import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Badge } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import AgGridTable from '../../AgGridTable';
import { IoEllipsisVertical } from "react-icons/io5";

const dummyJson = [
    {
        stakeholderName: 'Operations Leadership Team',
        description: 'Team responsible for overseeing daily business operations, supply chain, and resource management',
        topics: 'Resource efficiency, Operational sustainability, Waste management, Compliance with environmental regulations',
        engagement: 'Quarterly meetings, Workshops on process optimization, Collaborative brainstorming sessions',
        contacts: 8
    },
    {
        stakeholderName: 'IT Department Heads',
        description: 'Department managing the company’s IT infrastructure, cybersecurity, and digital transformation initiatives.',
        topics: 'Energy-efficient IT systems, Data security and privacy, Green IT practices, Digital innovation for sustainability',
        engagement: 'Monthly check-ins, IT sustainability task force meetings, Hackathons for green tech solutions',
        contacts: 5
    }
]

const StakeholderList = () => {
    const [tableData, setTableData] = useState(dummyJson);
    const stakeholderRef = useRef(null);

    const columndata = [
        {
            headerName: 'Stakholder Name',
            field: 'stakeholderName',
            filter: false,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap',

        },
        {
            headerName: 'Description',
            field: 'description',
            filter: false,
            flex: 3,
            sortable: false,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap'
        },
        {
            headerName: 'Topics',
            field: 'topics',
            filter: false,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap',
        },
        {
            headerName: 'Engagement',
            field: 'engagement',
            filter: false,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap',
        },
        {
            headerName: 'No. of Contacts',
            field: 'contacts',
            filter: false,
            flex: 1.5,
            sortable: true,
            suppressMovable: true,
            autoHeight: true,
            wrapText: true,
            cellClass: 'cell-wrap',
        },
        {
            headerName: '',
            flex: 0.5,
            cellRenderer: (params) => {
                return (
                    <div><IoEllipsisVertical /></div>
                )
            }
        }
    ]


    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col lg='12'>
                        <div className="d-flex mtop-1">
                            <Breadcrumbs mainTitle="Stakeholders" />
                            {
                                <div className="text-right action_button mr-13">
                                    <div className="link" >
                                        <i style={{ fontSize: '25px', color: "#2b7704" }} className="icofont  icofont-plus-circle" ></i>
                                        &nbsp;&nbsp;{"Add Stakeholder"}
                                    </div>
                                </div>
                            }
                        </div>
                        <Card className="newtable">
                            <CardBody className='data-tables responsive-view ptop-0'>
                                <div className="groups-table">
                                    <div className="ag-theme-material ag-style" style={{ width: '100%' }}>
                                        <AgGridTable
                                            columnData={columndata}
                                            tableData={tableData}
                                            agRef={stakeholderRef}
                                            // onGridReady={onGridReady}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default StakeholderList;