import React, { Fragment, useCallback, useState, useEffect, useMemo } from "react";
import { CardBody, Spinner, Table } from "reactstrap";
import {
    Container,
    Row,
    Card,
    Col,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap";
import { H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm } from "react-hook-form";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { toast } from "react-toastify";
import uploadSignimg from "../../../assets/images/upload-sign.svg"
import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component-with-filter';
import moment from "moment";
import Loader from "../../Layout/Loader";
import { defaultUserType } from '../../api/helper';
import ButtonLoader from '../ButtonLoader';
import { Download } from "react-feather";
import LayoutLoader from '../LayoutLoader/LayoutLoader';
import Dropzone from 'react-dropzone-uploader';

const CertifyCredits = () => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [totalAmount, setTotalAmount] = useState()
    const [uncertifiedData, setUncertified] = useState();
    const [notes, setNotes] = useState();
    const [transactionId, setTransactionId] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const type = sessionStorage.getItem('userType')
    const [docLoading, setDocLoading] = useState(false)
    const [fileArray, setFileArray] = useState([]);
    const [sizeExceed, setSizeExceed] = useState(false);
    const projectId = window.location.pathname.split('/')[3];
    const [transactionEntryId, setTransactionEntryId] = useState([]);

    const handleChange = ({ selectedRows }) => {
        if (selectedRows?.length <= 10) {
            console.log(selectedRows)
            setSelectedRows(selectedRows);
            let total = 0;
            selectedRows?.map(i => {
                total = total + i.receiverAccountEntry?.amount
            })
            setTotalAmount(total)
            let transactionEntryId = selectedRows?.map(i => i?.receiverAccountEntry?.transactionEntryId)
            setTransactionEntryId(transactionEntryId);
            let transactionId = selectedRows?.map(i => i?.transactionId);
            setTransactionId(transactionId);
        } else {
            toast.error("Cannot certify more than 10 records. Please select upto 10 records")
        }
    };


    const loadUncertifiedData = async () => {

        await fetchWrapper(`${API_URL}/projectcredits/uncertifiedcredits/project/${projectId}`)
            .then(res => {
                console.log(res)
                if (res.status == 200) {

                    setUncertified(res.json)
                }
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        loadUncertifiedData()
    }, [])


    const columnsData = [
        {
            name: 'Issued Date',
            selector: row => moment(row.transactionTime).format('DD MMM, YYYY'),
            sortable: true,
            center: false,
        },
        {
            name: 'Amount',
            selector: row => `${Number(row.receiverAccountEntry?.amount).toFixed(2)}`,
            sortable: true,
            center: false,
        },
        {
            name: 'Unit Type',
            selector: row => `${row.receiverAccountEntry?.currency}`,
            sortable: true,
            autoWidth: false,
        },
        {
            name: 'Issued Notes',
            selector: row => `${row.receiverAccountEntry?.notes ? row.receiverAccountEntry?.notes : ''}`,
            sortable: true,
            autoWidth: false,
            center: false,
        },
    ]

    const customStyles = {
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontSize: '15px',
                width: '30%',
                minWidth: '50px !important'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
                width: '30%',
                minWidth: '50px !important'
            },
        },
    }

    const handleCancel = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[type]}/projects`)
    }
    const handleBack = () => {
        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[type]}/view-project/${projectId}`)
    }

    const handleUpload = async (e) => {
        console.log(4546)
        setDocLoading(true)
        if (fileArray && fileArray.length < 5) {

            let file = e.target.files[0]
            if (file.size < 4194304) {
                const authToken = sessionStorage.getItem('accessToken')
                let data = new FormData();
                data.append('file', e.target.files[0])
                data.append('documentTypeName', 'Others')
                data.append('otherDocumentTypeName', 'Certify')
                const options = {
                    method: 'POST',
                    headers: {
                        "Authorization": `Bearer ${authToken}`
                    },
                    body: data
                }
                await fetchWrapper(`${API_URL}/document/credits?projectId=${projectId}`, options)
                    .then((res) => {
                        console.log(res)
                        if (res.ok) {
                            let arr = [...fileArray];
                            arr.push(res.json);
                            setFileArray(arr);
                            setSizeExceed(false)
                            setDocLoading(false)
                            toast.success('Document Uploaded successfully!')
                        }
                    })
                    .catch((error) => {
                        setDocLoading(false)
                        console.error(error)
                    })
            } else {
                setSizeExceed(true)
                setDocLoading(false)
            }
        }
    }

    const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

    const handleSubmit = async () => {
        if (transactionEntryId?.length < 11) {
            setLoading(true)
            const projectId = window.location.pathname.split('/')[3]
            const options = {
                method: 'POST',
                body: {
                    projectId: projectId,
                    certificationNotes: notes,
                    transactionEntryIds: transactionEntryId,
                    transactionIds: transactionId
                }
            }
            await fetchWrapper(`${API_URL}/projectcredits/credits/certify`, options)
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        setLoading(false)
                        toast.success('Credits Certified Successfully!')
                        loadUncertifiedData()
                        setTransactionId()
                        setSelectedRows()
                        navigate(`${process.env.PUBLIC_URL}/${defaultUserType[type]}/view-project/${projectId}`)
                    }
                })
                .catch(err => {
                    if (err.status === 406) {
                        setLoading(false)
                        toast.error("Cannot certify more than 10 records. Please select upto 10 records")
                    }
                    console.log(err)
                })
        } else {
            toast.error("Cannot certify more than 10 records. Please select upto 10 records")
        }
    }
    const loadData = async () => {
        await fetchWrapper(`${API_URL}/document/${projectId}/Certify`)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setFileArray(res.json)
                }
            }, (error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        loadData();
    }, [])

    return (
        <Fragment>
            <LayoutLoader />
            <Breadcrumbs parent="Projects" title="Certify Credits" mainTitle='Certify Credits' />
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg='12'>
                                    <DataTable
                                        data={uncertifiedData}
                                        columns={columnsData}
                                        customStyles={customStyles}
                                        striped={true}
                                        center={true}
                                        selectableRows
                                        onSelectedRowsChange={handleChange}
                                        pagination
                                    />
                                    {
                                        totalAmount > 0 && <div className="d-flex mtop-2">
                                            <H3>Total to be Certified</H3>
                                            <h4 className="ml-6">{totalAmount}</h4>
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <div className="">
                                {
                                    uncertifiedData?.length > 0 && <Row>
                                        <Col sm='6'>
                                            <label>Notes </label>
                                            <textarea rows={4} cols={25} className="form-control" placeholder="Notes..." onChange={(e) => setNotes(e.target.value)} />
                                        </Col>
                                        <Col sm='6'>
                                            <div className="dz-message needsclick">
                                                <Dropzone
                                                    maxFiles={1}
                                                    multiple={false}
                                                    accept='.xlsx,.xls, .pdf, .doc, .txt, .jpeg, .kml'
                                                    getUploadParams={getUploadParams}
                                                    onChangeStatus={handleUpload}
                                                    maxSizeBytes={4194304}
                                                    inputContent="Drag and Drop Files"
                                                />
                                            </div>
                                            {/* <div className="mtop-2">
                                                <label id="uploadBtn"  >
                                                    <input type='file'
                                                        onChange={handleUpload} hidden accept='.xlsx,.xls, .pdf, .doc, .txt, .jpeg, .jpg'
                                                    />
                                                    Upload
                                                    {
                                                        docLoading ? <Spinner style={{ height: "15px", width: "15px" }} className="ml-1" color="#2d2d2d" /> : <img style={{ height: "10px", width: "10px" }} className="ml-1" src={uploadSignimg} />
                                                    }
                                                </label>
                                            </div> */}
                                            <div>
                                                {
                                                    fileArray && fileArray.length === 5 && <span className='text-danger'>Only 5 docs are allowed</span>
                                                }
                                                {
                                                    sizeExceed && <span className='text-danger'>File size should be below 4 MB</span>
                                                }
                                            </div>
                                            <p className='no-wrap'>[Note:Supported formats xlsx,xls,PDF,Doc,jpeg and file size should be 4MB per file]</p>

                                        </Col>
                                    </Row>
                                }

                            </div>

                            <Row>
                                <Col sm='6'>
                                    <div className="mt-40" >
                                        
                                        {
                                            loading ? <ButtonLoader color='primary' className='btn-font' loaderText={'Submitting'} />
                                                : uncertifiedData?.length > 0 && <Button color='primary' className='btn-font ml-1' onClick={handleSubmit} >Submit</Button>
                                        }

                                        <Button color="secondary" className='btn-font ml-1' onClick={handleCancel} >Cancel</Button>

                                        <Button color='warning' className='btn-font ml-1' onClick={handleBack} >Back</Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card >
                        <CardBody>
                            <Row>
                                <Col lg='12'>
                                    <Table striped bordered>
                                        <thead className='f-16'>
                                            <tr className="notes-table-header">
                                                <th>Document Name</th>
                                                <th>Size</th>
                                                <th>Download</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                fileArray && fileArray.length > 0 && fileArray.map((item) => {
                                                    return (
                                                        <tr className="notes-table-body">
                                                            <td>{item.originalFileName}</td>
                                                            <td>{(item.size / 1024).toFixed()}KB</td>
                                                            <td><a href={item.path} target='_blank'><Download className='document-icon' /></a></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                    {
                                        fileArray && fileArray?.length == 0 && <div className='d-flex-center mtop-2'>No Records Found..</div>
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </Fragment>
    )
}

export default CertifyCredits;