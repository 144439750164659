import React, { Fragment, useEffect, useState } from "react";
import AgGridDatasheet from "../../GhgDataCapture/AgGridDatasheet";
import { Button, Col, FormGroup, Row } from "reactstrap";
import {
    businessOpenness, complaintsData, misleadingData, nonmonetaryPenaltyData, penaltyData,
    policyTemplateData, trainingData
} from "../../Settings/settingshelper";
import fetchWrapper from '../../../api/fetchWrapper';
import { API_URL } from '../../../Config/Config';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { currencySymbol } from "../../../api/currencyData";
import { CustomFooter, thousandSeparator } from "../../../api/helper";
import moment from "moment";

const EthicalTransparentAccountability = ({ setActive, financialYear, previousYear, previous2Years, mode }) => {
    const [trainignCoverageData, setTrainingCoverage] = useState(trainingData);
    const [penaltyDisclosure, setPenaltyDisclosure] = useState(penaltyData);
    const [nonMonetaryPenalty, setNonMonetary] = useState(nonmonetaryPenaltyData);
    const [casesData, setCasesData] = useState([...Array(2)].map((_, index) => ({ serialNo: index + 1 })));
    const userCurrency = useSelector(state => state?.user?.userDetails?.currency);
    const [antiCorruptionDetails, setAntiCorruption] = useState();
    const [misleadingPersonData, setMisleadingPersonData] = useState(misleadingData);
    const [complaintDetails, setComplaintDetails] = useState(complaintsData);
    const [conflictData, setConflictData] = useState();
    const [currentPayableDays, setCurrentPayableDays] = useState();
    const [previousPayableDays, setPreviousPayableDays] = useState();
    const [programmesCount, setProgramCount] = useState();
    const [programTopic, setProgramTopic] = useState();
    const [programPercentage, setProgramPercentage] = useState();
    const [interestOfBoardMembers, setInterest] = useState('');
    const [conflictManagement, setConflictManagement] = useState();
    const [businessOpennessData, setBusinessOpennessData] = useState(businessOpenness);
    const [daysPaybleData, setPayableData] = useState();
    const [valueChainAwarenessData, setValueChainAwarenessData] = useState()

    const getPayableDaysData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/daysPayableData/${financialYear}`)
            .then((res) => {
                console.log(res?.json[0])
                setPayableData(res.json)
                setCurrentPayableDays(res?.json[0]?.currentFinancialYearDays)
                setPreviousPayableDays(res?.json[0]?.previousFinancialYearDays)
            }, (err) => { console.log(err) })
    }

    const getValueChainAwarenessData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/valueChainAwarenessData/${financialYear}`)
            .then((res) => {
                console.log(res?.json[0])
                setValueChainAwarenessData(res.json)
                setProgramCount(res?.json[0]?.totalProgrammesHeld)
                setProgramTopic(res?.json[0]?.topicsCovered)
                setProgramPercentage(res?.json[0]?.percentagePartnersCovered)
            }, (err) => { console.log(err) })
    }

    const getTrainingProgramData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/trainingProgramsData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const tempArray = trainingData?.map(ele => {
                    const temp = res?.json?.find(item => item?.segment === ele?.segment);
                    return temp ? temp : ele;
                })
                console.log(tempArray)
                setTrainingCoverage(tempArray)
            }, (err) => {
                console.log(err)
            })
    }

    const getRegulatoryActionsData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/regulatoryActionsData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const tempMonetaryArray = penaltyData?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                })
                setPenaltyDisclosure(tempMonetaryArray)
                const tempNonMonetaryArray = nonmonetaryPenaltyData?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                })
                setNonMonetary(tempNonMonetaryArray)
            }, (err) => {
                console.log(err)
            })
    }

    const getRegulatoryAgencyData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/regulatoryAgencyData/${financialYear}`)
            .then((res) => {
                let data = res?.json?.filter(ele => ele?.caseDetails)?.map((item) =>
                    ({ ...item, caseDetails: item?.caseDetails, regulatoryName: item?.regulatoryName }))
                data?.length > 0 ? setCasesData(data) : setCasesData([...Array(2)].map((_, index) => ({ serialNo: index + 1 })))
            }, (err) => {
                console.log(err)
            })
    }

    const getAttributesData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/attributesKeyValueData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const data = res.json?.filter(ele => ele.groupName === 'ethicalTransparent');
                setAntiCorruption(data?.find(ele => ele.key === 'Q3')?.value);
                setConflictData(data?.find(ele => ele.key === 'Q4')?.value);
                setInterest(data?.find(ele => ele.key === 'Q5')?.value)
                setConflictManagement(data?.find(ele => ele.key === 'Q6')?.value)
            }, (err) => console.log(err))
    }

    const getDisciplinaryActionData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/disciplinaryActionData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const tempArray = misleadingData?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                });
                setMisleadingPersonData(tempArray)
            }, (err) => console.log(err))
    }

    const getConflictOfInterestComplaintsData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/conflictOfInterestComplaintsData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const tempArray = complaintsData?.map(ele => {
                    const temp = res?.json?.find(item => item?.particulars === ele?.particulars);
                    return temp ? temp : ele;
                });
                setComplaintDetails(tempArray)
            }, (err) => console.log(err))
    }

    const getBusinessOpennessData = async () => {
        await fetchWrapper(`${API_URL}/emissionsReport/businessOpennessData/${financialYear}`)
            .then((res) => {
                console.log(res.json)
                const tempArray = businessOpenness?.map(ele => {
                    const temp = res?.json?.find(item => item?.metrics === ele?.metrics);
                    temp.parameter = ele?.parameter;
                    return temp ? temp : ele;
                });
                console.log(tempArray)
                setBusinessOpennessData(tempArray)
            }, (err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (financialYear) {
            getTrainingProgramData()
            getRegulatoryActionsData()
            getRegulatoryAgencyData()
            getAttributesData()
            getDisciplinaryActionData()
            getConflictOfInterestComplaintsData()
            getPayableDaysData()
            getBusinessOpennessData()
            getValueChainAwarenessData()
        }
    }, [financialYear])

    const businessOpennessColumn = [
        {
            headerName: 'Parameter',
            field: 'parameter',
            editable: false,
            filter: false,
            flex: 1,
            sortable: false,
            rowSpan: (params) => {
                return params.data.parameter === 'Share of RPT’s in' ? 6 : 4;
            },
            cellClass: 'cell-wrap disable-cell',
            cellClassRules: {
                "rowSpan-style": "value !== ''"
            }
        },
        {
            headerName: 'Metrics',
            field: 'metrics',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false,
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            field: 'currentFinancialYearDetails',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: (params) => {
                return {
                    min: 0,
                    max: params.data.type === 'percentage' ? 100 : 10000000
                }
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.currentFinancialYearDetails ?
                    thousandSeparator(params?.data?.currentFinancialYearDetails) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            field: 'previousFinancialYearDetails',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: (params) => {
                return {
                    min: 0,
                    max: params.data.type === 'percentage' ? 100 : 10000000
                }
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.previousFinancialYearDetails ?
                    thousandSeparator(params?.data?.previousFinancialYearDetails) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        }
    ]


    const traingCoverageColumns = [
        {
            headerName: 'Segment',
            field: 'segment',
            editable: false,
            filter: false,
            flex: 1,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Total number of training and awareness programmes held',
            field: 'totalTrainingPrograms',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.totalTrainingPrograms ?
                    thousandSeparator(params?.data?.totalTrainingPrograms) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
        {
            headerName: 'Topics/principles covered under the training and its impact',
            field: 'topicsCovered',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Category coverage percentage in awareness programs',
            field: 'percentageCovered',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0,
                precision: 2,
                max: 100
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.percentageCovered ?
                    thousandSeparator(params?.data?.percentageCovered.toFixed(2)) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
    ]

    const penaltyColumns = [
        {
            headerName: '',
            field: 'particulars',
            editable: false,
            filter: false,
            flex: 1.5,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Responsible Business Guidelines principle',
            field: 'ngrbcPrinciple',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Regulatory/Enforcement agencies/Judicial',
            field: 'regulatoryAgency',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false,

        },
        {
            headerName: `Amount (in ${currencySymbol[userCurrency]})`,
            field: 'amount',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.amount ?
                    thousandSeparator(params?.data?.amount) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
        {
            headerName: 'Brief of the case',
            field: 'caseBrief',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Has an appeal been preferred?',
            field: 'appealPreferred',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Yes', 'No']
            },
            filter: false,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            flex: 1.5,
            sortable: false
        }
    ]

    const caseDetailsColumn = [
        {
            headerName: 'Case details',
            field: 'caseDetails',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 1,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: 'Regulatory/Enforcement agencies/Judicial',
            field: 'regulatoryName',
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 250
            },
            filter: false,
            flex: 2,
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
    ];

    const misLeadingCountsColumn = [
        {
            headerName: '',
            field: 'particulars',
            editable: false,
            filter: false,
            flex: 1.5,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            field: 'currentFinancialYear',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.currentFinancialYear ?
                    thousandSeparator(params?.data?.currentFinancialYear) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            field: 'previousFinancialYear',
            filter: false,
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                min: 0
            },
            cellRenderer: (params) => {
                return <div className="text-right w-100">{params?.data?.previousFinancialYear ?
                    thousandSeparator(params?.data?.previousFinancialYear) : ''}</div>;
            },
            flex: 2,
            editable: mode === 'Edit' ? true : false,
            cellClass: mode === 'Edit' ? '' : 'disable-cell',
            sortable: true
        }
    ];

    const conflictColumns = [
        {
            headerName: '',
            field: 'particulars',
            editable: false,
            filter: false,
            flex: 2,
            cellClass: 'disable-cell',
            autoHeight: true,
            wrapText: true,
            sortable: false
        },
        {
            headerName: financialYear ? `${financialYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'Number',
                    field: 'currentFinancialYearNumber',
                    editable: mode === 'Edit' ? true : false,
                    cellClass: mode === 'Edit' ? '' : 'disable-cell',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Remarks',
                    field: 'currentFinancialYearRemarks',
                    editable: mode === 'Edit' ? true : false,
                    cellClass: mode === 'Edit' ? '' : 'disable-cell',
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        },
        {
            headerName: financialYear ? `${previousYear}` : 'FY _____',
            marryChildren: true,
            headerGroupComponent: 'spannedHeaderComponent',
            headerClass: 'group-header-align f-w-500',
            children: [
                {
                    headerName: 'Number',
                    field: 'previousFinancialYearNumber',
                    editable: mode === 'Edit' ? true : false,
                    cellClass: mode === 'Edit' ? '' : 'disable-cell',
                    cellEditor: 'agNumberCellEditor',
                    cellEditorParams: {
                        min: 0,
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
                {
                    headerName: 'Remarks',
                    field: 'previousFinancialYearNumberRemarks',
                    editable: mode === 'Edit' ? true : false,
                    cellClass: mode === 'Edit' ? '' : 'disable-cell',
                    cellEditor: 'agLargeTextCellEditor',
                    cellEditorPopup: true,
                    cellEditorParams: {
                        maxLength: 250
                    },
                    flex: 1,
                    headerClass: 'group-header-class, border-top-group-header-cell'
                },
            ]
        }
    ]


    const gridOptions = {
        groupHeaderHeight: 35, // Set the height of the group header row
        headerHeight: 70,
    }

    const onSubmit = async () => {
        let payableData = [], valueChainAwareness = [];
        if (daysPaybleData) {
            payableData = [...daysPaybleData];
            payableData[0].currentFinancialYearDays = Number(currentPayableDays);
            payableData[0].previousFinancialYearDays = Number(previousPayableDays);
        } else {
            payableData = [
                {
                    serialNo: 1,
                    particulars: "Number of days of accounts payable",
                    currentFinancialYearDays: Number(currentPayableDays),
                    previousFinancialYearDays: Number(previousPayableDays)
                }
            ]
        }
        if (valueChainAwarenessData) {
            valueChainAwareness = [...valueChainAwarenessData];
            valueChainAwareness[0].totalProgrammesHeld = programmesCount;
            valueChainAwareness[0].percentagePartnersCovered = programPercentage;
            valueChainAwareness[0].topicsCovered = programTopic;
        } else {
            valueChainAwareness = [
                {
                    serialNo: 1,
                    totalProgrammesHeld: programmesCount,
                    topicsCovered: programTopic,
                    percentagePartnersCovered: programPercentage,
                    financialYear: financialYear
                }
            ]
        }
        const bodyData = {
            financialYear: financialYear,
            trainingProgramsData: trainignCoverageData
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            regulatoryActionsData: [
                ...penaltyDisclosure
                    ?.map((item, index) => ({ ...item, actionType: 'Monetary', serialNo: index + 1 })),
                ...nonMonetaryPenalty
                    ?.map((item, index) => ({ ...item, actionType: 'Non-Monetary', serialNo: 3 + index }))
            ],
            regulatoryAgencyData: casesData
                ?.filter(ele => Object.keys(ele)?.length > 1)
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            disciplinaryActionData: misleadingPersonData
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            conflictOfInterestComplaintsData: complaintDetails
                ?.map((item, index) => ({ ...item, serialNo: index + 1 })),
            businessOpennessData: businessOpennessData
                ?.map((ele, index) =>
                ({
                    ...ele, parameter: ele?.parameter ? ele?.parameter :
                        (index > 0 && index < 3) ? businessOpennessData[0]?.parameter :
                            (index > 2 && index < 6) ? businessOpennessData[3]?.parameter :
                                (index > 6) ? businessOpennessData[6]?.parameter : '', serialNo: index + 1
                })),
            daysPayableData: payableData,
            valueChainAwarenessData: valueChainAwareness,
            attributesKeyValueData: [
                {
                    serialNo: 1,
                    groupName: 'ethicalTransparent',
                    key: 'Q3',
                    value: antiCorruptionDetails
                },
                {
                    serialNo: 1,
                    groupName: 'ethicalTransparent',
                    key: 'Q4',
                    value: conflictData
                },
                {
                    serialNo: 1,
                    groupName: 'ethicalTransparent',
                    key: 'Q5',
                    value: interestOfBoardMembers
                },
                {
                    serialNo: 1,
                    groupName: 'ethicalTransparent',
                    key: 'Q6',
                    value: conflictManagement
                },
            ]
        }
        const options = {
            method: 'POST',
            body: bodyData
        }
        await fetchWrapper(`${API_URL}/emissionsReport`, options)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
                if (err.status === 200) {
                    toast.success('Governance data saved successfully!')
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    return (
        <Fragment>
            <h3>Ethical Transparent Accountability</h3>
            <h4 className="mtop-1">Essential Indicators</h4>
            <div className="mtop-1 company-profile">
                <h5>1. Training Coverage Percentage</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={trainignCoverageData}
                        columnData={traingCoverageColumns}
                    />
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>2. Disclosure of Fines, Penalties, or Settlements Paid by Entity or Directors/KMPs in Financial Year</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={penaltyDisclosure}
                        columnData={penaltyColumns.map((ele, index) => ({ ...ele, headerName: index === 0 ? 'Monetary' : ele.headerName }))}
                        defaultColDef={{
                            wrapHeaderText: true,
                            autoHeaderHeight: true,
                        }}
                    />
                </div>
                <div style={{ width: '100%' }} className="ag-datasheet mtop-1">
                    <AgGridDatasheet
                        tableData={nonMonetaryPenalty}
                        columnData={penaltyColumns?.filter(field => field.field !== 'amount').map((ele, index) => ({ ...ele, headerName: index === 0 ? 'Non - Monetary' : ele.headerName }))}
                    />
                </div>
            </div>
            <div className="mtop-1 company-profile">
                <h5>3. Details of Appeals/Revisions Filed for Disclosed Monetary or Non-Monetary Actions</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={casesData}
                        columnData={caseDetailsColumn}
                    />
                    <CustomFooter handleAdd={() => setCasesData([...casesData, { serialNo: casesData?.length + 1 }])} />
                </div>
            </div>
            <Col lg={12} className="mtop-1 d-flex">
                <h5 className="col-6">4 Does the entity have an anti-corruption or anti-bribery policy? If yes, provide details in brief
                    and if available, provide a web-link to the policy.</h5>
                <textarea className="form-control ml-1 mtop-1" value={antiCorruptionDetails} rows={3} disabled={mode === 'Edit' ? false : true}
                    onChange={(e) => setAntiCorruption(e.target.value)} />
            </Col>
            <div className="mtop-1 company-profile">
                <h5>5. Count of Directors/KMPs/Employees/Workers Disciplined for Bribery/Corruption Charges by Law Enforcement</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={misleadingPersonData}
                        columnData={misLeadingCountsColumn}
                    />
                </div>
            </div>
            <div className="company-profile">
                <div className="mtop-1 ag-group-header">
                    <h5>6. Details of complaints with regard to conflict of interest</h5>
                    <div className="ngrbc-review">
                        <div style={{ width: '100%' }} className="ag-datasheet">
                            <AgGridDatasheet
                                tableData={complaintDetails}
                                columnData={conflictColumns}
                                gridOptions={gridOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Col lg={12} className="mtop-1 d-flex">
                <h5 className="col-6">7. Provide details of any corrective action taken or underway on issues related to fines/penalties/
                    action taken by regulators/law enforcement agencies/judicial institutions, on cases of corruption and
                    conflicts of interest.</h5>
                <textarea className="form-control ml-1" value={conflictData} rows={3} disabled={mode === 'Edit' ? false : true}
                    onChange={(e) => setConflictData(e.target.value)} />
            </Col>
            <h5 className="mtop-1">8. Number of days of accounts payables ((Accounts payable *365) / Cost of goods/services procured) in the following format</h5>
            <Row >
                <Col lg='6'>
                    <FormGroup>
                        <label>In current year({financialYear})</label>
                        <input type="number" className="form-control" disabled={mode === 'Edit' ? false : true}
                            value={currentPayableDays} onChange={(e) => setCurrentPayableDays(e.target.value)} />
                    </FormGroup>
                </Col>
                <Col lg='6'>
                    <FormGroup>
                        <label>In previous year({previousYear})</label>
                        <input type="number" className="form-control" disabled={mode === 'Edit' ? false : true}
                            value={previousPayableDays} onChange={(e) => setPreviousPayableDays(e.target.value)} />
                    </FormGroup>
                </Col>
            </Row>
            <div className="mtop-1 company-profile">
                <h5>9. Open-ness of business</h5>
                <div style={{ width: '100%' }} className="ag-datasheet">
                    <AgGridDatasheet
                        tableData={businessOpennessData}
                        columnData={businessOpennessColumn}
                    />
                </div>
            </div>
            <h4 className="mtop-1">Leadership Indicators</h4>
            <h5 className="mtop-1">1. Awareness programmes conducted for value chain partners on any of the
                principles during the financial year</h5>
            <Col lg='12' className="mtop-1 d-flex">
                <Col lg='6'>
                    <label>Total number of awareness programmes held</label>
                </Col>
                <input type="number" className="form-control" disabled={mode === 'Edit' ? false : true}
                    value={programmesCount}
                    onChange={(e) => setProgramCount(e.target.value)} />
            </Col>
            <Col lg='12' className="mtop-1 d-flex">
                <Col lg='6'>
                    <label>Topics / principles covered under the training</label>
                </Col>
                <input type="text" className="form-control" disabled={mode === 'Edit' ? false : true}
                    value={programTopic}
                    onChange={(e) => setProgramTopic(e.target.value)} />
            </Col>
            <Col lg='12' className="mtop-1 d-flex">
                <Col lg='6'>
                    <label>Percentage of value chain partners covered (by value of business done with such partners) under the awareness programmes </label>
                </Col>

                <input type="number" className="form-control" disabled={mode === 'Edit' ? false : true}
                    value={programPercentage}
                    onBlur={(e) => (e.target.value > 100 || e.target.value < 0) ? setProgramPercentage(0) : setProgramPercentage(e.target.value)}
                    onChange={(e) => setProgramPercentage(e.target.value)} />
            </Col>
            <h5 className="mtop-1">2. Does the entity have processes in place to avoid/ manage conflict of interests involving members of the Board? (Yes/No)
                If yes, provide details of the same.</h5>
            <div className="d-flex">
                <div className="col-6">
                    <select className="form-select" value={interestOfBoardMembers} disabled={mode === 'Edit' ? false : true}
                        onChange={(e) => setInterest(e.target.value)}>
                        <option value={''}>-Please select-</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>

                {
                    interestOfBoardMembers === 'Yes' && <div className="col-6">
                        <textarea className="form-control ml-1 mtop-1"
                            value={conflictManagement} rows={3} disabled={mode === 'Edit' ? false : true}
                            onChange={(e) => setConflictManagement(e.target.value)} />
                    </div>
                }
            </div>
            <div className="d-flex mtop-1" >
                <Button color="secondary" onClick={() => setActive(6)}>Back</Button>
                <Button color="primary" disabled={(!financialYear || (mode !== 'Edit')) ? true : false} className="ml-1" onClick={onSubmit}>Submit</Button>
            </div>
        </Fragment>
    )
}

export default EthicalTransparentAccountability;