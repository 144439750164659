import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, FormGroup, Form, Col, Button } from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { X } from "react-feather";
import fetchWrapper from "../../api/fetchWrapper";
import { API_URL } from "../../Config/Config";
import { Controller, useForm } from "react-hook-form";
import { CustomInput, handleDate } from "../../api/helper";
import DatePicker from 'react-datepicker';
import ButtonLoader from "../ButtonLoader";
import { toast } from "react-toastify";
import moment from "moment";

const AddSubOrganization = (props) => {
    const [open, setOpen] = useState(props.value);
    const [orgList, setOrgList] = useState([]);
    const [childOrgId, setChildOrgId] = useState();
    const [controlType, setControlType] = useState();
    const [controlPercentage, setControlPercentage] = useState();
    const [effectiveDate, setEffectiveDate] = useState()
    const [endDate, setEndDate] = useState();
    const { register, handleSubmit, formState: { errors }, reset, setValue, control, getValues, resetField } = useForm();
    const [loading, setLoading] = useState(false);
    const orgControl = {
        'Equity': 'EQUITY',
        'Operational': 'OPERATIONAL',
        'Financial': 'FINANCIAL'
    }
    const onClose = () => {
        setOpen(false);
        props.setOpenModal(false)
    }

    const getOrgList = async () => {
        await fetchWrapper(`${API_URL}/organization`)
            .then((res) => {
                console.log(res.json, props.childOrgs)
                setOrgList(res?.json?.filter(ele => (ele?.orgType === 'ASSET_OWNER' && !props.childOrgs?.some(item => item?.childOrgId === ele?.id))))
            }, (err) => console.log(err))
    }

    useEffect(() => {
        getOrgList()
    }, [])

    const onSubmit = async (data) => {
        let bodydata = {
            ...data,
            effectiveDate: moment(data?.effectiveDate).format('YYYY-MM-DD'),
            endDate: data?.endDate ? moment(data?.endDate).format('YYYY-MM-DD') : null,
        }
        const options = {
            method: 'POST',
            body: bodydata
        }
        await fetchWrapper(`${API_URL}/ownership`, options)
            .then((res) => {
                console.log(res.json)

            }, (err) => {
                console.log(err)
                if (err.status === 200) {
                    toast.success('Child organization relationship successfully created!')
                    props.setAdd(true)
                    onClose()
                } else if (err.status === 500) {
                    toast.error('Something went wrong')
                }
            })
    }

    return (
        <Fragment>
            <Modal centered={true} isOpen={open} size='lg'>
                <ModalHeader style={{ display: 'flex' }}>
                    <H3 attrH3={{ className: 'modal-title-color' }}>New Child Organization
                    </H3>
                    <X className="x-button" onClick={onClose} />
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <FormGroup className="mb-6">
                                <label>Child Organization <span className='required'>*</span></label>
                                <select className="form-select" name='childOrgId' value={childOrgId}
                                    {...register('childOrgId', { required: true })}
                                    onChange={(e) => setChildOrgId(e.target.value)}>
                                    <option value={''}>-Not Applicable-</option>
                                    {
                                        orgList?.filter(ele => ele?.orgType === 'ASSET_OWNER')?.map((item) => (
                                            <option value={item?.id}>{item?.companyName}</option>
                                        ))
                                    }
                                </select>
                            </FormGroup>
                        </Row>
                        <Row>
                            <Col lg='6'>
                                <FormGroup>
                                    <label>Control Type <span className='required'>*</span></label>
                                    <select className="form-select" name="controlType" value={orgControl[controlType]}
                                        {...register('controlType', { required: true })}
                                        onChange={e => setControlType(e.target.value)}
                                    >
                                        <option value=''>-Select Control Type-</option>
                                        <option value="EQUITY">Equity</option>
                                        <option value="OPERATIONAL">Operational</option>
                                        <option value="FINANCIAL">Financial</option>
                                    </select>
                                    <span className="text-danger">{errors?.controlType && 'Control Type is required'}</span>
                                </FormGroup>
                            </Col>
                            <Col lg='6'>
                                <FormGroup >
                                    <label>Control Percentage (%) <span className='required'>*</span></label>
                                    <input
                                        className="form-control"
                                        name="controlTypePercentage"
                                        placeholder="%"
                                        type="text"
                                        value={controlPercentage}
                                        {...register('controlTypePercentage', { required: true, min: 0, max: 100 })}
                                        onChange={(e) => setControlPercentage(e.target.value)}
                                    />
                                    <span className="text-danger">{errors.controlTypePercentage?.type === 'required' && 'Control Percentage (%) is Required'}</span>
                                    <span className="text-danger">{errors.controlTypePercentage?.type === 'min' && 'The value should be greater than 0'}</span>
                                    <span className="text-danger">{errors.controlTypePercentage?.type === 'max' && 'The value should be less than 100'}</span>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg='6'>
                                <FormGroup className="mb-3 project-form">
                                    <label>Effective Date <span className="text-danger">*</span> </label>
                                    <Controller
                                        name='effectiveDate'
                                        control={control}
                                        rules={{
                                            required: true,
                                        }}
                                        onChange={(e) => setEffectiveDate(e)}
                                        value={handleDate(effectiveDate)}
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <DatePicker
                                                    className="datepicker-here form-control"
                                                    selected={value ? new Date(value) : ''}
                                                    onChange={e => {
                                                        onChange(e)
                                                    }}
                                                    showMonthDropdown
                                                    dateFormatCalendar="MMMM"
                                                    showYearDropdown
                                                    yearDropdownItemNumber={15}
                                                    dropdownMode="select"
                                                    dateFormat="dd MMM, yyyy"
                                                    customInput={<CustomInput />}
                                                />
                                            )
                                        }}
                                    />
                                    <span className='text-danger'>{errors.effectiveDate && errors.effectiveDate.type === 'required' && 'Effective Date is required'}</span>
                                </FormGroup>
                            </Col>
                            <Col lg='6'>
                                <FormGroup className="mb-3 project-form">
                                    <label>End Date </label>
                                    <Controller
                                        name='endDate'
                                        control={control}
                                        onChange={(e) => setEndDate(e)}
                                        value={handleDate(endDate)}
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <DatePicker
                                                    className="datepicker-here form-control"
                                                    selected={value ? new Date(value) : ''}
                                                    onChange={e => {
                                                        onChange(e)
                                                    }}
                                                    showMonthDropdown
                                                    dateFormatCalendar="MMMM"
                                                    showYearDropdown
                                                    yearDropdownItemNumber={15}
                                                    dropdownMode="select"
                                                    dateFormat="dd MMM, yyyy"
                                                    customInput={<CustomInput />}
                                                />
                                            )
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="d-flex mtop-1" >
                            {
                                loading ? <ButtonLoader loaderText={`submitting`} color="primary" /> : <Button color="primary" >Submit</Button>
                            }
                            <Button color="secondary" className="ml-1" onClick={() => onClose()}> Cancel</Button>
                        </div>
                    </Form>

                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default AddSubOrganization;