import React, { useEffect, useState } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';
import { faqContent, faqContentId } from '../../api/helper';


const Faq = ({ setFaqOpen, faqOpen }) => {
    const [open, setopen] = useState(faqOpen);
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname?.split('/')
    const tooltip = (
        <Tooltip id="tooltip" >
            Help & FAQ
        </Tooltip>
    );

    const onClose = () => {
        setopen(false)
        setFaqOpen(false)
    }

    useEffect(() => {
        console.log(location.pathname?.split('/'))
        console.log(faqContent[path[path?.length - 1]])
        console.log(faqOpen, open)
        setopen(faqOpen)
    }, [faqOpen])

    return (
        <div>
            {/* <OverlayTrigger placement="top" overlay={tooltip}>
                <div className='faq-icon-box' style={{ display: (open === 1 || open === 2) ? 'flex' : 'none' }} onClick={() => setopen(0)}>
                    <div class="chat-icon">
                        <span class="question-mark">?</span>
                    </div>
                </div>
            </OverlayTrigger> */}
            <div style={{ display: faqOpen === true ? 'block' : 'none' }}
                className={`chat-popup ${faqOpen === true ? 'showBox' : 'hideBox'}`} id="chatPopup">
                <div className="chat-header">
                    <div className='d-flex'>
                        <div>
                            <div class="chat-icon">
                                <span class="question-mark">?</span>
                            </div>
                        </div>
                        <h2 className='ml-1'>FAQ & Help</h2>
                    </div>
                    <button className="close-btn" onClick={() => onClose()}>✖</button>
                </div>
                <div className="chat-body">
                    <div className="messages mb-1">
                        {console.log(path[path?.length - 1])}
                        {
                            faqContent[path[path?.length - 1]]?.map((ele, index) => (
                                <div className={index > 0 && 'mtop-1'}>
                                    <p className='question'>{ele?.query}</p>
                                    <p>{ele?.ans}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='chat-footer' onClick={() => navigate(`${process.env.PUBLIC_URL}/faq-and-help#${faqContentId[path[path?.length - 1]]}`)}>
                    <a>Learn more</a>
                </div>

            </div>
        </div>

    )
}

export default Faq;