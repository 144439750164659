import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import { Card, CardBody } from "reactstrap";
import Accordion from "react-bootstrap/Accordion";
import {
  carbonCredits,
  ghgGeneral,
  GHGPlatform,
  groups,
  locations,
  dataCapture,
  Net0TracePlatform,
  projects,
  general,
  existingProject,
  wallet,
  trading,
  stationarycombustion,
  mobilesource,
  mobileSource,
  stationaryCombustion,
  refrigerationAcEquipmentUse,
  fireSuppressio,
  purchasedGases,
  purchasedAndConsumedElectricity,
  employeeBusinessTravel,
  purchasedAndConsumedSteam,
  employeeComputing,
  upstreamTrasportationDistribution,
  waste,
  water,
  approvalQueue,
  submitQueue,
  progress,
  net0Planner,
  actionPlan,
  executiveDashboard,
  targetAndActual,
  emissionInsights,
  scope1,
  scope2,
  scope3,
  sustainabilityReporting,
  businessInfo,
  environment,
  social,
  governance,
  energy,
  waters,
  emission,
  effluentsAndWaste,
  reporting,
  scopePreference,
  users,
  downloads,
  applications,
  subsidiaries,
} from "./faqContent";
import { useLocation } from "react-router";
import ghg from "../../../assets/images/faq/ghg.png";
import generalImg from "../../../assets/images/faq/general.png";
import technical from "../../../assets/images/faq/tech.png";
import carbon from "../../../assets/images/faq/carbon.png";
import { Header } from "../../Constant";

const FaqHelp = () => {
  const location = useLocation();

  const handleScroll = (hash) => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    handleScroll(location.hash);
  }, [location]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle={"FAQ & Help"} />
      <div className="d-flex justify-center">
        <Card className="faq-card">
          <div
            className="faq-card color-dark-primary"
            onClick={() => handleScroll("#general")}
          >
            <img src={generalImg} width={50} height={50} />
            <h5 className="f-s-17 mtop-1">General</h5>
          </div>
        </Card>
        <Card className="faq-card ml-1">
          <div
            className="faq-card color-dark-primary"
            onClick={() => handleScroll("#Net0Trace")}
          >
            <img src={ghg} width={50} height={50} />
            <h5 className="f-s-17 mtop-1">Net0Trace</h5>
          </div>
        </Card>
        <Card className="faq-card ml-1">
          <div
            className="faq-card color-dark-primary"
            onClick={() => handleScroll("#carbonCredits")}
          >
            <img src={carbon} width={50} height={50} />
            <h5 className="f-s-17 mtop-1">Carbon Credits</h5>
          </div>
        </Card>
      </div>
      <Card className="faq">
        <CardBody>
          <div className="general">
            <h2>General</h2>
            {
              <Accordion flush className="mtop-1">
                {general?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      <p>{item.ans}</p>
                      <ul>
                        {item?.isListObjectPaired == true &&
                          item?.list?.map((i, index) => (
                            <li className="ml-1 mtop-1 d-flex">
                              <div className="f-s-14">
                                <strong>* {i?.split("-")[0]}</strong> -{" "}
                                {i?.split("-")[1]}
                              </div>
                            </li>
                          ))}
                        {item?.isListObjectPaired === false &&
                          item?.list?.map((i, index) => (
                            <li className="ml-1 mtop-1 d-flex">
                              <div className="f-s-14">
                                <strong>*</strong> {i}
                              </div>
                            </li>
                          ))}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            }
          </div>
          <div className="mtop-1" id="Net0Trace">
            <h2>Net0Trace Information</h2>
            <Accordion flush className="mtop-1">
              {Net0TracePlatform?.map((item, index) => (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>{item.query}</Accordion.Header>
                  <Accordion.Body>
                    <p>{item.ans}</p>
                    {item?.list && (
                        <ul>
                        {item?.isListObjectPaired === true &&
                          item?.list?.map((i, index) => (
                            <li className="ml-1 mtop-1 d-flex">
                              <div className="f-s-14">
                                <div>
                                  <span>
                                    <strong>* {i?.split("-")[0]}</strong>
                                  </span>{" "}
                                  - {i?.split("-")[1]}
                                </div>
                              </div>
                            </li>
                          ))}
                        {!item?.isListObjectPaired &&
                          item?.list?.map((i, index) => (
                            <li className="ml-1 mtop-1 d-flex">
                              <div className="f-s-14">
                                <div>
                                  <span>
                                    <strong>*</strong> {i}
                                  </span>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    )}
                    
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <h4 className="mtop-1">
              <i>
                <u>DEFINE</u>
              </i>
            </h4>

            <div className="mtop-1" id="groups">
              <h3 className="f-s-17">Groups</h3>
              <Accordion flush className="mtop-1">
                {groups?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      <p>{item.ans}</p>
                      {item?.list?.map((i, index) => (<ul>
                        <li className="ml-1 mtop-1 d-flex">
                          <div className="f-s-14">
                            <span>
                              <b>*</b> {i}
                            </span>
                          </div>
                        </li>
                        </ul>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="mtop-1" id="locations">
              <h3 className="f-s-17">Locations</h3>
              <Accordion flush className="mtop-1">
                {locations?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      <p>{item.ans}</p>
                      {item?.list && (
                        <div>
                          <ul>
                            {item?.isListObjectPaired === true &&
                              item?.list?.map((i, index) => (
                                <li className="ml-1 mtop-1 d-flex">
                                  <div className="f-s-14">
                                    <strong>* {i?.split("-")[0]}</strong> -{" "}
                                    {i?.split("-")[1]}
                                  </div>
                                </li>
                              ))}
                          </ul>
                          <ul>
                            {item?.isListObjectPaired === false &&
                              item?.list?.map((i, index) => (
                                <li className="ml-1 mtop-1 d-flex">
                                  <div className="f-s-14">
                                    <strong>*</strong> {i}
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                      {item?.Note && <h5 className="mtop-1">{item.Note}</h5>}
                      {item?.Message && (
                        <p className="mtop-1">{item.Message}</p>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="mtop-1" id="scopePreference">
              <h3 className="f-s-17">Scope Preferences</h3>
              <Accordion flush className="mtop-1">
                {scopePreference?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      <p>{item.ans}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="mtop-1" id="users">
              <h3 className="f-s-17">Users</h3>
              <Accordion flush className="mtop-1">
                {users?.map((item, index) => (
                  <Accordion.Item eventKey={item}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      {item?.isAnsObjectPair === true &&
                        item.ans
                          .split("//")
                          .map((point, i) => <p>• {point}</p>)}
                      {item?.isAnsObjectPair === false && <p>{item.ans}</p>}
                      {item?.list?.map((i, index) => (
                        <li className="ml-1 mtop-1 d-flex">
                          <div className="f-s-14">
                            <strong> * {i?.split("-")[0]} </strong> -{" "}
                            {i?.split("-")[1]}
                          </div>
                        </li>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="mtop-1" id="subsidiaries">
              <h3 className="f-s-17">Subsidiaries</h3>
              <Accordion flush className="mtop-1">
                {subsidiaries?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      <p>{item.ans}</p>
                      {item?.list?.map((item) => (
                        <li className="ml-1 mtop-1 d-flex">
                          <div className="f-s-14">
                            <strong>*</strong> {item}
                          </div>
                        </li>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <h4 className="mtop-1">
              <i>
                <u>CAPTURE</u>
              </i>
            </h4>
            <div className="mtop-1" id="dataCapture">
              <h3 className="f-s-17">Data Entry</h3>
              <Accordion flush className="mtop-1">
                {dataCapture?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      <p>{item.ans}</p>
                      {item?.STEP && <h3>{item.STEP}</h3>}
                      {item?.list1 && (
                        <div>
                          <ul>
                            {item?.isListObjectPaired == true &&
                              item?.list1?.map((i, index) => (
                                <li className="ml-1 mtop-1 d-flex">
                                  <div className="f-s-14">
                                    <strong> * {i?.split("-")[0]} </strong> -{" "}
                                    {i?.split("-")[1]}
                                  </div>
                                </li>
                              ))}
                            {item?.isListObjectPaired == false &&
                              item?.list1?.map((i, index) => (
                                <li className="ml-1 mtop-1 d-flex">
                                  <div className="f-s-14">
                                    <strong> * </strong> {i}
                                  </div>
                                </li>
                              ))}
                          </ul>
                          <p className="mtop-1">{item.Additional}</p>
                          <ul>
                            {item?.isListObjectPaired == true &&
                              item?.list2?.map((i, index) => (
                                <li className="ml-1 mtop-1 d-flex">
                                  <div className="f-s-14">
                                    <strong> * {i?.split("-")[0]} </strong> -{" "}
                                    {i?.split("-")[1]}
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                      {item?.Message && <p>{item.Message}</p>}
                      {item?.Note && <h5 className="mtop-1"> {item.Note} </h5>}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
              <hr />
              <div>
                <h3>Scope 1</h3>
              </div>
              <ul className="datacapture-list">
                <li>
                  <a
                    className="ml-1 mtop-1 d-flex"
                    onClick={() => handleScroll("#stationary")}
                  >
                    <i className="fa fa-hand-o-right me-2" aria-hidden="true" />
                    Stationary Combustion
                  </a>
                </li>
                <li>
                  <a
                    className="ml-1 mtop-1 d-flex"
                    onClick={() => handleScroll("#mobile")}
                  >
                    <i className="fa fa-hand-o-right me-2" aria-hidden="true" />
                    Mobile Source
                  </a>
                </li>
                <li>
                  <a
                    className="ml-1 mtop-1 d-flex"
                    onClick={() => handleScroll("#refrigeration")}
                  >
                    <i className="fa fa-hand-o-right me-2" aria-hidden="true" />
                    Refrigeration / Ac Equipment Use
                  </a>
                </li>
                <li>
                  <a
                    className="ml-1 mtop-1 d-flex"
                    onClick={() => handleScroll("#suppression")}
                  >
                    <i className="fa fa-hand-o-right me-2" aria-hidden="true" />
                    Fire Suppression
                  </a>
                </li>
                <li>
                  <a
                    className="ml-1 mtop-1 d-flex"
                    onClick={() => handleScroll("#purchased")}
                  >
                    <i className="fa fa-hand-o-right me-2" aria-hidden="true" />
                    Purchased Gases
                  </a>
                </li>
                <h3 className="mtop-1">Scope 2</h3>
                <li>
                  <a
                    className="ml-1 mtop-1 d-flex"
                    onClick={() => handleScroll("#purchasedElectricity")}
                  >
                    <i className="fa fa-hand-o-right me-2" aria-hidden="true" />
                    Purchased And Consumed Electricity
                  </a>
                </li>
                <li>
                  <a
                    className="ml-1 mtop-1 d-flex"
                    onClick={() => handleScroll("#purchasedSteam")}
                  >
                    <i className="fa fa-hand-o-right me-2" aria-hidden="true" />
                    Purchased And Consumed Steam
                  </a>
                </li>
                <h3 className="mtop-1">Scope 3</h3>
                <li>
                  <a
                    className="ml-1 mtop-1 d-flex"
                    onClick={() => handleScroll("#employeeBusinessTravel")}
                  >
                    <i className="fa fa-hand-o-right me-2" aria-hidden="true" />
                    Employee Business Travel
                  </a>
                </li>
                <li>
                  <a
                    className="ml-1 mtop-1 d-flex"
                    onClick={() => handleScroll("#employeeCommuting")}
                  >
                    <i className="fa fa-hand-o-right me-2" aria-hidden="true" />
                    Employee Commuting
                  </a>
                </li>
                <li>
                  <a
                    className="ml-1 mtop-1 d-flex"
                    onClick={() =>
                      handleScroll("#upstreamTransportationDistribution")
                    }
                  >
                    <i className="fa fa-hand-o-right me-2" aria-hidden="true" />
                    Upstream Transportation and Distribution
                  </a>
                </li>
                <li>
                  <a
                    className="ml-1 mtop-1 d-flex"
                    onClick={() => handleScroll("#Waste")}
                  >
                    <i className="fa fa-hand-o-right me-2" aria-hidden="true" />
                    Waste
                  </a>
                </li>
                <li>
                  <a
                    className="ml-1 mtop-1 d-flex"
                    onClick={() => handleScroll("#water")}
                  >
                    <i className="fa fa-hand-o-right me-2" aria-hidden="true" />
                    Water
                  </a>
                </li>
              </ul>
            </div>
            <hr />
            <div>
              <div className="mtop-1" id="stationary">
                <h4 className="mtop-1"> SCOPE 1 </h4>
                <h3 className="f-s-17">Stationary Combustion</h3>
                <Accordion flush className="mtop-1">
                  {stationaryCombustion?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                        {item?.list?.map((i, index) => (
                          <ul>
                            <li className="ml-1 mtop-1 d-flex">
                              <div className="f-s-14">
                                <strong> * </strong> {i}
                              </div>
                            </li>
                          </ul>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="mtop-1" id="mobile">
                <h3 className="f-s-17">Mobile Source</h3>
                <Accordion flush className="mtop-1">
                  {mobileSource?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                        {item?.list?.map((i, index) => (
                          <ul>
                            <li className="ml-1 mtop-1 d-flex">
                              <div className="f-s-14">
                                <strong> * </strong> {i}
                              </div>
                            </li>
                          </ul>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="mtop-1" id="refrigeration">
                <h3 className="f-s-17">Refrigeration / Ac Equipment Use</h3>
                <Accordion flush className="mtop-1">
                  {refrigerationAcEquipmentUse?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="mtop-1" id="suppression">
                <h3 className="f-s-17">Fire Suppression</h3>
                <Accordion flush className="mtop-1">
                  {fireSuppressio?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                        {item?.Additional && (
                          <h5 className="mtop-1">{item.Additional}</h5>
                        )}
                        {item?.Message && <p> {item.Message} </p>}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="mtop-1" id="purchased">
                <h3 className="f-s-17">Purchased Gases</h3>
                <Accordion flush className="mtop-1">
                  {purchasedGases?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div>
                <h4 className="mtop-1"> SCOPE 2 </h4>
              </div>
              <div className="mtop-1" id="purchasedElectricity">
                <h3 className="f-s-17">Purchased And Consumed Electricity</h3>
                <Accordion flush className="mtop-1">
                  {purchasedAndConsumedElectricity?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="mtop-1" id="purchasedSteam">
                <h3 className="f-s-17">Purchased And Consumed Steam</h3>
                <Accordion flush className="mtop-1">
                  {purchasedAndConsumedSteam?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                        {item?.list?.map((i, index) => (
                          <ul>
                            <li className="ml-1 mtop-1 d-flex">
                              <div className="f-s-14">
                                <strong> * {i?.split("-")[0]} </strong> -{" "}
                                {i?.split("-")[1]}
                              </div>
                            </li>
                          </ul>
                        ))}
                        {item?.Message && (
                          <p className="mtop-1">{item.Message}</p>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div>
                <h4 className="mtop-1"> SCOPE 3 </h4>
              </div>
              <div className="mtop-1" id="employeeBusinessTravel">
                <h3 className="f-s-17">Employee Business Travel</h3>
                <Accordion flush className="mtop-1">
                  {employeeBusinessTravel?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="mtop-1" id="employeeCommuting">
                <h3 className="f-s-17">Employee Commuting</h3>
                <Accordion flush className="mtop-1">
                  {employeeComputing?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="mtop-1" id="upstreamTransportationDistribution">
                <h3 className="f-s-17">
                  Upstream Transportation And Distribution
                </h3>
                <Accordion flush className="mtop-1">
                  {upstreamTrasportationDistribution?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="mtop-1" id="Waste">
                <h3 className="f-s-17">Waste</h3>
                <Accordion flush className="mtop-1">
                  {waste?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                        {item?.list && (
                          <div>
                            <ul>
                              {item?.isListObjectPaired === true &&
                                item?.list?.map((i, index) => (
                                  <li className="ml-1 mtop-1 d-flex">
                                    <div className="f-s-14">
                                      <strong>* {i?.split("-")[0]}</strong> -{" "}
                                      {i?.split("-")[1]}
                                    </div>
                                  </li>
                                ))}
                            </ul>
                            <ul>
                              {item?.isListObjectPaired === false &&
                                item?.list?.map((i, index) => (
                                  <li className="ml-1 mtop-1 d-flex">
                                    <div className="f-s-14">
                                      <strong>*</strong> {i}
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="mtop-1" id="water">
                <h3 className="f-s-17">Water</h3>
                <Accordion flush className="mtop-1">
                  {water?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>

                        {item?.list?.map((i, index) => (
                          <ul>
                            <li className="ml-1 mtop-1 d-flex">
                              <div className="f-s-14">
                                <strong>* {i?.split("-")[0]}</strong> -{" "}
                                {i?.split("-")[1]}
                              </div>
                            </li>
                          </ul>
                        ))}
                        {item?.Message && (
                          <p className="mtop-1"> {item.Message} </p>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="mtop-1" id="approvalQueue">
                <h3 className="f-s-17">Approval Queue</h3>
                <Accordion flush className="mtop-1">
                  {approvalQueue?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                        {item?.list?.map((i, index) => (
                          <ul>
                            <li className="ml-1 mtop-1 d-flex">
                              <div className="f-s-14">
                                <span>
                                  <b>*</b> {i}
                                </span>
                              </div>
                            </li>
                          </ul>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="mtop-1" id="submitQueue">
                <h3 className="f-s-17">Submit Queue</h3>
                <Accordion flush className="mtop-1">
                  {submitQueue?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>

                        {item?.list?.map((i, index) => (
                          <ul>
                            <li className="ml-1 mtop-1 d-flex">
                              <div className="f-s-14">
                                <span>
                                  <b> * {i?.split("-")[0]}</b> -{" "}
                                  {i?.split("-")[1]}
                                </span>
                              </div>
                            </li>
                          </ul>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="mtop-1" id="progress">
                <h3 className="f-s-17">Progress Tracker</h3>
                <Accordion flush className="mtop-1">
                  {progress?.map((item, index) => (
                    <Accordion.Item eventKey={item}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>

                        {item?.list?.map((i, index) => (
                          <ul>
                            <li className="ml-1 mtop-1 d-flex">
                              <div className="f-s-14">
                                <span>
                                  <b> * {i?.split("-")[0]}</b> -{" "}
                                  {i?.split("-")[1]}
                                </span>
                              </div>
                            </li>
                          </ul>
                        ))}
                        {item?.Message && (
                          <p className="mtop-1"> {item.Message} </p>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <h4 className="mtop-1">
                <i>
                  <u>PLAN</u>
                </i>
              </h4>
              <div className="mtop-1" id="net0Planner">
                <h3 className="f-s-17">Net Zero Planner</h3>
                <Accordion flush className="mtop-1">
                  {net0Planner?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="mtop-1" id="actionPlan">
                <h3 className="f-s-17">Action Plan</h3>
                <Accordion flush className="mtop-1">
                  {actionPlan?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <h4 className="mtop-1">
                <i>
                  <u>ANALYTICS</u>
                </i>
              </h4>
              <div className="mtop-1" id="executiveDashboard">
                <h3 className="f-s-17">Executive Dashboard</h3>
                <Accordion flush className="mtop-1">
                  {executiveDashboard?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                        {item?.list && (
                          <div>
                            <ul>
                              {item?.isListObjectPaired === true &&
                                item?.list?.map((i, index) => (
                                  <li className="ml-1 mtop-1 d-flex">
                                    <div className="f-s-14">
                                      <strong>* {i?.split("-")[0]}</strong> -{" "}
                                      {i?.split("-")[1]}
                                    </div>
                                  </li>
                                ))}
                            </ul>
                            <ul>
                              {item?.isListObjectPaired === false &&
                                item?.list?.map((i, index) => (
                                  <li className="ml-1 mtop-1 d-flex">
                                    <div className="f-s-14">
                                      <strong>* </strong> {i}
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="mtop-1" id="targetAndActual">
                <h3 className="f-s-17">Target Vs Actual</h3>
                <Accordion flush className="mtop-1">
                  {targetAndActual?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>

                        {item?.list?.map((i, index) => (
                          <ul>
                            <li className="ml-1 mtop-1 d-flex">
                              <div className="f-s-14">
                                <span>
                                  {" "}
                                  <b>*</b> {i}
                                </span>
                              </div>
                            </li>
                          </ul>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div className="mtop-1" id="emissionInsights">
                <h3 className="f-s-17">Emission Insight</h3>
                <Accordion flush className="mtop-1">
                  {emissionInsights?.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.query}</Accordion.Header>
                      <Accordion.Body>
                        <p>{item.ans}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
              <div>
                <h4 className="mtop-1">
                  <i>
                    <u>VALIDATION</u>
                  </i>
                </h4>
                <div className="mtop-1" id="scope1">
                  <h3 className="f-s-17">Scope 1</h3>
                  <Accordion flush className="mtop-1">
                    {scope1?.map((item, index) => (
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>{item.query}</Accordion.Header>
                        <Accordion.Body>
                          <p>{item.ans}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
                <div className="mtop-1" id="scope2">
                  <h3 className="f-s-17">Scope 2</h3>
                  <Accordion flush className="mtop-1">
                    {scope2?.map((item, index) => (
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>{item.query}</Accordion.Header>
                        <Accordion.Body>
                          <p>{item.ans}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
                <div className="mtop-1" id="scope3">
                  <h3 className="f-s-17">Scope 3</h3>
                  <Accordion flush className="mtop-1">
                    {scope3?.map((item, index) => (
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>{item.query}</Accordion.Header>
                        <Accordion.Body>
                          <p>{item.ans}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="mtop-1" id="sustainabilityReporting">
              <h2>Sustainability Reporting</h2>
              <Accordion flush className="mtop-1">
                {sustainabilityReporting?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      <p>{item.ans}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="mtop-1" id="businessInfo">
              <h3 className="f-s-17">Business Info</h3>
              <Accordion flush className="mtop-1">
                {businessInfo?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      <p>{item.ans}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="mtop-1" id="environment">
              <h3 className="f-s-17">Environmental</h3>
              <Accordion flush className="mtop-1">
                {environment?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      <p>{item.ans}</p>
                      {item?.list?.map((i, index) => (
                        <ul>
                          <li className="ml-1 mtop-1 d-flex">
                            <div className="f-s-14">
                              <strong>* {i?.split("-")[0]}</strong> -{" "}
                              {i?.split("-")[1]}
                            </div>
                          </li>
                        </ul>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="mtop-1" id="social">
              <h3 className="f-s-17">Social</h3>
              <Accordion flush className="mtop-1">
                {social?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      {item?.isAnsObjectPair === true &&
                        item.ans
                          .split("//")
                          .map((point, index) => <p>• {point}</p>)}
                      {item?.isAnsObjectPair === false && <p>{item.ans}</p>}
                      {item?.list?.map((i, index) => (
                        <ul>
                          <li className="ml-1 mtop-1 d-flex">
                            <div className="f-s-14">
                              <strong>* {i?.split("-")[0]}</strong> -{" "}
                              {i?.split("-")[1]}
                            </div>
                          </li>
                        </ul>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="mtop-1" id="governance">
              <h3 className="f-s-17">Governance</h3>
              <Accordion flush className="mtop-1">
                {governance?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      {item?.isAnsObjectPair === true &&
                        item.ans
                          .split("point: 2 ->")
                          .map((point, i) => <p>• {point}</p>)}
                      {item?.isAnsObjectPair === false && <p>{item.ans}</p>}
                      {item?.list?.map((i, index) => (
                        <ul>
                          <li className="ml-1 mtop-1 d-flex">
                            <div className="f-s-14">
                              <strong>* {i?.split("-")[0]}</strong> -{" "}
                              {i?.split("-")[1]}
                            </div>
                          </li>
                        </ul>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <h4 className="mtop-1">
              <i>
                <u>GRI DASHBOARDS</u>
              </i>
            </h4>
            <div className="mtop-1" id="energy">
              <h3 className="f-s-17">Energy</h3>
              <Accordion flush className="mtop-1">
                {energy?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      <p>{item.ans}</p>
                      {item?.list && (
                        <div>
                          <ul>
                            {item?.isListObjectPaired == true &&
                              item?.list?.map((i, index) => (
                                <li className="ml-1 mtop-1 d-flex">
                                  <div className="f-s-14">
                                    <strong>* {i?.split("-")[0]}</strong> -{" "}
                                    {i?.split("-")[1]}
                                  </div>
                                </li>
                              ))}
                          </ul>
                          <ul>
                            {item?.isListObjectPaired === false &&
                              item?.list?.map((i, index) => (
                                <li className="ml-1 mtop-1 d-flex">
                                  <div className="f-s-14">
                                    <strong>*</strong> {i}
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                      {item?.Message && (
                        <p className="mtop-1"> {item.Message} </p>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="mtop-1" id="waters">
              <h3 className="f-s-17"> Water </h3>
              <Accordion flush className="mtop-1">
                {waters?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      <p>{item.ans}</p>
                      {item?.list && (
                        <div>
                          <ul>
                            {item?.isListObjectPaired == true &&
                              item?.list.map((i, index) => (
                                <li className="ml-1 mtop-1 d-flex">
                                  <div className="f-s-14">
                                    <span>
                                      <b> * {i?.split("-")[0]}</b> -{" "}
                                      {i?.split("-")[1]}
                                    </span>
                                  </div>
                                </li>
                              ))}
                          </ul>
                          <ul>
                            {item?.isListObjectPaired == false &&
                              item?.list.map((i, index) => (
                                <li className="ml-1 mtop-1 d-flex">
                                  <div className="f-s-14">
                                    <span>
                                      <b> *</b> {i}
                                    </span>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                      {item?.Message && (
                        <p className="mtop-1"> {item.Notes} </p>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
          <div className="mtop-1" id="emission">
            <h3 className="f-s-17">Emission</h3>
            <Accordion flush className="mtop-1">
              {emission?.map((item, index) => (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>{item.query}</Accordion.Header>
                  <Accordion.Body>
                    <p>{item.ans}</p>
                    {item?.list && (
                      <div>
                        <ul>
                          {item?.isListObjectPaired == true &&
                            item?.list.map((i, index) => (
                              <li className="ml-1 mtop-1 d-flex">
                                <div className="f-s-14">
                                  <span>
                                    <b> * {i?.split("-")[0]}</b> -{" "}
                                    {i?.split("-")[1]}
                                  </span>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <ul>
                          {item?.isListObjectPaired == false &&
                            item?.list.map((i, index) => (
                              <li className="ml-1 mtop-1 d-flex">
                                <div className="f-s-14">
                                  <span>
                                    <b> *</b> {i}
                                  </span>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                    {item?.Message && (
                      <p className="mtop-1"> {item.Message} </p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className="mtop-1" id="effluentsAndWaste">
            <h3 className="f-s-17">Effluents And Waste</h3>
            <Accordion flush className="mtop-1">
              {effluentsAndWaste?.map((item, index) => (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>{item.query}</Accordion.Header>
                  <Accordion.Body>
                    <p>{item.ans}</p>
                    {item?.list && (
                      <div>
                        <ul>
                          {item?.isListObjectPaired == true &&
                            item?.list.map((i, index) => (
                              <li className="ml-1 mtop-1 d-flex">
                                <div className="f-s-14">
                                  <span>
                                    <b>* {i?.split("-")[0]}</b> -{" "}
                                    {i?.split("-")[1]}
                                  </span>
                                </div>
                              </li>
                            ))}
                          {item?.isListObjectPaired == false &&
                            item?.list.map((i, index) => (
                              <li className="ml-1 mtop-1 d-flex">
                                <div className="f-s-14">
                                  <span>
                                    <b>* </b> {i}
                                  </span>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                    {item?.Message && <p className="mtop-1">{item.Message}</p>}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div className="mtop-1" id="reporting">
            <h3 className="f-s-17"> Reporting </h3>
            <Accordion flush className="mtop-1">
              {reporting?.map((item, index) => (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>{item.query}</Accordion.Header>
                  <Accordion.Body>
                    <p>{item.ans}</p>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
          <div>
            <h4 className="mtop-1">
              <i>
                <u>SETTINGS</u>
              </i>
            </h4>
            <div className="mtop-1" id="downloads">
              <h3 className="f-s-17">Downloads</h3>
              <Accordion flush className="mtop-1">
                {downloads?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      <p>{item.ans}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="mtop-1" id="applications">
              <h3 className="f-s-17">Applications</h3>
              <Accordion flush className="mtop-1">
                {applications?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      <p>{item.ans}</p>
                      {item?.list?.map((i, index) => (
                        <ul>
                          <li className="ml-1 mtop-1 d-flex">
                            <div className="f-s-14">
                              <span>
                                {" "}
                                <b>*</b> {i}
                              </span>
                            </div>
                          </li>
                        </ul>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
          <div>
            <div className="mtop-1" id="carbonCredits">
              <h2>Carbon Credits</h2>
              <Accordion flush className="mtop-1">
                {carbonCredits?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>{item.ans}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="mtop-1" id="projects">
              <h3 className="f-s-17">Projects</h3>
              <Accordion flush className="mtop-1">
                {projects?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>
                      <p>{item.ans}</p>
                      <ul>
                        {item?.list?.map((i, index) => (
                          <li className="ml-1 mtop-1 d-flex">
                            <div className="f-s-14">
                              <strong>* {i?.split("-")[0]}</strong> -{" "}
                              {i?.split("-")[1]}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="mtop-1" id="existingProjects">
              <h3 className="f-s-17">Existing Projects</h3>
              <Accordion flush className="mtop-1">
                {existingProject?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>{item.ans}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="mtop-1" id="wallet">
              <h3 className="f-s-17">Wallets</h3>
              <Accordion flush className="mtop-1">
                {wallet?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>{item.ans}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
            <div className="mtop-1" id="trading">
              <h3 className="f-s-17">Trading</h3>
              <Accordion flush className="mtop-1">
                {trading?.map((item, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>{item.query}</Accordion.Header>
                    <Accordion.Body>{item.ans}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default FaqHelp;
