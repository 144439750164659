import { List } from "reactstrap"
import Notes from "../../Component/ProjectInitiation/Notes"
import { EmployeeName, Message, STEP } from "../../Constant"

export const ghgTopics = [
    {
        query: 'What is the GHG platform?',
        ans: 'The GHG platform is a comprehensive tool designed to help businesses and organizations track, manage, and reduce their greenhouse gas emissions.'
    },
    {
        query: 'Who can use the GHG platform?',
        ans: 'The platform is suitable for companies, non-profits, government agencies, and any organization interested in monitoring and reducing their carbon footprint.'
    },
    {
        query: 'How do I sign up for the GHG platform?',
        ans: 'You can sign up by visiting our website and clicking on the "Sign Up" button. Follow the prompts to create an account.'
    }
]

export const ghgGeneral = [
    {
        query: 'What is a carbon footprint?',
        ans: 'A carbon footprint is the total amount of GHGs emitted directly or indirectly by an individual, organization, event, or product.'
    }

]


export const Net0TracePlatform = [
    {
        query: 'What is the Net0Trace platform?',
        ans: 'The Net0Trace platform is a comprehensive solution designed to help organizations measure, track, and manage their greenhouse gas (GHG) emissions. It supports businesses in achieving their sustainability goals by providing tools for emissions tracking, reporting, and reduction planning. The platform aligns with international standards for carbon accounting and net-zero commitments.GHG emissions are gases released into the atmosphere that trap heat and contribute to global warming. Common GHGs include carbon dioxide (CO2), methane (CH4), and nitrous oxide (N2O).'
    },
    {
        query: 'Who can use the Net0Trace platform?',
        ans: 'The Net0Trace platform can be used by organizations of all sizes, including corporations, small businesses, government entities, and non-profits. It is particularly useful for companies looking to monitor their carbon footprint, comply with sustainability regulations, and work toward achieving net-zero emissions.'
    },
    {
        query: 'Why is tracking emissions with Net0Trace important?',
        ans: 'Tracking emissions with Net0Trace is important because it helps organizations:',
        list: [
            'Understand their carbon footprint and identify key emission sources.',
            'Meet regulatory requirements and global sustainability standards.',
            'Demonstrate accountability and transparency to stakeholders.',
            'Make data-driven decisions to achieve net zero goals.',
            'Enhance their brand reputation by showcasing environmental responsibility.'

        ],
        isListObjectPaired: false
    },
    {
        query: 'What are the key features of the Net0Trace platform?',
        ans: 'The key features of the Net0Trace platform include:',
        list: [
            'Emissions Tracking- Monitors Scope 1, Scope 2, and Scope 3 emissions across all operational activities.',
            'Customizable Dashboards- Visualizes emissions data with user-friendly charts and insights tailored to organizational needs.',
            'Compliance Support- Aligns with global standards and frameworks such as GHG Protocol, Science-Based Targets initiative (SBTi), and Carbon Disclosure Project (CDP).',
            'Reduction Planning- Provides actionable recommendations and strategies to lower emissions.',
            'Action Plan Creation- Allows users to design and implement their own customized action plans to address specific emission sources.',
            'Target Setting- Facilitates the setting of ambitious yet achievable sustainability targets based on accurate emissions data and organizational goals.',
        ],
        isListObjectPaired: true
    },
]

export const groups = [
    {
        query: 'What does "Group" mean in Net0Trace?',
        ans: 'A "Group" in Net0Trace refers to a collection or category that organizes data or entities, such as business units, locations, or specific operational areas, under a single identifier for streamlined emissions tracking and management.'
    },
    {
        query: 'What are the benefits of creating a group?',
        ans: 'Creating a group allows for better organization and management of data. It helps segregate emissions by specific units, locations, or operations, making it easier to track, analyse, and report on targeted areas within an organization.'
    },
    {
        query: 'How do I create a group?',
        ans: "To create a new group:",
        list: [
            'Click on the "Add Group" button.',
            'Enter the group name, group code (if applicable), and a brief description of the group.',
            'Save the details to complete the group creation process.'
        ]
    },
    {
        query: 'How can I edit a group name?',
        ans: "To edit a group name:",
        list: [
            'Click on the "Edit" icon next to the group.',
            'Make the necessary changes to the group name or other details.',
            'Save the changes to update the group information.'
        ]
    },
    {
        query: 'How can I delete a group?',
        ans: 'To delete a group:',
        list: [
            'Click on the "Delete" icon next to the group.',
            'Confirm the deletion when prompted.',
            'Verify that the group has been successfully removed from the list.'
        ]
    },
    {
        query: 'Is there a limit to the number of groups I can create?',
        ans: 'No, there is no limit to the number of groups you can create on the Net0Trace platform. You can add as many groups as necessary to meet your organizational needs.'
    },
    {
        query: 'Can I create a group with the same name more than once?',
        ans: 'No, duplicate group names are not allowed. Each group name must be unique to avoid confusion and ensure accurate data tracking.'
    }

]

export const locations = [
    {
        query: 'What does "Location" mean in Net0Trace?',
        ans: 'A "Location" in Net0Trace represents a specific geographical or operational site within an organization where emissions data is tracked. This could include offices, factories, warehouses, or any other physical site.'
    },
    {
        query: 'What are the benefits of creating a location?',
        ans: "Creating a location helps organize and segregate emissions data by specific sites. This enables organizations to:",
        list: [
            'Analyse emissions data more accurately.',
            'Identify high-emission areas or locations.',
            'Implement targeted reduction strategies for specific sites.'
        ],
        isListObjectPaired: false
    },
    {
        query: 'How do I create a location?',
        ans: 'To create a location:',
        list: [
            'Navigate to the "Locations" section of the platform.',
            'Click on "Add Location".',
            'Enter the required information, including location name, type, and address details.',
            'Save the details by clicking "Add."'
        ],
        isListObjectPaired: false
    },
    {
        query: 'What information do I need to provide when creating a location?',
        ans: "When creating a location, you need to provide the following information:",
        list: [
            'Group Name- Select from the dropdown list.',
            'Location Type- Choose from the respective dropdown options.',
            'Location Name- Enter a unique name for the location.',
            'Address Details- Provide the address, city, zip code, and state (selectable from dropdowns).',
            'Country- Select the country from the dropdown menu.'
        ],
        isListObjectPaired: true,
        Message: 'After entering these details, click "Add" to save the location.'
    },
    {
        query: 'Can I edit a location after it has been created?',
        ans: "Yes, you can edit a location. To do so:",
        list: [
            'Click on the "Edit" icon next to the location.',
            'Make the necessary changes.',
            'Save the updates to apply the changes.'
        ],
        isListObjectPaired: false
    },
    {
        query: ' How do I delete a location?',
        ans: 'To delete a location:',
        list: [
            'Go to the "Locations" section.',
            'Select the location you want to delete.',
            'Click on the "Delete" button.',
            'Confirm the deletion when prompted.'
        ],
        isListObjectPaired: false,
        Note: 'Note: Deleting a location may remove all associated emissions data, so proceed cautiously.'
    },
    {
        query: 'How do locations help in analysing emissions data?',
        ans: 'Locations provide insights into emissions at specific sites, enabling you to:',
        list: [
            'Identify areas with the highest emissions.',
            'Pinpoint specific sources of emissions within a location.',
            'Implement targeted strategies for emissions reduction.',
            'Compare emissions across multiple locations for better decision-making.'
        ],
        isListObjectPaired: false
    }

]

export const scopePreference = [
    {
        query: 'What is Scope Preferences?',
        ans: "Scope Preferences refers to the selection of relevant scope categories (Scope 1, Scope 2, and Scope 3) based on the organization's emissions and operational boundaries."
    },
    {
        query: 'Is it mandatory to select all the scope categories?',
        ans: "No, it is not mandatory to select all scope categories. Organizations can choose only the applicable categories relevant to their operations."
    },
    {
        query: 'Will the selection of scope categories reflect elsewhere?',
        ans: 'Yes, the selected scope categories will appear on the Data Entry screen. You can enter data only for the categories you have selected.'
    },
    {
        query: 'After selecting the categories, will they automatically appear on the Data Entry screen?',
        ans: 'No, after selecting the categories, you need to submit your selection. Once submitted, the categories will reflect in the respective Data Entry sections.'
    }
]

export const users = [
    {
        query: 'What is Users?',
        ans: 'Users refers to the individuals assigned specific roles within the platform, each with distinct permissions and responsibilities.',
        isAnsObjectPair: false
    },
    {
        query: 'What user roles are applicable?',
        ans: 'Admin // Analyst // Approver // Report User',
        isAnsObjectPair: true
    },
    {
        query: 'Who can add user roles?',
        ans: 'The Admin is responsible for adding users to an organization. Admins can create any number of user roles.',
        isAnsObjectPair: false
    },
    {
        query: 'What is the role of an Admin?',
        ans: 'The Admin has full access to the platform, including the ability to read, write, and download all types of reports. Additionally, the Admin can create and manage user roles.',
        isAnsObjectPair: false
    },
    {
        query: 'What is the role of an Analyst?',
        ans: 'An Analyst is responsible for data entry. They have access only to the Data Entry screen and can also view the Submit Queue.',
        isAnsObjectPair: false
    },
    {
        query: 'What is the role of an Approver?',
        ans: 'An Approver reviews and approves or rejects entries in the Data Entry screen. They also have access to view and read the Groups and Locations screens.',
        isAnsObjectPair: false
    },
    {
        query: 'What is the role of a Report User?',
        ans: 'A Report User is responsible for viewing and downloading reports. The Admin assigns this role to users.',
        isAnsObjectPair: false
    },
    {
        query: 'How can an Admin create roles for various users?',
        ans: 'The Admin can create roles by clicking the "Add User" button in the top-right corner. Two steps are required:',
        isAnsObjectPair: false,
        list: [
            "Basic Info - Enter the user's first name, last name, email address, and phone number.",
            "Roles - Assign the user's role and provide access to a specific location. Once completed, the Admin submits the information, and the user will receive an email with their username and password."
        ]
    },
    {
        query: 'How do I add a user?',
        ans: 'Adding a user involves two steps:',
        isAnsObjectPair: false,
        list: [
            "Basic Info - Enter the user's mandatory details, including name, email address, and phone number. Only after completing these details can you proceed to the next step.",
            "Roles - Assign the user a role and location. After completing both steps, submit the information. The user will receive an email with their username and password."
        ]
    },
    {
        query: 'Are there any conditions for creating roles?',
        ans: 'Yes, a single user cannot hold multiple roles for the same location. For example, a person assigned the Analyst role for a specific location cannot simultaneously have the Approver role for the same location. This ensures data integrity and consistency.',
        isAnsObjectPair: false
    },
    {
        query: 'Can the Admin modify user roles after they are created?',
        ans: 'Yes, the Admin can modify user roles by clicking the pencil (edit) icon. After making the required changes, the Admin must click Save and Submit to ensure the updates are applied successfully.',
        isAnsObjectPair: false
    },
    {
        query: 'Can the Admin deactivate a user role?',
        ans: 'Yes, the Admin can deactivate a user role by toggling the button under the Status column. Once the button is turned off, the specific user role will be deactivated.',
        isAnsObjectPair: false
    }

]

export const subsidiaries = [
    {
        query: 'What does the Subsidiaries section represent?',
        ans: 'The Subsidiaries section provides information about the ownership history of child companies.'
    },
    {
        query: 'What is Ownership Management?',
        ans: 'Ownership Management is where the details of child companies are stored, including ownership percentage and the effective dates.'
    },
    {
        query: 'What does the Child Companies section display?',
        ans: 'The Child Companies section displays details of child organizations, including columns such as company name, country, type of control, control percentage, effective date, and end date.'
    },
    {
        query: 'What is Manage Ownership?',
        ans: 'The Manage Ownership feature allows you to view and manage the ownership control history.',
        list: [
            'If the end date is active, you can modify or edit details by clicking the pen icon and saving the changes. You can also delete a record by clicking the bin icon.',
            'If the end date is inactive, modifications or edits are not allowed.',
            'To add a new record, click the "Add" button, enter the required data, and save it.'
        ]
    },
    {
        query: 'How can I add details of a new child company?',
        ans: 'Click "New" in the top-right corner, which will open a form to input the organization details of the child company. Once all details are entered, click "Submit." The information will be saved and reflected on the Ownership Management page.'
    },
]

export const dataCapture = [
    {
        query: 'What does "Data Entry" mean in the Net0Trace platform?',
        ans: 'Data entry in the Net0Trace platform refers to the process of inputting essential information related to an organization’s emissions. This data is used to calculate and track greenhouse gas (GHG) emissions across various categories.'
    },
    {
        query: 'Why is data entry important?',
        ans: 'Data entry is crucial because it serves as the foundation for accurate emissions tracking and analysis. It ensures that the organization’s carbon footprint is calculated based on reliable and up-to-date information, enabling:',
        list1: [
            'Precise reporting and compliance with regulations.',
            'Identification of high-emission sources.',
            'Development of targeted strategies for emissions reduction.'
        ],
        isListObjectPaired: false
    },
    {
        query: 'What details are covered in the Data Entry section?',
        ans: 'The Data Entry section includes the following details:',
        list1: [
            'Location- Select the location name from the dropdown menu.',
            'Period- Choose the relevant time period (e.g., month or year) from the list.',
            'Employee Count- Enter the number of employees at the location for the selected period.',
        ],
        isListObjectPaired: true,
        Additional: 'Additionally, the Data Entry section provides a summary table of the organization’s emissions, which includes',
        list2: [
            'Scope 1 Emissions- Direct emissions from owned or controlled sources.',
            'Scope 2 Emissions- Indirect emissions resulting from purchased energy.',
            'Scope 3 Emissions- All other indirect emissions throughout the organization’s value chain.'
        ],
        isListObjectPaired: true,
        Note: 'Note: This ensures that all necessary information is captured for accurate emissions tracking and reporting.'
    },
    {
        query: "What does Scope 1 mean, and what categories are covered?",
        ans: 'Scope 1 emissions are direct greenhouse gas (GHG) emissions from sources that are controlled or owned by an organization. These emissions result from activities such as fuel combustion in boilers, furnaces, and vehicles.',
        STEP: 'This scope includes the following five subcategories:',
        list1: [
            'Stationary Combustion- Emissions from fixed equipment like boilers or generators.',
            'Mobile Sources- Emissions from vehicles and equipment used for transportation or operations.',
            'Refrigeration/AC Equipment Use- Emissions from leaks or usage of refrigerants in cooling systems.',
            'Fire Suppression- Emissions from the use of fire suppression systems.',
            'Purchased Gases- Emissions from gases purchased and consumed in operations.'
        ],
        isListObjectPaired: true
    },
    {
        query: 'What does Scope 2 mean, and what categories are covered?',
        ans: 'Scope 2 emissions are indirect greenhouse gas (GHG) emissions associated with the generation of purchased electricity, heat, or steam consumed by an organization. These emissions occur at the facilities of the energy supplier and are considered indirect because they are not directly under the organization’s control.',
        STEP: 'This scope includes two subcategories:',
        list1: [
            'Purchased and Consumed Electricity- Emissions generated from the production of electricity that an organization buys and uses for its operations.',
            'Purchased and Consumed Steam- Emissions resulting from the generation of steam that an organization purchases and consumes for heating or industrial processes.',
        ],
        isListObjectPaired: true
    },
    {
        query: 'What does Scope 3 contain?',
        ans: 'Scope 3 emissions are indirect greenhouse gas (GHG) emissions that result from activities in an organization’s value chain but occur from sources not owned or directly controlled by the organization. These emissions include both upstream and downstream activities.',
        STEP: 'On the Net0Trace platform, the following Scope 3 categories are captured:',
        list1: [
            'Employee Commuting- Emissions from employees traveling to and from work.',
            'Employee Business Travel- Emissions from business-related travel activities.',
            'Upstream Transportation and Distribution- Emissions from the transport of goods and services purchased by the organization.',
            'Waste- Includes emissions from both direct and indirect waste management.',
            'Water- Includes emissions from water consumption and discharge.'
        ],
        isListObjectPaired: true
    },
    {
        query: 'Is it necessary to fill in all Scope categories?',
        ans: 'No, it is not necessary to fill in all Scope categories. You can complete only the categories that are relevant to your organization.',
        STEP: ' To configure this:',
        list1: [
            'Navigate to the Settings section.',
            'Select Scope Applicability.',
            'Choose the relevant categories and click Submit.'
        ],
        isListObjectPaired: false,
        Message: 'The selected categories will then appear on the data entry page.'
    },
    {
        query: 'What does the "Status" column in the Emissions Summary represent?',
        ans: 'The "Status" column in the Emissions Summary serves as a quick verification tool. Once data is entered, a green tick symbol appears in this column, confirming that the data has been successfully captured.'
    },
    {
        query: 'What does "Save as Draft" mean?',
        ans: '"Save as Draft" allows you to save your progress without submitting the data. This ensures that you can revisit and update the information before final submission.'
    },
    {
        query: 'What does "Submit for Approval" mean?',
        ans: 'By clicking "Submit for Approval," a data preview screen will be displayed, enabling you to review all the entered data. Once reviewed, you can proceed to submit the data for the next step in the process.'
    }
]

export const upstreamTrasportationDistribution = [
    {
        query: 'What does upstream transportation and distribution mean?',
        ans: 'Upstream transportation and distribution refer to the activities involved in the transportation and delivery of goods before they reach your organization. This includes the movement of raw materials, components, or finished products from suppliers to your organization or between facilities.'
    },
    {
        query: 'What does source description mean?',
        ans: 'The source description refers to a brief label or identification that explains the specific transportation or distribution activity being tracked. It helps categorize the activity for emissions calculations, such as goods transported or distributed by the organization.'
    },
    {
        query: 'What does the type column denote?',
        ans: 'The type column contains a dropdown menu with options like "Transportation" and "Distribution." You can select the appropriate option based on whether the activity involves moving goods or distributing them to different locations.'
    },
    {
        query: 'What does vehicle type mean?',
        ans: 'The vehicle type column provides a dropdown menu with options for specific vehicle types based on the selected activity (transportation or distribution). This allows you to specify the mode of transportation used for the upstream activities.'
    },
    {
        query: 'What does the distance travelled column mean?',
        ans: 'The distance travelled column represents the total distance covered during the transportation or distribution of goods. This data is essential for calculating emissions based on the transportation distance.'
    },
    {
        query: 'What does the units column mean?',
        ans: 'The units column specifies the measurement units used to track distance or weight, such as kilometres, miles, or tons. This ensures standardized reporting and accurate emissions calculations.'
    },
    {
        query: 'What kind of document should be attached in the evidence column?',
        ans: 'Documents such as bills, invoices, or other relevant supporting materials can be attached in the evidence column. These documents should validate the data entered and can be in formats like PDF, Word, or image files.'
    }
]

export const stationaryCombustion = [
    {
        query: 'What is stationary combustion?',
        ans: 'Stationary combustion refers to the burning of fuels in fixed equipment, such as boilers, furnaces, or generators, at a single location to produce energy. This process generates greenhouse gases like carbon dioxide (CO₂), methane (CH₄), and nitrous oxide (N₂O).'
    },
    {
        query: 'Why is stationary combustion important in Net0Trace?',
        ans: "Stationary combustion is a significant source of emissions, especially in industries and buildings where fuels are burned for heat, power, or other processes. Accurately reporting these emissions is essential for tracking an organization's environmental impact and ensuring compliance with regulations."
    },
    {
        query: 'What is a source description?',
        ans: 'A source description is a concise identifier or label that describes the type or nature of a stationary combustion source. Examples include industrial boilers, heating furnaces, incinerators, and power plants.'
    },
    {
        query: 'What does source area mean?',
        ans: 'Source area refers to the physical space or size of the facility or equipment where emissions are generated.'
    },
    {
        query: 'What does fuel combusted mean?',
        ans: 'Fuel combusted refers to the process of burning fuels. The "Fuel Combusted" column is a dropdown where you can select the specific type of fuel and enter the data. Available fuels in the dropdown include anthracite coal, bituminous coal, biodiesel, kerosene, petrol, gasoline, diesel, LPG, and more.'
    },
    {
        query: 'What does fuel state mean?',
        ans: 'Fuel state refers to the physical form of the fuel being used. Options available in the dropdown are:',
        list: [
            'Solid',
            'Liquid',
            'Gas'
        ],
        isListObjectPaired: false

    },
    {
        query: 'What does quantity combusted mean?',
        ans: 'Quantity combusted refers to the total amount or volume of fuel burned within a specific timeframe or for a particular activity.'
    },
    {
        query: 'What does "units" mean?',
        ans: 'Units refer to the measurement units for the quantity of fuel combusted, which vary depending on the type of fuel and context. Common units include:',
        list: [
            'Tonnes',
            'Litres',
            'Cubic meters',
            'Gallons',
            'Kilograms'
        ],
        isListObjectPaired: false
    },
    {
        query: 'Which columns are auto-populated?',
        ans: 'When you select the type of fuel in the "Fuel Combusted" dropdown, the "Fuel State" and "Units" columns are automatically populated based on the selected fuel.'
    },
    {
        query: 'Can I submit without entering data in all columns?',
        ans: 'No, you cannot submit without filling in all columns with appropriate data. If any column is left empty, an error will be displayed, preventing submission.'
    },
    {
        query: 'What kind of document should be attached in the evidence column?',
        ans: 'Documents such as bills, invoices, or other relevant supporting materials can be attached in the evidence column. These documents should validate the data entered and can be in formats like PDF, Word, or image files.'
    }
]

export const mobileSource = [
    {
        query: 'What are Mobile Sources?',
        ans: 'Mobile sources refer to vehicles and equipment that are capable of moving or being moved, such as cars, trucks, buses, motorcycles, ships, airplanes, and construction equipment. These are typically powered by fossil fuels, resulting in greenhouse gas emissions.'
    },
    {
        query: 'How do mobile sources contribute to Net0Trace?',
        ans: 'Mobile sources contribute to emissions by burning fossil fuels like gasoline, diesel, and natural gas, which release carbon dioxide (CO₂), methane (CH₄), and nitrous oxide (N₂O). Additionally, leaks from air conditioning and refrigeration systems in vehicles release hydrofluorocarbons (HFCs), which have a significant impact on the environment.'
    },
    {
        query: 'What does source description mean?',
        ans: 'Source description is a brief label or identifier used to categorize and describe the type of vehicle or equipment generating greenhouse gas emissions. Examples include office fleet, construction equipment, agricultural machinery, delivery vehicles, marine vessels, and aviation.'
    },
    {
        query: 'What does on-road mean?',
        ans: 'On-road refers to vehicles and equipment designed and used primarily for transportation on public roads and highways.'
    },
    {
        query: 'What does non-road mean?',
        ans: 'Non-road refers to vehicles and equipment that are not typically used on public roads, such as construction equipment or marine vessels.'
    },
    {
        query: 'What vehicle types fall under on-road?',
        ans: 'On-road vehicle types include cars, trucks, buses, motorcycles, and other vehicles designed for use on public roads and highways.'
    },
    {
        query: 'What vehicle types fall under non-road?',
        ans: 'Non-road vehicle types include agricultural machinery, construction equipment, marine vessels, aircraft, and recreational vehicles.'
    },
    {
        query: 'What does the vehicle year column denote?',
        ans: 'The vehicle year column indicates the manufacturing year of the vehicle. It provides insights into the vehicle’s age, which can affect its emissions and efficiency.'
    },
    {
        query: 'Does every vehicle type have a vehicle year?',
        ans: 'No, not all vehicle types have a vehicle year. For on-road vehicle types, fuels such as ethanol, methanol, CNG, LPG, and LNG do not have a vehicle year. Non-road vehicle types also do not have a vehicle year. For these cases, the vehicle year is displayed as "N/A" and is automatically triggered when selecting the vehicle type.'
    },
    {
        query: 'Which vehicle types have a vehicle year, and how do I select it?',
        ans: 'Only on-road vehicle types have a vehicle year. For the applicable vehicle type, you can select the vehicle year from the dropdown menu and proceed.'
    },
    {
        query: 'What does the fuel usage column mean?',
        ans: 'The fuel usage column specifies the amount of fuel consumed by a vehicle or equipment. This data helps calculate the total fuel usage over a specific period or operation.'
    },
    {
        query: 'What does the unit column denote?',
        ans: 'The unit column indicates the measurement unit for fuel usage or emissions data. Common units include litres, gallons, kilograms, and cubic meters, specifying the standard for measuring fuel or emissions quantities.'
    },
    {
        query: 'What does the distance travelled column include?',
        ans: 'The distance travelled column captures the total distance covered by a vehicle or equipment, which is useful for calculating fuel efficiency and emissions over a specific range.'
    },
    {
        query: 'What does distance unit mean?',
        ans: 'The distance unit represents the measurement unit for the distance travelled by the vehicle or equipment. Common units include kilometres, miles, or nautical miles.'
    },
    {
        query: 'Which columns are auto-populated?',
        ans: 'Based on the selected vehicle type, the following columns are auto-populated:',
        list: [
            'Vehicle year (for applicable vehicle types).',
            'Units (for fuel usage).',
            'Distance units (for distance travelled).'
        ]
    },
    {
        query: 'Is it necessary to enter data in all columns?',
        ans: 'Yes, all columns must be filled with the required data. If any column is left incomplete, the system will display an error and prevent submission.',
    },
    {
        query: 'What kind of document should be attached in the evidence column?',
        ans: 'Documents such as bills, invoices, or other relevant supporting materials can be attached in the evidence column. These documents should validate the data entered and can be in formats like PDF, Word, or image files.'
    }
]

export const refrigerationAcEquipmentUse = [
    {
        query: 'What are refrigerants?',
        ans: 'Refrigerants are chemical compounds used in air conditioning (AC) and refrigeration systems to absorb heat and provide cooling. Common refrigerants include hydrofluorocarbons (HFCs), chlorofluorocarbons (CFCs), and natural refrigerants like ammonia and carbon dioxide.'
    },
    {
        query: 'How do refrigerants contribute to Net0Trace?',
        ans: 'Refrigerants contribute to emissions when they leak from AC and refrigeration systems. Many refrigerants have a high global warming potential (GWP), which means even small leaks can significantly impact the environment.'
    },
    {
        query: 'What is the global warming potential (GWP) of refrigerants?',
        ans: 'The global warming potential (GWP) of a refrigerant measures its impact on global warming relative to carbon dioxide (CO₂). Refrigerants like HFCs and CFCs have a high GWP. For instance, HFC-134a has a GWP of 1,430, making it 1,430 times more effective at trapping heat in the atmosphere than CO₂.'
    },
    {
        query: 'Is it necessary to enter data in all three tables?',
        ans: 'No, it is not necessary. If data is entered in the material balance table, the other two tables will be frozen. You can fill in data for any one of the three tables.'
    },
    {
        query: 'What does material balance mean?',
        ans: 'Material balance is a method to account for the quantities of refrigerants entering and leaving a system. It tracks refrigerants purchased, used, stored, and disposed of, ensuring the total amount is balanced. This method helps identify leaks, losses, and inefficiencies.'
    },
    {
        query: 'What does the gas column denote?',
        ans: 'The gas column specifies the type of refrigerant being tracked in the system, such as C2F6, R-134a, or R-401A.'
    },
    {
        query: 'What does inventory change mean?',
        ans: 'Inventory change refers to the difference in the refrigerant stock stored on-site (e.g., in cylinders) from the start to the end of the reporting period. It excludes refrigerants contained within equipment and accounts for changes due to usage, leaks, or additions.'
    },
    {
        query: 'What does transferred amount mean?',
        ans: 'Transferred amount refers to the quantity of refrigerant moved within the organization or sent off-site for recycling, reclamation, or disposal. It is calculated as the gas purchased minus the gas sold or disposed of during the reporting period.'
    },
    {
        query: 'What does capacity change mean?',
        ans: 'Capacity change refers to the change in total refrigerant capacity due to adding new equipment, retiring old equipment, or modifications. It is calculated as the capacity of new units minus the capacity of retired units.'
    },
    {
        query: 'What does simplified material balance mean?',
        ans: 'Simplified material balance is a streamlined method for tracking refrigerants entering and leaving a system, focusing on identifying leaks, losses, and inefficiencies.'
    },
    {
        query: 'What do existing units mean?',
        ans: 'Existing units refer to equipment already in use before the reporting period and still operational during the reporting period.'
    },
    {
        query: 'What do charge/recharge units mean?',
        ans: 'Charge/Recharge refers to the refrigerant added to equipment by the organization or a contractor, excluding gas pre-charged by the manufacturer.'
    },
    {
        query: 'What does recovered mean?',
        ans: 'Recovered refers to the refrigerant extracted from decommissioned or retired units during the reporting period.'
    },
    {
        query: 'What does the units column mean?',
        ans: 'The units column specifies the measurement units for refrigerants, such as kilograms, pounds, or litres.'
    },
    {
        query: 'What does type of equipment mean?',
        ans: 'The type of equipment refers to the category of refrigerant or AC systems being tracked, such as chillers, domestic refrigeration, etc. The type can be selected from a dropdown list.'
    },
    {
        query: 'What does the gas column denote?',
        ans: 'The gas column indicates the specific refrigerant being tracked, such as C2F6, R-134a, or R-401A.'
    },
    {
        query: 'What do new units mean?',
        ans: 'New units are equipment installed during the reporting period, excluding pre-charged units from the supplier.'
    },
    {
        query: 'What does charge mean?',
        ans: 'Charge refers to refrigerant added to units during the reporting period by the organization or a contractor, excluding gas pre-charged by the manufacturer.'
    },
    {
        query: 'What does capacity mean?',
        ans: 'Capacity refers to the total refrigerant capacity of all operating units, excluding new units pre-charged by the manufacturer.'
    },
    {
        query: 'What do operating units mean?',
        ans: 'Operating units are refrigerant and AC equipment actively used during the reporting period, including both new and existing units.'
    },
    {
        query: 'What do disposed units mean?',
        ans: 'Disposed units are equipment decommissioned and removed from service during the reporting period.'
    },
    {
        query: 'What kind of document should be attached in the evidence column?',
        ans: 'Documents such as bills, invoices, or other relevant supporting materials can be attached in the evidence column. These documents should validate the data entered and can be in formats like PDF, Word, or image files.'
    }

]

export const fireSuppressio = [
    {
        query: 'What is fire suppression?',
        ans: 'Fire suppression refers to systems and equipment designed to control, extinguish, or prevent the spread of fires. These systems utilize agents like water, foam, or chemical suppressants to protect buildings, vehicles, and other environments from fire damage.'
    },
    {
        query: 'How do fire suppression systems contribute to Net0Trace?',
        ans: 'Fire suppression systems can contribute to emissions through the release of fire suppressants like halons or hydrofluorocarbons (HFCs), which have high global warming potentials (GWPs). Such emissions may occur due to leaks, discharge during testing, or activation during fire incidents.'
    },
    {
        query: 'Is it mandatory to enter data in all three tables?',
        ans: 'No, it is not mandatory. If data is entered in the material balance table, the other two tables will be frozen. Therefore, data can be filled in any one of the three tables.'
    },
    {
        query: 'What does inventory change mean?',
        ans: 'Inventory change refers to the difference in the amount of gas stored in inventory from the beginning to the end of the reporting period. It includes only gas stored on-site (e.g., in cylinders) and not gas contained within the equipment.',
        Additional: 'Additional Information: Inventory Change = difference of gas stored in inventory from beginning to end of the reporting period. (Includes only gas stored on-site, i.e., cylinders, and not gas contained within equipment).'
    },
    {
        query: 'What does transferred amount mean?',
        ans: 'Transferred amount is calculated as the gas purchased minus the gas sold or disposed of during the reporting period.',
        Additional: 'Additional Information: Transferred Amount = gas purchased minus gas sold/disposed of during the reporting period.',
        Message: 'Gas purchased includes purchases for inventory, as part of equipment servicing (not from inventory), within purchased equipment, and gas returned to the site after off-site recycling. Gas sold/disposed includes returns to the supplier, sales or disposals (including within equipment), and gas sent off-site for recycling, reclamation, or destruction.'
    },
    {
        query: 'What does capacity change mean?',
        ans: 'Capacity change is calculated as the capacity of all units at the beginning of the reporting period minus the capacity of all units at the end of the reporting period. This can be assumed to be the capacity of new units minus the capacity of retired units.',
        Additional: 'Additional Information: Capacity Change = capacity of all units at the beginning minus the capacity of all units at the end of the reporting period (can be assumed to be the capacity of new units minus the capacity of retired units).'
    },
    {
        query: 'What is simplified material balance?',
        ans: 'Simplified material balance provides an easy method to track fire suppressant gas usage during a reporting period. It eliminates complex inventory tracking and focuses on estimating total gas used, recharged, and recovered to calculate emissions or meet regulatory compliance.'
    },
    {
        query: 'What does the gas column represent?',
        ans: 'The gas column indicates the type of fire suppressant gas being tracked, identifying specific chemical compounds like C4F10, HFC-227ea, or HFC-23.'
    },
    {
        query: 'What are new units?',
        ans: 'New units are fire suppression equipment installed during the reporting period. These do not include units pre-charged by the supplier.'
    },
    {
        query: 'What are existing units?',
        ans: 'Existing units refer to fire suppression equipment that was operational before and continues to be used during the reporting period. These units are not newly installed or disposed of within the current reporting period.'
    },
    {
        query: 'What are disposed units?',
        ans: 'Disposed units are fire suppression equipment that was decommissioned and discarded during the reporting period.'
    },
    {
        query: 'What is charge/recharge?',
        ans: 'Charge/recharge refers to the gas added to units by the organization or a contractor. This excludes gas pre-charged by the manufacturer.'
    },
    {
        query: 'What does capacity mean?',
        ans: 'Capacity refers to the total gas capacity of all units. It does not include units pre-charged by the manufacturer.'
    },
    {
        query: 'What does recovered mean?',
        ans: 'Recovered refers to the total quantity of gas recovered from decommissioned or retired units.'
    },
    {
        query: 'What does the units column represent?',
        ans: 'The units column specifies the measurement units for fire suppressant gases, such as kilograms, pounds, or litres. It ensures standardized reporting for accurate tracking and comparison.'
    },
    {
        query: 'What does the type of equipment mean?',
        ans: 'The type of equipment refers to categories of fire suppression equipment, such as fixed or portable systems. A dropdown list allows the user to select the appropriate category for further tracking.'
    },
    {
        query: 'What does the gas column represent in fire suppression?',
        ans: 'In fire suppression, the gas column identifies the type of fire suppressant gas being tracked, such as C4F10, HFC-125, or HFC-23. It ensures accurate reporting of the chemical compounds used in the equipment.'
    },
    {
        query: 'What kind of document should be attached in the evidence column?',
        ans: 'Documents such as bills, invoices, or other relevant supporting materials can be attached in the evidence column. These documents should validate the data entered and can be in formats like PDF, Word, or image files.'
    }
]

export const purchasedGases = [
    {
        query: 'What are purchased gases in Net0Trace?',
        ans: 'Purchased gases are gases that an organization acquires for use in processes such as manufacturing, refrigeration, or laboratory operations. These gases may contribute to emissions either directly through their release or indirectly through their impact on the environment.'
    },
    {
        query: 'Which gases are commonly considered purchased gases?',
        ans: 'Commonly considered purchased gases include carbon dioxide (CO2), methane (CH4), nitrous oxide (N2O), refrigerants like hydrofluorocarbons (HFCs), and industrial gases such as sulfur hexafluoride (SF6).'
    },
    {
        query: 'How do purchased gases contribute to Net0Trace?',
        ans: 'Purchased gases contribute to emissions when they are released into the atmosphere due to leaks, losses, or during their use. Some gases, like CO2, contribute directly to emissions, while others, such as HFCs and SF6, have a significantly higher global warming potential (GWP).'
    },
    {
        query: 'How do I select the gases?',
        ans: 'Gases can be selected from a dropdown list provided in the system, which includes all relevant options.'
    },
    {
        query: 'What does purchased amount mean?',
        ans: 'Purchased amount refers to the total quantity of gas acquired by the organization during the reporting period. This includes gas purchased for immediate use or storage.'
    },
    {
        query: 'What does the units column mean?',
        ans: 'The units column specifies the measurement units for the quantities of purchased gases, such as kilograms, pounds, or cubic meters. This standardization ensures accurate tracking and reporting of gas usage.'
    },
    {
        query: 'What kind of document should be attached in the evidence column?',
        ans: 'Documents such as bills, invoices, or other relevant supporting materials can be attached in the evidence column. These documents should validate the data entered and can be in formats like PDF, Word, or image files.'
    }
]

export const purchasedAndConsumedElectricity = [
    {
        query: 'What does purchased and consumed electricity mean?',
        ans: 'Purchased and consumed electricity refers to the total amount of electricity bought from external sources, such as the power grid or energy providers, and used within an organization during a specific period. This includes electricity utilized for operations, equipment, and facility needs.'
    },
    {
        query: 'What does source description mean?',
        ans: 'The source description is automatically populated based on the location type selected in the locations menu. The system fetches the relevant information and displays it here.'
    },
    {
        query: 'What does source area (Sq. ft) mean?',
        ans: 'The source area refers to the physical size of the space, measured in square feet, where electricity consumption is being tracked. It represents the area of the facility and is useful for analysing energy efficiency or intensity. If the value is entered in the locations screen, it is automatically captured here; otherwise, you can manually input it.'
    },
    {
        query: 'What does electricity purchased mean?',
        ans: 'Electricity purchased refers to the total amount of electricity obtained from external sources, such as the electricity grid or energy providers, during a specified period. This is the electricity consumed by your organization but not self-generated.'
    },
    {
        query: 'What does electricity source mean?',
        ans: 'Electricity source refers to the origin of the electricity consumed, such as renewable energy, non-renewable energy, or other sources. You can select the appropriate source from the dropdown list provided in the system.'
    },
    {
        query: 'What does market-based electricity mean?',
        ans: 'Market-based electricity refers to electricity emissions accounting based on the specific energy mix of the suppliers or contracts used by the organization. It allows for consideration of renewable energy certificates (RECs) or supplier-specific emissions factors.'
    },
    {
        query: 'What do CO₂, CH₄, and N₂O emissions mean under the market-based heading?',
        ans: 'CO₂, CH₄, and N₂O emissions under the market-based heading represent the greenhouse gases emitted as a result of electricity consumption at a specific location. These emissions are calculated using the energy mix of the electricity grid serving that location and include carbon dioxide (CO₂), methane (CH₄), and nitrous oxide (N₂O).'
    },
    {
        query: 'Do I need to enter all CO₂, CH₄, and N₂O emissions?',
        ans: 'No, it is not necessary to enter all CO₂, CH₄, and N₂O emissions. You can provide data for the emissions you have (if available) and proceed with the subsequent steps.'
    },
    {
        query: 'What kind of document should be attached in the evidence column?',
        ans: 'Documents such as bills, invoices, or other relevant supporting materials can be attached in the evidence column. These documents should validate the data entered and can be in formats like PDF, Word, or image files.'
    }
]

export const purchasedAndConsumedSteam = [
    {
        query: 'What does purchased and consumed steam mean?',
        ans: 'Purchased and consumed steam refers to the total amount of steam bought from external suppliers and used within an organization for various processes, such as heating, industrial operations, or other applications, during a specific timeframe.'
    },
    {
        query: 'What does source description mean?',
        ans: 'The source description refers to a brief label or identifier that describes the specific source of steam usage within an organization. It provides context about how and where the steam is utilized.'
    },
    {
        query: 'What does source area (Sq. ft) mean in the steam category?',
        ans: 'The source area (Sq. ft) refers to the physical space or area associated with the steam source. It defines the size of the area where steam is either consumed or generated.'
    },
    {
        query: 'What does fuel type mean?',
        ans: 'Fuel type refers to the specific kind of fuel used in the steam generation process. You can select the relevant fuel type for your location from the dropdown list provided.'
    },
    {
        query: 'What does fuel state mean?',
        ans: 'Fuel state refers to the physical state of the fuel, such as solid, liquid, or gas. The system will automatically populate the fuel state based on the selected fuel type.'
    },
    {
        query: 'What does boiler efficiency (%) mean?',
        ans: 'Boiler efficiency (%) measures the percentage of heat energy from the fuel that is effectively used to generate steam. It indicates the boiler’s performance in converting fuel energy into steam energy.'
    },
    {
        query: 'What does steam purchased mean?',
        ans: 'Steam purchased refers to the total amount of steam bought from an external supplier during a specific period. This helps track steam usage that is not generated on-site.'
    },
    {
        query: 'What does the units column denote?',
        ans: 'The units column indicates the measurement units used for steam, fuel, or other related metrics, such as kilograms, pounds, cubic meters, or megajoules. This standardizes the reporting for accurate tracking and comparison.'
    },
    {
        query: 'What is the difference between location-based and market-based emissions, which include CO₂, CH₄, and N₂O emission values?',
        list: [
            'Location - based emissions are calculated using the average emission factors of the electricity grid where the energy is consumed.',
            'Market - based emissions are calculated using emission factors specific to the energy supplier or contractual arrangements.'
        ],
        isListObjectPaired: true,
        Message: 'Both types of emissions account for CO₂, CH₄, and N₂O.'
    },
    {
        query: 'What if I do not enter location-based and market-based emissions values? Can I proceed without entering them?',
        ans: 'Yes, you can proceed without entering these values. If the data is available, you can select the checkbox at the top to input the respective values. Otherwise, you may skip this step and continue.'
    },
    {
        query: 'What kind of document should be attached in the evidence column?',
        ans: 'Documents such as bills, invoices, or other relevant supporting materials can be attached in the evidence column. These documents should validate the data entered and can be in formats like PDF, Word, or image files.'
    }
]

export const employeeBusinessTravel = [
    {
        query: 'What does business travel mean?',
        ans: 'Business travel refers to the trips undertaken by employees for work-related purposes, such as client meetings, conferences, or site visits. These trips often involve various modes of transportation, including cars, trains, flights, or public transit.'
    },
    {
        query: 'What does source description mean?',
        ans: 'The source description refers to a brief label or identification that specifies the type of business travel, such as the mode of transportation or specific trips undertaken by employees. It helps categorize travel activities for GHG tracking purposes.'
    },
    {
        query: 'What does the type column denote?',
        ans: 'The type column provides a dropdown menu with options like "Personal," "Public," and "Airways." You can select the appropriate travel mode for the business trip from this list.'
    },
    {
        query: 'What does vehicle type denote?',
        ans: 'The vehicle type column displays a dropdown menu based on the selected travel type. If "Personal" or "Public" is chosen in the type column, the flight length column is disabled. This column allows you to specify the type of vehicle used for travel.'
    },
    {
        query: 'What does flight length denote?',
        ans: "If 'Airways' is selected in the type column, the flight length column becomes active, enabling you to choose the flight's length from the dropdown options. In this case, the vehicle type column is disabled."
    },
    {
        query: 'What does the distance travelled column denote?',
        ans: 'The distance travelled column represents the total distance covered during business trips. This value is essential for calculating emissions generated by the transportation mode used for business travel.'
    },
    {
        query: 'What does the units column denote?',
        ans: 'The units column specifies the measurement units for tracking business travel, such as kilometres or miles. It ensures consistency and accuracy in reporting and comparison of travel data.'
    },
    {
        query: 'What kind of document should be attached in the evidence column?',
        ans: 'Documents such as bills, invoices, or other relevant supporting materials can be attached in the evidence column. These documents should validate the data entered and can be in formats like PDF, Word, or image files.'
    },
]

export const employeeComputing = [
    {
        query: 'What does employee commuting mean?',
        ans: 'Employee commuting refers to the daily travel of employees between their homes and the workplace. This includes any mode of transportation used, such as personal vehicles, public transit, bicycles, or walking. Tracking this data helps assess emissions from employee travel patterns.'
    },
    {
        query: 'What does source description mean?',
        ans: 'The source description refers to a brief identification or label that outlines the specific commuting activity of employees, such as the mode of transportation used to travel to and from work. This helps in categorizing commuting activities for emissions tracking.'
    },
    {
        query: 'What does the type column denote?',
        ans: 'The type column includes a dropdown menu with options like "Personal" and "Public," allowing you to select the appropriate mode of commuting based on how employees travel to work.'
    },
    {
        query: 'What does vehicle type mean?',
        ans: 'The vehicle type column provides a dropdown menu with specific vehicle options based on the selected commute type. This allows you to choose the exact type of vehicle used for personal or public commuting.'
    },
    {
        query: 'What does the distance travelled column mean?',
        ans: 'The distance travelled column represents the total distance covered by employees during their commute. This information is essential for calculating emissions associated with employee commuting.'
    },
    {
        query: 'What does the units column mean?',
        ans: 'The units column specifies the measurement units used for tracking the distance travelled by employees, such as kilometres or miles. It ensures standardization and accuracy in reporting and analysis.'
    },
    {
        query: 'What kind of document should be attached in the evidence column?',
        ans: 'Documents such as bills, invoices, or other relevant supporting materials can be attached in the evidence column. These documents should validate the data entered and can be in formats like PDF, Word, or image files.'
    }
]

export const waste = [
    {
        query: 'What does direct waste mean?',
        ans: 'Direct waste refers to waste generated by an organization’s operations that is directly disposed of, recycled, or processed. This includes materials such as paper, plastic, organic waste, or hazardous substances that are managed through various waste disposal or treatment methods.'
    },
    {
        query: 'What does indirect waste mean?',
        ans: 'Indirect waste refers to waste generated as a result of an organization’s operations but not directly produced on-site. This may include waste from outsourced activities, supply chains, or other indirect processes that contribute to emissions.'
    },
    {
        query: 'What does source description mean?',
        ans: 'The source description refers to a brief label or explanation of the specific waste activity being tracked, such as the type of waste generated. It helps categorize the waste management process for emissions calculations.'
    },
    {
        query: 'What is waste material?',
        ans: 'Waste material refers to the type of material being disposed of or recycled, such as plastic, metal, paper, or organic waste. This helps in identifying the nature of the waste for GHG reporting and emissions tracking.'
    },
    {
        query: 'What is disposal type?',
        ans: 'The disposal type column contains two options:',
        list: [
            'Directed to Disposal - Waste is sent directly to a disposal site, such as a landfill.',
            'Diverted from Disposal - Waste is redirected for recycling, composting, or other reuse methods.'
        ],
        isListObjectPaired: true
    },
    {
        query: 'What is disposal method?',
        list: [
            'Direct Waste - The disposal method column is populated based on the selected disposal type. It includes specific waste management methods, such as incineration, landfilling, or recycling.',
            'Indirect Waste - The disposal method column provides a dropdown menu populated based on the selected waste material. Options include methods such as landfilling, recycling, or combusted, depending on the type of waste.'
        ],
        isListObjectPaired: true
    },
    {
        query: 'What does the hazardous/non-hazardous waste column mean?',
        ans: 'This column specifies whether the waste material is:',
        list: [
            'Hazardous - Poses risks to health or the environment and requires special handling.',
            'NonHazardous - Does not pose significant risks and has standard disposal requirements.'
        ],
        isListObjectPaired: true
    },
    {
        query: 'What does the onsite/offsite column mean?',
        ans: 'The onsite/offsite column indicates where the waste is processed or disposed of:',
        list: [
            'Onsite - Managed within the organization’s premises.',
            'Offsite - Processed or disposed of at an external location.'
        ],
        isListObjectPaired: true
    },
    {
        query: 'What does the organic/inorganic waste column mean?',
        ans: 'This column identifies the type of waste material:',
        list: [
            'Organic Waste - Biodegradable materials, such as food waste or plant materials.',
            'Inorganic Waste - Non biodegradable materials, such as plastics or metals.'
        ],
        isListObjectPaired: true
    },
    {
        query: 'What does the weight column mean?',
        ans: 'The weight column represents the total weight of the waste material being processed or disposed of. This value is critical for calculating emissions related to waste.'
    },
    {
        query: 'What does the units column mean?',
        ans: 'The units column specifies the measurement units used to report the weight of the waste. Options include kilograms, tons, or cubic meters, and you can select the appropriate unit from the dropdown. This ensures accurate and standardized reporting.'
    },
    {
        query: 'What kind of document should be attached in the evidence column?',
        ans: 'Documents such as bills, invoices, or other relevant supporting materials can be attached in the evidence column. These documents should validate the data entered and can be in formats like PDF, Word, or image files.'
    }
]

export const water = [
    {
        query: 'What does water consumption mean?',
        ans: 'Water consumption refers to the total volume of water used by an organization for various activities. This includes both recycled and non-recycled water sources.'
    },
    {
        query: 'What does the category column mean?',
        ans: 'The category column contains a dropdown menu with two options: Recycled and Non-recycled.',
        list: [
            'Recycled - Allows you to enter details in the upcoming columns related to recycled water.',
            'Non recycled - Enables input for non recycled water details but restricts certain fields like treatment type.'
        ]
    },
    {
        query: 'What is water source?',
        ans: 'The water source column provides a dropdown list from which you can select the type of water source used, such as groundwater, municipal supply, or rainwater.'
    },
    {
        query: 'What is water consumption?',
        ans: 'Water consumption requires you to enter the total volume of water consumed for the selected water source category (recycled or non-recycled).'
    },
    {
        query: 'What does energy used (kWh) mean?',
        ans: 'This column captures the total energy consumption, measured in kilowatt-hours (kWh), associated with the water consumption process, including pumping, treatment, or distribution.'
    },
    {
        query: 'What does treatment type denote?',
        ans: 'The treatment type column becomes available only if Recycled is selected as the category. You can choose the specific treatment method (e.g., filtration, reverse osmosis) from the dropdown list.',
        Message: 'If Non-recycled is selected, this column remains disabled.'
    },
    {
        query: 'What does recycled water used mean?',
        ans: 'This column allows you to input the volume of recycled water used, but only if Recycled is selected in the category column. If Non-recycled is selected, this column remains disabled.'
    },
    {
        query: 'What does water discharge mean?',
        ans: 'Water discharge refers to the release or disposal of wastewater from a specific source, typically after use or treatment, into the environment or designated discharge destinations. It helps track and manage water usage and its impact on the environment.'
    },
    {
        query: 'What does wastewater type mean?',
        ans: 'The wastewater type column allows you to select the specific type of wastewater being discharged (e.g., industrial, domestic, or other types) from a dropdown menu. Based on your selection, subsequent columns will populate with their respective dropdown options.'
    },
    {
        query: 'What is water discharge destination?',
        ans: 'Water discharge destination refers to the location where the wastewater is discharged, such as rivers, lakes, municipal systems, or land. You can select the appropriate option from the dropdown menu.'
    },
    {
        query: 'What is level of treatment?',
        ans: 'The level of treatment indicates the extent to which the wastewater has been treated before discharge (e.g., primary, secondary, or tertiary). This information can be selected from the dropdown menu.'
    },
    {
        query: 'What does the water volume mean?',
        ans: 'Water volume refers to the total quantity of water being discharged. This value should be entered to calculate water-related metrics.'
    },
    {
        query: 'What does the units column denote?',
        ans: 'The units column specifies the measurement units for reporting the water volume, such as liters, cubic meters, or gallons.'
    },
    {
        query: 'What kind of document should be attached in the evidence column?',
        ans: 'Documents such as bills, invoices, or other relevant supporting materials can be attached in the evidence column. These documents should validate the data entered and can be in formats like PDF, Word, or image files.'
    }
]

export const approvalQueue = [
    {
        query: 'What is an Approval Queue?',
        ans: 'The approval queue is a list that displays all data submissions awaiting approval. It includes details such as location name, period, status, submitted by, submission date and time, and approval/rejection date and time.'
    },
    {
        query: 'When does data appear in the approval queue?',
        ans: 'Data appears in the approval queue after the analyst submits it for approval. The submission details, including the location, period, and status, are displayed in the queue.'
    },
    {
        query: 'How can I view the data in the approval queue?',
        ans: 'You can view the data by clicking on the location name in the approval queue. The location name is displayed as a hyperlink, which navigates to the data entry screen. On this screen, you can view all the entered data for each category in read-only mode.'
    },
    {
        query: 'How can I approve the data entry?',
        ans: 'To approve the data entry:',
        list: [
            'Navigate to the data entry screen by clicking on the location name.',
            'Scroll to the bottom of the page, where you will find the option to approve.',
            'Click "Approve," and the approval status will be updated in the approval queue.'
        ]
    },
    {
        query: 'How can I reject the data entry?',
        ans: 'To reject the data entry:',
        list: [
            'Navigate to the data entry screen by clicking on the location name.',
            'Scroll to the bottom of the page, where you will find the option to reject.',
            'If you find any issues with the data, click "Reject."',
            'Enter the reason for rejection in the provided text box and click "Submit."',
            'The rejection status,  will be updated in the approval queue.'
        ]
    },
    {
        query: 'Do I have write access in the data entry screen while approving or rejecting data?',
        ans: 'No, you will only have read-only access to the data entry screen during the approval or rejection process.'
    }
]

export const submitQueue = [
    {
        query: 'What is a Submit Queue?',
        ans: 'The submit queue is a list that displays all the data submissions made by an analyst, along with their current status in the approval process.'
    },
    {
        query: 'What information does the Submit Queue screen contain?',
        ans: 'The submit queue screen contains the same details as the approval queue, such as location name, period, status, submitted by, submission date and time, and approval/rejection date and time. However, unlike the approval queue, it does not allow navigation to the data entry screen. This screen is designed for analysts to view the approval or rejection status of their submissions.'
    },
    {
        query: 'What are the different status options displayed on this screen?',
        ans: 'The status options displayed on the submit queue screen include:',
        list: [
            'Approved - Indicates the submission has been approved.',
            'Rejected - Indicates the submission has been rejected.',
            'Pending for Approval - Indicates the submission is awaiting approval.'
        ],
    }
]

export const progress = [
    {
        query: 'What is the purpose of the progress screen?',
        ans: 'The progress screen provides an overview of the status of data entries for a specific location. It allows users to track the progress of their submissions across various periods.'
    },
    {
        query: 'What statuses are displayed on the progress screen?',
        ans: 'The progress screen displays the following statuses for the respective location and period:',
        list: [
            'Save as Draft - Indicates data entries that are still being worked on and have not yet been submitted.',
            'Approved - Indicates data entries that have been reviewed and approved.',
            'Rejected - Indicates data entries that have been reviewed and rejected.'
        ],
        Message: 'Based on the status for the respective location and period, the progress screen will display the relevant details.'
    }
]

export const net0Planner = [
    {
        query: 'What is Net Zero Planner?',
        ans: 'The Net Zero Planner is a tool that helps organizations plan and track their progress toward achieving net-zero emissions by setting targets, reducing emissions, and monitoring reductions over time.'
    },
    {
        query: 'What is Base Year?',
        ans: 'The base year refers to the starting year selected for tracking and measuring emissions reductions. It serves as the benchmark against which progress is compared.'
    },
    {
        query: 'What year should be selected as a Base Year?',
        ans: 'The base year should be one for which you have emissions data entered. You can select the base year from the dropdown list.'
    },
    {
        query: 'What is Target Year?',
        ans: 'The target year is the year by which your organization aims to achieve its emissions reduction goals or net-zero target.'
    },
    {
        query: 'What year should be selected as a Target Year?',
        ans: 'The target year is chosen based on your organization’s goals for contributing to net-zero emissions. You can select the desired year from the dropdown list.'
    },
    {
        query: 'What is Reduction Percentage?',
        ans: 'The reduction percentage indicates the percentage of emissions reduction achieved compared to the base year. This value will be auto-populated based on the reduction values entered.'
    },
    {
        query: 'How to Reduce the Value?',
        ans: 'You can reduce the values for emissions in each year and category by manually entering the desired reduction percentages or amounts.'
    },
    {
        query: 'Is it necessary to reduce the value for each year and each category?',
        ans: 'It is entirely up to your organization’s decision whether to reduce emissions values for each year and category.'
    },
    {
        query: 'Why is the Base Year Value populated until the Target Year?',
        ans: 'The base year value is automatically carried forward to the target year, and it adjusts dynamically based on the reductions entered for each respective year.'
    },
    {
        query: 'What is Offset?',
        ans: 'Offset refers to the use of projects or initiatives that reduce, avoid, or remove carbon emissions from the atmosphere to compensate for emissions that an organization cannot reduce directly.'
    },
    {
        query: 'Is it necessary to enter the Offset Value?',
        ans: 'If an offset value is available, you can enter it along with a description.'
    },
    {
        query: 'Will the changes get automatically saved?',
        ans: 'No, the changes will not be automatically saved. After setting the target year and entering reduction values, you must click the Submit button at the bottom of the page to save the changes.'
    }

]

export const actionPlan = [
    {
        query: 'What is an Action Plan?',
        ans: 'An action plan is a structured framework that outlines the steps your organization will take to reduce emissions and achieve its net-zero goals.'
    },
    {
        query: 'What are the benefits of an Action Plan?',
        ans: 'An action plan helps your organization strategize and track progress toward reducing emissions effectively, aligning with sustainability goals and contributing to a net-zero society'
    },
    {
        query: 'What does the Action Plan screen contain?',
        ans: 'The Action Plan screen in the Net Zero Planner displays all areas where emission values have been reduced. It allows you to create and document specific plans detailing how your organization intends to achieve those reductions.'
    },
    {
        query: 'Is it necessary to enter all the action items?',
        ans: 'Yes, entering all action items is crucial as it helps your organization effectively plan and progress toward a net-zero society.'
    },
    {
        query: 'Is the Action Plan screen available for download?',
        ans: 'Yes, the Action Plan screen can be downloaded. The download option is located at the top-right corner of the screen.'
    },
    {
        query: 'Is submission required for the Action Plan?',
        ans: 'Yes, once you have entered all the action plan details, you must click Submit at the bottom of the screen to save and finalize the plan.'
    },
]

export const executiveDashboard = [
    {
        query: 'What is the Executive Dashboard?',
        ans: 'The Executive Dashboard is a high-level platform that consolidates emissions data and key metrics for organizational decision-making. It provides actionable insights across four primary screens:',
        list: [
            'Executive Overview - Offers a broad view of emissions data.',
            'Key Insights - Enables deep exploration of emissions patterns.',
            'Forecasting - Predicts future trends in emissions.',
            "Environmental Impact - Highlights the environmental consequences of the organization's activities"
        ],
        isListObjectPaired: true
    },
    {
        query: 'What does the Executive Overview screen capture?',
        ans: 'The Executive Overview screen focuses on summarizing critical emissions data, including:',
        list: [
            'The top 10 categories contributing to emissions.',
            'Revenue intensity and other intensity metrics.',
            'Slicerdicer options that allow users to apply filters and tailor the data view.',
            "Chart options to visually represent the data for better understanding."
        ],
        isListObjectPaired: false,

        Notes: 'This screen serves as the starting point for understanding where emissions are highest and which areas require attention.'
    },
    {
        query: 'What does the Key Insights screen provide?',
        ans: 'The Key Insights screen is a tool for analysing emissions data in depth. It allows users to:',
        list: [
            'Use filters to explore different combinations of data.',
            'Identify areas of high and low emissions contributions.',
        ],
        isListObjectPaired: false,

        Notes: 'This screen is designed to help organizations understand where they excel and where improvements are needed in terms of emissions reduction.'
    },
    {
        query: 'What does the Forecasting screen provide?',
        ans: 'The Forecasting screen uses historical data and current trends to predict future emissions.',
        list: [
            'The forecasting screen visualizes emission trends over time, allowing for predictions based on historical data.',
            'It provides insights into minimum, maximum, and average emissions values, enabling better planning.',
            'The graph highlights significant peaks and reductions, helping identify years with notable changes in emissions.',
            'Filters are available for detailed exploration of data, tailored to specific requirements or customer insights.',
            'This tool supports organizations in setting goals and tracking progress towards emission reduction targets.'
        ],
        isListObjectPaired: false,
    },
    {
        query: 'What does the Environmental Impact screen provide?',
        ans: "Environmental impact refers to the effect that an organization's activities have on the natural environment. It measures contributions to factors like climate change, resource usage, pollution, and ecological shifts.",
        list: [
            'Temperature Rise - Displays the projected increase in temperature (°C) caused by Scope 1, Scope 2, and Scope 3 emissions, with a percentage rise.',
            'Emissions Overview - Shows total CO2-e emissions in metric tons, categorized by scope',
            'Country wise Impact - Lists countries and their respective temperature rise values.',
            'Visualization Tools - Includes a thermometer graphic for temperature thresholds and a line chart to track trends over time.',
            'Interactive Filters - Offers filters to analyse data by group, country, and location for better insights into environmental impacts.'
        ],
        isListObjectPaired: true
    },
    {
        query: 'Can I download this dashboard?',
        ans: 'Yes, this screen is available for download. By clicking Export PDF at the top right corner, the file will be downloaded and can be accessed under the DOWNLOAD section in SETTINGS.'
    },
    {
        query: 'Can I use this downloaded dashboard as proof for any external meetings?',
        ans: 'Yes, the downloaded dashboard can be used as proof or supporting documentation in external meetings or for other purposes.'
    }

]

export const targetAndActual =
    [
        {
            query: 'What does the Target vs Actual dashboard represent?',
            ans: 'The Target vs Actual dashboard provides a comparison between your actual emissions and your target emissions over the selected timeframe.'
        },
        {
            query: 'How is the data populated in this dashboard?',
            ans: 'The data is populated based on the selection of the base year and target year on the Net Zero Planner page. All relevant and respective information is displayed accordingly.'
        },
        {
            query: 'What is the "latest year"?',
            ans: 'The "latest year" refers to the most recent year for which actual emission data is available.'
        },
        {
            query: 'What does the Emissions table represent?',
            ans: 'The Emissions table displays details such as the base year, base year emissions, the latest year, latest year emissions, the target year, and the target year emissions.'
        },
        {
            query: 'What does the Reduction Percentage table represent?',
            ans: 'The Reduction Percentage table shows:',
            list: [
                'The target reduction percentage (base year to target year).',
                'The actual reduction percentage (base year to the latest year).',
                'The remaining percentage gap between the target and the actual.'
            ]
        },
        {
            query: 'What does the Environmental Benefit table represent?',
            ans: 'The Environmental Benefit table showcases the positive impacts of emission reductions, including the number of vehicle miles saved, the number of trees planted, and the total cost savings achieved. It highlights how these actions contribute to reducing environmental footprint, conserving resources, and generating cost efficiencies, ultimately supporting sustainability efforts.'
        },
        {
            query: 'What does the Line Chart represent?',
            ans: 'The line chart displays actual and target emissions over time. The gap between the lines represents the difference between actual emissions and target emissions for each year.'
        },
        {
            query: 'What does the Bar Chart represent?',
            ans: 'The green bars represent actual emission values, while the black line indicates target year emissions. Hovering over the chart will display detailed information about each bar.'
        },
        {
            query: 'Can I download this dashboard?',
            ans: 'Yes, you can download this dashboard. By clicking Export PDF at the top right corner, the file will be saved and can be accessed in the Downloads section under Settings.'
        },
        {
            query: 'Can I use this downloaded dashboard as proof for external meetings?',
            ans: 'Yes, the downloaded dashboard can be used as proof or supporting documentation in external meetings or for other purposes.'
        }
    ]

export const emissionInsights = [
    {
        query: 'What does the Emission Insights Dashboard represent?',
        ans: "The Emission Insights Dashboard provides a comprehensive view of your organization's emissions data, enabling you to analyse and track emissions across different categories and periods."
    },
    {
        query: 'What does Scope 1 details capture?',
        ans: 'Scope 1 details display the total emissions your organization has emitted during the selected period. The screen includes various filter options that allow you to select specific criteria and view the corresponding emissions data. When a filter is selected, the emission value at the top and all the charts (bar, line) will display data for that particular category alone.'
    },
    {
        query: 'What does Scope 2 details capture?',
        ans: 'Scope 2 details show the total emissions your organization emitted during the chosen period. The screen includes several filter options for selecting specific data points, and when filters are applied, the emission value at the top and the charts will reflect the emissions for that category alone.'
    },
    {
        query: 'What does Scope 3 details capture?',
        ans: 'Scope 3 details represent the total emissions emitted by your organization during the selected period. Like other sections, it offers various filters for specific categories, and selecting a filter will adjust the displayed emission value and chart to reflect only that category’s data.'
    },
    {
        query: 'What does renewable energy capture?',
        ans: 'Renewable energy details show the total amount of renewable energy your organization used during the selected period. Filters are available to refine the data, and once a filter is applied, the value at the top and all charts will display the relevant data for that specific category.'
    },
    {
        query: 'What does Deep Analysis capture?',
        ans: 'Deep Analysis presents data in a hierarchical format. It starts with the total emissions, followed by groups, each showing the corresponding emissions value. You can click on a group name to view the emissions for countries within that group. The same process applies to other levels of data. This section also includes filter options to select a specific year and analyse emissions for that period'
    },
    {
        query: 'Can I download this dashboard?',
        ans: 'Yes, the dashboard can be downloaded by clicking the "Export PDF" button located at the top-right corner. After exporting, the file will be saved under the Downloads section in the settings.'
    },
    {
        query: 'Can I use this downloaded dashboard as proof for external meetings?',
        ans: 'Yes, the downloaded dashboard can be used as proof or supporting documentation in external meetings or for other purposes.'
    }
]

export const scope1 = [
    {
        query: 'What is data validation?',
        ans: 'Data validation ensures the accuracy and reliability of data across all three scope categories. It allows you to review the formulas used for calculations and the emission factors applied for each scope.'
    },
    {
        query: 'What does Scope 1 contain?',
        ans: 'Scope 1 contains detailed information for the selected scope category data. You can navigate to each category to view the formulas used for calculations. Additionally, the table below displays details such as emission factors, calculated emission values, and other relevant information.'
    },
    {
        query: 'Can I download tables for each category?',
        ans: 'Yes, you can download the tables for each category. These downloaded tables can be used for further processes or reporting purposes.'
    }
]

export const scope2 = [
    {
        query: 'What does Scope 2 contain?',
        ans: 'Scope 2 contains detailed information for the selected scope category data. You can navigate to each category to view the formulas used for calculations. Additionally, the table below displays details such as emission factors, calculated emission values, and other relevant information.'
    },
    {
        query: 'Can I download tables for each category?',
        ans: 'Yes, you can download the tables for each category. These downloaded tables can be used for further processes or reporting purposes.'
    }
]

export const scope3 = [
    {
        query: 'What does Scope 3 contain?',
        ans: 'Scope 3 contains detailed information for the selected scope category data. You can navigate to each category to view the formulas used for calculations. Additionally, the table below displays details such as emission factors, calculated emission values, and other relevant information.'
    },
    {
        query: 'Can I download tables for each category?',
        ans: 'Yes, you can download the tables for each category. These downloaded tables can be used for further processes or reporting purposes.'
    }
]

export const sustainabilityReporting = [
    {
        query: 'What is sustainability reporting?',
        ans: 'Sustainability reporting is the process through which organizations disclose information about their environmental, social, and governance (ESG) performance. It provides transparency on how a company is managing its non-financial impacts and aligning with sustainability goals.'
    },
    {
        query: 'What are the benefits of sustainability reporting?',
        ans: 'Sustainability reporting helps organizations enhance their reputation, improve stakeholder trust, identify risks and opportunities, increase operational efficiency, and comply with regulatory requirements. It also demonstrates a commitment to sustainable development and can attract sustainability-focused investors.'
    },
    {
        query: 'How does a sustainability report help businesses?',
        ans: 'A sustainability report helps businesses by identifying areas for improvement, reducing costs through better resource management, building stakeholder confidence, and aligning corporate strategy with global sustainability trends. It also serves as a tool to communicate progress toward sustainability goals.'
    },
    {
        query: 'What are the different types of sustainability reports?',
        ans: 'The various types of sustainability reports include standalone sustainability reports, integrated reports (combining financial and non-financial performance), ESG disclosures, and reports based on specific frameworks such as GRI, SASB, or TCFD.'
    },
    {
        query: 'What is ESG?',
        ans: 'ESG stands for Environmental, Social, and Governance. It refers to the three key factors used to measure the sustainability and ethical impact of a business or investment. ESG helps organizations evaluate risks and opportunities that can affect long-term performance.'
    },
    {
        query: 'What is BRSR?',
        ans: 'BRSR, or Business Responsibility and Sustainability Reporting, is a framework introduced by the Securities and Exchange Board of India (SEBI). It mandates Indian companies to disclose their ESG performance and align with sustainability goals through a structured reporting format.'
    },
    {
        query: 'What is GRI?',
        ans: 'GRI, or the Global Reporting Initiative, is a widely used framework for sustainability reporting. It provides standardized guidelines for organizations to report on their environmental, social, and economic impacts.'
    },
    {
        query: 'Which countries can use the GRI report?',
        ans: 'The GRI (Global Reporting Initiative) framework is globally applicable, meaning it can be used by organizations in any country. It is widely recognized and adopted by companies, governments, and non-profits across the world, making it one of the most universal sustainability reporting standards.'
    },
    {
        query: 'What is SASB?',
        ans: 'SASB, or the Sustainability Accounting Standards Board, is an organization that develops industry-specific standards for sustainability reporting. It focuses on material ESG factors that can influence financial performance.'
    },
    {
        query: 'Which countries can use the SASB report?',
        ans: 'SASB (Sustainability Accounting Standards Board) standards are designed for global use but are particularly popular in the United States and Canada due to their alignment with financial reporting frameworks like the U.S. GAAP. However, companies in other countries also adopt SASB standards to address material ESG factors in a financially relevant manner.'
    },
    {
        query: 'What is CDP?',
        ans: 'CDP, or the Carbon Disclosure Project, is a global non-profit organization that runs a disclosure system for companies, cities, and governments to report on their environmental impacts, particularly related to climate change, water security, and deforestation.'
    },
    {
        query: 'Which countries can use the CDP report?',
        ans: 'The CDP (Carbon Disclosure Project) is a global initiative, and organizations in any country can report through CDP. It is widely used by companies and governments across Europe, North America, Asia, and other regions to disclose environmental impacts related to climate change, water security, and deforestation.'
    },
    {
        query: 'What is TCFD?',
        ans: 'TCFD, or the Task Force on Climate-related Financial Disclosures, is a framework that provides recommendations for organizations to disclose climate-related risks and opportunities. It aims to improve transparency and help stakeholders understand the financial implications of climate change'
    },
    {
        query: 'Which countries can use the TCFD report?',
        ans: 'The TCFD (Task Force on Climate-related Financial Disclosures) recommendations are also globally applicable and are supported by governments and organizations in various countries, including the UK, Japan, Canada, Australia, and the European Union. It is particularly relevant for countries where climate-related financial risks are integrated into regulatory frameworks.'
    },
    {
        query: 'What is ESRS?',
        ans: 'ESRS stands for European Sustainability Reporting Standards. These are detailed guidelines developed under the Corporate Sustainability Reporting Directive (CSRD) in the European Union. ESRS helps organizations report on their environmental, social, and governance (ESG) performance in a consistent, transparent, and comparable manner, aligning with EU sustainability goals.'
    },
    {
        query: 'Which countries can use ESRS?',
        ans: 'ESRS is primarily designed for organizations operating in European Union member states, as it is part of the EU’s CSRD framework. However, multinational companies with operations or reporting obligations in the EU are also required to comply with ESRS, making it applicable to businesses outside the EU that operate within its jurisdiction or supply chain.'
    },
    {
        query: 'What is a Materiality Report?',
        ans: 'A Materiality Report identifies and evaluates the key ESG issues that are most relevant to an organization and its stakeholders. It ensures that sustainability reporting focuses on significant topics that impact the organization’s operations, risks, and opportunities, aligning with stakeholder priorities and business objectives.'
    },
    {
        query: 'Which countries can use a Materiality Report?',
        ans: 'A Materiality Report is a globally applicable tool, and any country or organization can use it to assess and prioritize sustainability issues. It is widely adopted in countries with established sustainability reporting frameworks, such as the United States, European Union nations, Canada, Australia, India, Japan, and South Africa. Organizations in countries following global reporting standards like GRI, SASB, TCFD, or CDP also use materiality reports to align with stakeholder expectations and ensure compliance with sustainability regulations.'
    },
]

export const businessInfo = [
    {
        query: 'What information is included in the Business Info section?',
        ans: 'The Business Info section contains essential details about the organization, such as its  location, industry type, and other foundational information required for sustainability reporting.'
    },
    {
        query: 'How do I select the year for which I am entering the data?',
        ans: 'You can select the reporting year from the dropdown list provided. The selected year will automatically appear across all screens within the Business Info section.'
    },
    {
        query: 'Is it mandatory to enter details for both tables in the Products/Services section?',
        ans: 'No, it is not mandatory to enter details for both tables. You can provide the information if you have the relevant data available.'
    },
    {
        query: 'Is it mandatory to enter all details in the Operations section?',
        ans: 'No, it is not mandatory to fill in all details. You can provide the information if you have the relevant data available.'
    },
    {
        query: 'How do I submit data after entering it in both the Products/Services and Operations sections?',
        ans: 'At the bottom of both the Products/Services and Operations screens, there is a "Submit" option. Once you have entered the data, you can click the "Submit" button to complete the process.'
    },
    {
        query: 'Can I edit or modify data after submission?',
        ans: 'Yes, you can return to the respective section and modify or edit the data even after submission.'
    }
]

export const environment = [
    {
        query: 'What does "E" stand for?',
        ans: '"E" stands for the Environmental aspect of sustainability. It focuses on minimizing an organizations environmental footprint by managing resources responsibly, reducing greenhouse gas emissions, conserving energy, and protecting ecosystems. This includes initiatives such as waste management, renewable energy adoption, water conservation, and addressing climate change impacts.'
    },
    {
        query: 'What are the different screens under the Environmental section?',
        ans: 'The Environmental section includes the following screens:',
        list: [
            'Sustainable Safe Practices - Focuses on implementing environmentally sustainable and safe operational practices.',
            'Environmental Protection Efforts - Highlights initiatives and measures taken to preserve and protect the environment.',
            'Responsible Consumer Engagement - Emphasizes engaging consumers in environmentally responsible and sustainable practices.'
        ]
    },
    {
        query: 'How do I select the year for which I am entering the data?',
        ans: 'You can select the reporting year from the dropdown menu. The chosen year will automatically appear on all screens within the Environmental section.'
    },
    {
        query: 'Is it mandatory to enter answers for all the questions in the Sustainable Safe Practices screen?',
        ans: 'No, it is not mandatory to answer all the questions in this section. You can provide the information if you have relevant and accurate data available.'
    },
    {
        query: 'Is it mandatory to enter answers for all the questions in the Environmental Protection Efforts screen?',
        ans: 'No, it is not mandatory to fill in answers for all the questions. You are encouraged to enter the information if the data is available and applicable to your organization.'
    },
    {
        query: 'Is it mandatory to enter answers for all the questions in the Responsible Consumer Engagement screen?',
        ans: 'No, it is not mandatory to complete all the questions. However, if you have the necessary data, you should enter it to ensure comprehensive reporting.'
    },
    {
        query: 'Is it necessary to enter data for the previous financial year in the tables?',
        ans: "Yes, it is necessary to include data for the previous financial year if available. The previous year's headers are auto-populated based on your selected financial year."
    },
    {
        query: 'Can I navigate to the next screen in between?',
        ans: 'Yes, you can navigate to the next screen using the "Next" button. When you do this, all the entered data will be saved automatically.'
    },
    {
        query: 'Can I return to a screen and make changes?',
        ans: 'Yes, you can return to any screen by clicking the "Back" button. This allows you to make changes to the previously entered data.'
    },
    {
        query: 'How is the data saved?',
        ans: 'The data is saved automatically when you click the "Next" button. This ensures that no data is lost while navigating between screens.'
    },
    {
        query: 'Can I edit or modify data after submission?',
        ans: 'Yes, you can edit or modify the submitted data later. Simply go back to the respective section and update the information as needed.'
    }
]

export const social = [
    {
        query: 'What does "S" stand for?',
        ans: '"S" refers to the Social aspect of sustainability. It encompasses the organizations impact on people, communities, and society at large. This includes promoting fair labor practices, employee welfare, health and safety, community development, diversity and inclusion, stakeholder engagement, and respecting human rights. // The Social aspect also emphasizes building strong relationships with employees, customers, suppliers, and communities. It highlights efforts to ensure ethical business operations, equitable development, and transparent communication while fostering a positive societal impact.',
        isAnsObjectPair: true
    },
    {
        query: 'What are the various screens under the Social section?',
        ans: 'The Social section includes the following screens:',
        isAnsObjectPair: false,
        list: [
            'Employees - Focuses on workforce-related data, including demographics and employment practices.',
            'Corporate Social Responsibility - Highlights initiatives that contribute to societal welfare.',
            'Employee Wellbeing - Covers programs and policies aimed at improving employee health and satisfaction.',
            'Stakeholder Interest - Captures efforts to address and engage stakeholder priorities.',
            'Human Rights Advocacy - Focuses on promoting and upholding human rights within operations.',
            'Inclusive and Equitable Development - Emphasizes strategies for fostering diversity and equal opportunities.',
            'Responsible and Transparent Advocacy - Details actions taken for ethical and transparent communication and advocacy.'
        ],
        isListObjectPaired: true
    },
    {
        query: 'How do I select the year for which I am entering the data?',
        ans: 'You can select the desired reporting year from the dropdown menu. The selected year will appear consistently across all screens in the Social section.',
        isAnsObjectPair: false
    },
    {
        query: 'Is it mandatory to enter details in all the screens under the Social section?',
        ans: 'No, it is not mandatory to enter details in all the screens. You can provide information only if you have relevant data available.',
        isAnsObjectPair: false
    },
    {
        query: 'Is it necessary to enter data for the previous financial year in the tables?',
        ans: 'Yes, it is necessary to include data for the previous financial year if available. The previous years headers will be auto-populated based on your selected financial year.',
        isAnsObjectPair: false
    },
    {
        query: 'Can I navigate to the next screen before completing the current one?',
        ans: 'Yes, you can navigate to the next screen by clicking the "Next" button. The entered data will be automatically saved as you proceed.',
        isAnsObjectPair: false
    },
    {
        query: 'Can I return to a screen and make changes later?',
        ans: 'Yes, you can return to any screen and make changes by clicking the "Back" button. This allows you to edit previously entered data.',
        isAnsObjectPair: false
    },
    {
        query: 'How is the data saved?',
        ans: 'The data is saved automatically when you click the "Next" button. This ensures that your progress is not lost.',
        isAnsObjectPair: false
    },
    {
        query: 'Can I edit or modify data after submission?',
        ans: 'Yes, you can edit or modify the data even after submission. Simply go back to the relevant section and update the information as needed.',
        isAnsObjectPair: false
    }
]

export const governance = [
    {
        query: 'What does "G" stand for?',
        ans: '"G" stands for the Governance aspect of sustainability. It focuses on the systems, policies, and practices that ensure an organization operates ethically, transparently, and in compliance with regulations. Governance encompasses areas such as board structure, executive leadership, risk management, anti-corruption measures, and adherence to ethical business practices. point: 2 -> It ensures accountability to stakeholders, promotes transparency in operations, and aligns corporate objectives with sustainable development goals. Governance also involves external evaluations, responsible advocacy, and the creation of robust frameworks to mitigate risks while maintaining long-term organizational resilience.',
        isAnsObjectPair: true
    },
    {
        query: 'What are the various screens under the Governance section?',
        ans: 'The Governance section includes the following screens:',
        isAnsObjectPair: false,
        list: [
            'Transparency Compliance-Tracks adherence to laws, regulations, and disclosure practices.',
            'Policy Processes- Focuses on the development and implementation of internal policies.',
            'Governance Oversight- Highlights the roles and responsibilities of leadership in governance.',
            'Responsible Business Guidelines - Details ethical standards and frameworks followed by the organization.',
            'External Evaluation- Captures assessments or audits conducted by third parties.',
            'Policy Coverage - Ensures policies adequately cover organizational objectives and risks.',
            'Ethical Transparent Accountability- Focuses on promoting transparency and accountability in all operations.'
        ]
    },
    {
        query: 'How do I select the year for which I am entering the data?',
        ans: 'You can select the desired year from the dropdown menu. The chosen year will automatically appear across all screens in the Governance section.',
        isAnsObjectPair: false,
    },
    {
        query: 'Is it mandatory to enter details in all the screens under the Governance section?',
        ans: 'No, it is not mandatory to enter details in all the screens. You can provide information only if relevant data is available.',
        isAnsObjectPair: false,
    },
    {
        query: 'Is it necessary to enter data for the previous financial year in the tables?',
        ans: 'Yes, it is necessary to include previous financial year data if available. The headers for the previous year will be auto-populated based on the selected financial year.',
        isAnsObjectPair: false,
    },
    {
        query: 'Can I navigate to the next screen without completing the current one?',
        ans: 'Yes, you can navigate to the next screen by clicking the "Next" button. The entered data will be automatically saved as you proceed.',
        isAnsObjectPair: false,
    },
    {
        query: 'Can I return to a screen and make changes later?',
        ans: 'Yes, you can return to any screen and make changes by clicking the "Back" button. This allows you to revise previously entered data.',
        isAnsObjectPair: false,
    },
    {
        query: 'How is the data saved?',
        ans: 'The data is saved automatically when you click the "Next" button. This ensures that your progress is securely stored.',
        isAnsObjectPair: false,
    },
    {
        query: 'Can I edit or modify data after submission?',
        ans: 'Yes, you can edit or modify the data even after submission. Simply navigate back to the relevant section and update the required information.',
        isAnsObjectPair: false,
    }
]

export const energy = [
    {
        query: 'What is the GRI Dashboard?',
        ans: 'The GRI Dashboard is a reporting tool that provides visual insights into sustainability metrics based on Global Reporting Initiative (GRI) standards. It helps organizations track and analyse their ESG performance across various indicators, including energy consumption and intensity.'
    },
    {
        query: 'What does the Energy Dashboard represent?',
        ans: 'The Energy Dashboard represents two key GRI indicators:',
        list: [
            'GRI 302 1: Energy consumption within the organization - Reports the total energy consumed by the organization during the reporting period.',
            'GRI 302 3: Energy intensity ratio - Measures energy consumption relative to an operational or financial metric, such as revenue or production output.'
        ],
        isListObjectPaired: true,
        Message: 'It provides detailed data on energy use and efficiency within the organization.'
    },
    {
        query: 'What does the GRI 302-1 Dashboard represent?',
        ans: 'The GRI 302-1 Dashboard focuses on energy consumption within the organization.',
        list: [
            'It includes filter options for selecting the reporting period.',
            "It displays the total energy consumed during the chosen period.",
            'Various filters are available across tables and graphs for better insights.'
        ],
        isListObjectPaired: false
    },
    {
        query: 'What does the GRI 302-3 Dashboard represent?',
        ans: 'The GRI 302-3 Dashboard represents the energy intensity ratio.',
        list: [
            'It contains filter options for selecting the reporting period.',
            'It shows energy intensity calculations based on the respective reporting period.',
            'It also includes filters across all tables and graphs for deeper analysis.'
        ],
        isListObjectPaired: false
    },
    {
        query: 'Can I download this dashboard?',
        ans: 'Yes, the dashboard can be downloaded. By clicking Export PDF at the top-right corner, the file will be saved and can be accessed under the DOWNLOAD section in SETTINGS.'
    },
    {
        query: 'Can I use the downloaded dashboard as proof for external meetings?',
        ans: 'Yes, the downloaded dashboard can serve as proof or supporting documentation for external meetings, audits, or any other purposes requiring evidence of energy data or sustainability reporting.'
    }
]

export const waters = [
    {
        query: 'What does the Water Dashboard represent?',
        ans: 'The Water Dashboard provides insights into water-related metrics as per GRI standards:',
        list: [
            'GRI 303 4: Water Discharge - Tracks the total volume of water discharged by the organization during a reporting period.',
            'GRI 303 5: Water Consumption - Measures the total volume of water consumed by the organization within the reporting period.'
        ],
        isListObjectPaired: true,
        Message: 'It helps track water usage and discharge within the organization.'
    },
    {
        query: 'What does the GRI 303-4 Dashboard represent?',
        ans: 'The GRI 303-4 Dashboard focuses on Water Discharge data:',
        list: [
            'It includes filter options for selecting the reporting period.',
            'It shows water discharged by the organization during the chosen reporting period.',
            'Various filters across tables and graphs enable detailed analysis.'
        ],
        isListObjectPaired: false
    },
    {
        query: 'What does the GRI 303-5 Dashboard represent?',
        ans: 'The GRI 303-5 Dashboard represents Water Consumption data:',
        list: [
            'It provides filter options for selecting the reporting period.',
            'It displays the total water consumed by the organization during the selected period.',
            'It offers multiple filtering options for analysis across tables and graphs.'
        ],
        isListObjectPaired: false
    },
    {
        query: 'Can I download this dashboard?',
        ans: 'Yes, the dashboard can be downloaded. By clicking Export PDF at the top-right corner, the file will be saved and can be accessed under the DOWNLOAD section in SETTINGS.'
    },
    {
        query: 'Can I use the downloaded dashboard as proof for external meetings?',
        ans: 'Yes, the downloaded dashboard can be used as proof or supporting documentation in external meetings, audits, or for any other official purpose.'
    }
]

export const emission = [
    {
        query: 'What does the Emission Dashboard represent?',
        ans: 'The Emission Dashboard provides insights into GHG emissions as per GRI standards:',
        list: [
            'GRI 305 1: Direct (Scope 1) GHG Emissions - Reports emissions from direct activities under the organization’s operational control.',
            'GRI 305 2: Energy Indirect (Scope 2) GHG Emissions - Accounts for GHG emissions from purchased electricity, heat, or steam.',
            'GRI 305 3: Other Indirect (Scope 3) GHG Emissions - Covers GHG emissions from the organization’s value chain activities.',
            'GRI 305 4: GHG Emission Intensity Ratio - Shows GHG emissions in relation to a financial or operational metric, such as revenue or output. '
        ],
        isListObjectPaired: true,
        Message: 'It helps track and analyse an organization’s overall emissions and intensity metrics.'
    },
    {
        query: 'What does the GRI 305-1 Dashboard represent?',
        ans: 'The GRI 305-1 Dashboard focuses on Direct (Scope 1) GHG Emissions:',
        list: [
            'Includes filter options for selecting the reporting period.',
            'Displays emissions produced directly by the organization during the chosen period.',
            'Offers various filters across tables and graphs for detailed analysis.'
        ],
        isListObjectPaired: false
    },
    {
        query: 'What does the GRI 305-2 Dashboard represent?',
        ans: 'The GRI 305-2 Dashboard focuses on Energy Indirect (Scope 2) GHG Emissions:',
        list: [
            'Provides filter options to select the reporting period.',
            'Shows emissions from purchased energy during the respective period.',
            'Offers filtering options across tables and graphs for better insights.'
        ],
        isListObjectPaired: false
    },
    {
        query: 'What does the GRI 305-3 Dashboard represent?',
        ans: 'The GRI 305-3 Dashboard highlights Other Indirect (Scope 3) GHG Emissions:',
        list: [
            'Includes filter options for selecting the reporting period.',
            'Displays emissions from indirect activities like business travel or supply chain operations.',
            'Provides multiple filters for analysis across tables and graphs.'
        ],
        isListObjectPaired: false
    },
    {
        query: 'What does the GRI 305-4 Dashboard represent?',
        ans: 'The GRI 305-4 Dashboard focuses on the GHG Emission Intensity Ratio:',
        list: [
            'Offers filter options for the reporting period.',
            'Shows revenue and revenue intensity in relation to GHG emissions during the respective period.',
            'Provides various filters for comprehensive analysis across graphs and tables.'
        ],
        isListObjectPaired: false
    },
    {
        query: 'Can I download this dashboard?',
        ans: 'Yes, the dashboard can be downloaded. By clicking Export PDF at the top-right corner, the file will be saved and can be accessed under the DOWNLOAD section in SETTINGS.'
    },
    {
        query: 'Can I use the downloaded dashboard as proof for external meetings?',
        ans: 'Yes, the downloaded dashboard can be used as proof or supporting documentation for external meetings, audits, or any other official purpose.'
    }
]

export const effluentsAndWaste = [
    {
        query: 'What does the Effluents and Waste Dashboard represent?',
        ans: 'The Effluents and Waste Dashboard provides insights into waste management based on GRI standards:',
        list: [
            'GRI 306 3: Waste Generated - Captures the total quantity of waste produced during the reporting period.',
            'GRI 306 4: Waste Diverted from Disposal - Represents waste recovered or repurposed instead of being disposed of.',
            'GRI 306 5: Waste Directed to Disposal - Highlights the total waste sent for disposal during the reporting period.'
        ],
        isListObjectPaired: true,
        Message: 'It helps organizations track and analyse their waste generation, recovery, and disposal practices.'
    },
    {
        query: 'What does the GRI 306-3 Dashboard represent?',
        ans: 'The GRI 306-3 Dashboard focuses on Waste Generated:',
        list: [
            'Includes filter options for selecting the reporting period.',
            'Displays the total waste generated by the organization during the chosen period.',
            'Provides multiple filters for detailed analysis across tables and graphs.'
        ],
        isListObjectPaired:false
    },
    {
        query: 'What does the GRI 306-4 Dashboard represent?',
        ans: 'The GRI 306-4 Dashboard highlights Waste Diverted from Disposal:',
        list: [
            'Offers filter options for selecting the reporting period.',
            'Shows the waste recovered or diverted from disposal during the selected period.',
            'Includes various filters for detailed analysis across tables and graphs.'
        ],
        isListObjectPaired:false
    },
    {
        query: 'What does the GRI 306-5 Dashboard represent?',
        ans: 'The GRI 306-5 Dashboard focuses on Waste Directed to Disposal:',
        list: [
            'Contains filter options for selecting the reporting period.',
            'Displays the waste sent for disposal during the respective period for the organization.',
            'Provides filters for comprehensive analysis across graphs and tables.'
        ],
        isListObjectPaired: false
    },
    {
        query:'Can I download this dashboard?',
        ans: 'Yes, the dashboard is available for download. By clicking Export PDF at the top-right corner, the file will be saved and can be accessed under the DOWNLOAD section in SETTINGS.'
    },
    {
        query: 'Can I use the downloaded dashboard as proof for external meetings?',
        ans: 'Yes, the downloaded dashboard can serve as proof or supporting documentation for external meetings, audits, or other official purposes.'
    }
]

export const reporting = [
    {
        query: 'What does the Reporting screen consist of?',
        ans: 'The Reporting screen allows users to download reports based on the selected report type. It provides options to generate, view, file, and download reports.'
    },
    {
        query: 'What does the Report Type represent?',
        ans: 'Report Type provides a dropdown menu to select the type of report, such as Materiality Report, BRSR, SASB, GRI, ESRS, CDP, or TCFD, for generation and download after entering data in the ESG section.'
    },
    {
        query: 'What does Financial Year denote?',
        ans: 'The Financial Year dropdown lets users select the year for which the ESG data has been entered.'
    },
    {
        query: 'How can I generate a report?',
        ans: 'To generate a report, select the desired Report Type and Financial Year from the dropdown menus, then click the "Generate" option. The report will be created and made available for download.'
    },
    {
        query: 'What is the next step after generating a report?',
        ans: 'After generating a report, you can view its status on the Reports page. Once the status changes from "In Progress" to "Completed," you can download the report from the Downloads column.'
    },
    {
        query: 'Can I edit after downloading the file?',
        ans: 'Yes, you can edit the E, S, G, and Business Info sections before filing the report. After making changes on the platform, you can download the updated report again.'
    },
    {
        query: 'What is the History column?',
        ans: 'The History column maintains a record of all previously downloaded reports.'
    },
    {
        query: 'What is Filing?',
        ans: 'Filing finalizes the report. Once filed, no changes can be made to the Business Info, E, S, or G sections, and editing is disabled on the platform.'
    },

]

export const downloads = [
    {
        query: 'What is the purpose of the Downloads screen?',
        ans: 'The Downloads screen is where all the documents downloaded from the Analytics and Dashboard sections under Sustainability Reporting are stored.'
    },
    {
        query: 'What information does the Downloads screen contain?',
        ans: 'The Downloads screen displays a list of documents that were downloaded from the Analytics and Dashboard sections within Sustainability Reporting.'
    },
    {
        query: 'Can I download these files to my local system?',
        ans: 'Yes, the Downloads section provides an option to download the files directly to your local system.'
    },
    {
        query: 'What does the Expiration Time column indicate?',
        ans: 'The Expiration Time column specifies when a file will be automatically deleted from the Downloads screen. Once the expiration time is reached, the file will no longer be available.'
    }
]

export const applications = [
    {
        query: 'What does the Application screen contain?',
        ans: 'The Application screen consists of three main sections:',
        list: [
            'Landing Page',
            'Financial Year',
            'Report Settings'
        ]
    },
    {
        query: 'What is the purpose of the Landing Page?',
        ans: 'The Landing Page serves as a favourites page where you can select the default screen the platform should navigate to when you log in.'
    },
    {
        query: 'What are Select Module and Select Landing Page options?',
        ans: 'These options allow you to choose the module and specific page you want as your default. Once submitted, the selected screen will load by default the next time you log in.'
    },
    {
        query: 'What does the Financial Year page handle?',
        ans: 'The Financial Year page allows you to define the start and end months of your financial year. Once selected and submitted, this configuration will reflect on the data entry screen.'
    },
    {
        query: 'What does the Report Settings page contain?',
        ans: 'The Report Settings page provides options to customize the font style and size from a dropdown menu. Additionally, you can input the title color and text color. Based on these settings, your sustainability report will be generated with the specified design and made available for download.'
    }
]

export const carbonCredits = [
    {
        query: 'What is a carbon credit?',
        ans: 'A carbon credit is a permit that allows a company or organization to emit a certain amount of carbon dioxide or other greenhouse gases. One carbon credit equals one metric ton of CO2 or the equivalent amount of a different greenhouse gas.'
    },
    {
        query: 'What is the Green Carbon Wallet?',
        ans: 'The Green Carbon Wallet is a digital platform provided by AIROI where users can manage their carbon credits. It allows users to buy and sell  carbon credits, providing a secure and transparent way to track their carbon offsetting activities.'
    },
    {
        query: 'What types of carbon offset projects does our platform support?',
        ans: 'Our Platform supports a variety of verified carbon offset projects, including reforestation, renewable energy, energy efficiency, sustainable agriculture, Plastic waste and so on. Each project is vetted to ensure it meets rigorous environmental standards.'
    },
    {
        query: 'How are the carbon credits verified?',
        ans: 'All carbon credits available on the Green Carbon Wallet platform are verified by national and international recognized standards such as Verified Carbon Standard (VCS) , Gold Standard, Plastic Credit Exchange (PCX), Universal Carbon Registry (UCR) and so on. This ensures that the emission reductions are real, measurable, and permanent.'
    },
    {
        query: 'Can I trade carbon credits on the Green Carbon Wallet platform?',
        ans: 'Yes, the platform includes a marketplace where users can buy and sell carbon credits. This enables flexibility in managing carbon portfolios and supports a dynamic market for carbon offsets.'
    }
]

export const projects = [
    {
        query: 'How do I create a new project?',
        ans: "This process initiates a new project. To create a new project, fill in all mandatory fields required in the form. It's crucial to provide accurate details as these will be displayed on the project list section of the homepage. Once all details are entered, click submit."
    },
    {
        query: 'What can I see on the project list page?',
        ans: 'You can view the list of projects that your organization has created. This page displays columns such as project name, status, UN SDGs, project type, country, and actions.'
    },
    {
        query: 'What does the status column indicate?',
        ans: 'The status column shows the current state of your created projects. Hovering over the icon reveals whether a project is active, submitted, or in another state.'
    },
    {
        query: 'What does "project type" mean?',
        ans: 'Project type refers to the category under which the project was created. The same type name is displayed on this page. '
    },
    {
        query: 'What do UN SDGs mean?',
        ans: 'UN SDGs stand for United Nations Sustainable Development Goals. These are a set of 17 global goals adopted by all UN Member States in 2015 as part of the 2030 Agenda for Sustainable Development. The SDGs are designed to address various global challenges, including poverty, inequality, climate change, environmental degradation, peace, and justice. Each goal has specific targets to be achieved by 2030, aiming to create a more sustainable and equitable world for all. Below are the 17 goals along with their descriptions: ',
        list: [
            'No Poverty - End poverty in all its forms everywhere. ',
            'Zero Hunger - End hunger, achieve food security and improved nutrition, and promote sustainable agriculture. ',
            'Good Health and Well-being - Ensure healthy lives and promote well-being for all at all ages. ',
            'Quality Education - Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.',
            'Gender Equality - Achieve gender equality and empower all women and girls.',
            'Clean Water and Sanitation - Ensure availability and sustainable management of water and sanitation for all. ',
            'Affordable and Clean Energy - Ensure access to affordable, reliable, sustainable, and modern energy for all.',
            'Decent Work and Economic Growth - Promote sustained, inclusive, and sustainable economic growth, full and productive employment, and decent work for all. ',
            'Industry, Innovation, and Infrastructure - Build resilient infrastructure, promote inclusive and sustainable industrialization, and foster innovation. ',
            'Reduced Inequalities - Reduce inequality within and among countries',
            'Sustainable Cities and Communities - Make cities and human settlements inclusive, safe, resilient, and sustainable. ',
            'Responsible Consumption and Production - Ensure sustainable consumption and production patterns.',
            'Climate Action - Take urgent action to combat climate change and its impacts. ',
            'Life Below Water - Conserve and sustainably use the oceans, seas, and marine resources for sustainable development.',
            'Life On Land - Protect, restore, and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, halt and reverse land degradation, and halt biodiversity loss.',
            'Peace, Justice, and Strong Institutions - Promote peaceful and inclusive societies for sustainable development, provide access to justice for all, and build effective, accountable, and inclusive institutions at all levels. ',
            'Partnerships for the Goals - Strengthen the means of implementation and revitalize the Global Partnership for Sustainable Development. ',
        ]
    },
    {
        query: 'What does the "actions" column signify? ',
        ans: 'The actions column allows you to view the project status and edit the details of the project you created. By clicking the eye icon, you can view the profile, details, status, notes, documents, and baseline calculations specific to that project type. Clicking the pen icon allows you to edit the project details. Once the project reaches active status, you can no longer edit the project details. If the project is still in draft mode, you can delete the project before submitting.'
    }
];

export const existingProject = [
    {
        query: 'What is the purpose of the existing project feature?',
        ans: 'This feature is used to onboard existing projects onto our platform.'
    },
    {
        query: 'What details need to be filled out?',
        ans: "It's necessary to complete all mandatory fields in the form. After all sections are filled out, the user can click submit."
    }
]

export const wallet = [
    {
        query: 'What does this page tell me about?',
        ans: 'This page provides an overview of the project name, pending credit value, certified credit value, and pending sale settlement value. It helps you gain a detailed understanding of the credits associated with your projects. If you click the trading button, it will take you to the trading section where you can explore this area in depth.'
    }
];

export const trading = [
    {
        query: 'How can I trade with my credits?',
        ans: 'You can initiate trading by clicking the trading button, which opens a new window displaying the monetary value of your credits. If you wish to sell , you can proceed by clicking the appropriate button and following the subsequent steps. Fill in all the details and click submit.'
    },
    {
        query: 'What does the Orders section deal with?',
        ans: 'This section provides a view of the projects you are planning to sell. It includes order ID, order date, project name, quantity, monetary value, status, and an option to view more details.'
    },
    {
        query: 'How can I view the project status?',
        ans: 'Click on the eye icon to be taken to the next page where you can see basic details and view the transaction. Only the system admin has the authority to sell orders. If the status is "Filled," it means that your order is sold. If the status is "Open," it means that the order is processing. If the status is "New," it means that you are ready to sell the credits.'
    }
];

export const general = [
    {
        query: 'What is the Green Carbon Wallet and What Do We Provide to You?',
        ans: 'At AIROI, we have developed a ‘Green-Carbon’ Wallet, which serves as both a marketplace for buyers to purchase carbon or plastic credits to offset their emission levels, and a platform for sellers to trade their carbon or plastic credits. This Green-Carbon Wallet helps you measure your carbon footprint, calculate the number of credits or offsets you have, and connect with potential buyers to sell or offset your credits.'
    },
    {
        query: 'What can I do with the carbon credits section in this platform?',
        ans: 'In the carbon credits section, you can: ',
        list: [
            'View Project Details - Access detailed information about your carbon credit projects.',
            'Track UN SDG Goals - Identify and monitor the UN Sustainable Development Goals applicable to your projects.',
            'Monitor Project Status - Follow a step-by-step status update for each project to stay informed of their progress.',
            'Manage Carbon Credits - Check the number of carbon credits you own and their current status.'
        ],
        isListObjectPaired: true
    },
    {
        query: 'What are the features of our platform?',
        ans: 'Our platform offers a comprehensive suite of features designed to help you manage and reduce your carbon footprint: ',
        list: [
            'GHG Emission Tracking - Monitor and visualize your greenhouse gas emissions through interactive dashboards.',
            'Emission Reduction Planning - Set and manage your action plans to achieve net zero goals.',
            'Carbon Credit Management - View, track, and manage your carbon credits, including project details and status updates.',
            'Scope Applicability - Customize your data entry by selecting relevant categories to streamline your workflow.',
            ' Air Quality Index (AQI) Monitoring - Access real-time air quality data to ensure compliance with standards and identify improvement areas.',
            'UN SDG Integration - Align your projects with relevant UN Sustainable Development Goals and track their progress.'
        ],
        isListObjectPaired: true
    },
    {
        query: ' What are the benefits of the Net0Trace?',
        ans: 'The Net0Trace section of our platform provides several key benefits:',
        list: [
            'Comprehensive Tracking- Monitor your greenhouse gas emissions across various scopes and categories.',
            'Visualization Tools- Use interactive dashboards to visualize your emission data and trends.',
            'Reduction Planning- Set action plans for emission reduction and track your progress towards net zero goals.',
            'Scope Customization- Select applicable categories to streamline data entry and focus on relevant information.',
            'Set Group and Location- Organize and manage your emissions data by different groups and locations, allowing for more precise tracking and analysis.',
            'Comparing Your Actual vs Target Data- Easily compare your current emissions data against your target goals to identify areas for improvement and measure your progress towards achieving those goals.'
        ],
        isListObjectPaired: true
    },
    {
        query: 'What is Net0Trace?',
        ans: 'Net0Trace is a system or platform designed to help organizations measure, track, and manage their greenhouse gas (GHG) emissions to achieve net-zero emissions. It enables transparency in emissions reporting and supports sustainability goals by providing insights and analytics for effective carbon reduction strategies.',

    },
    {
        query: 'Why is Net0Trace important?',
        ans: 'Net0Trace is important because it helps organizations understand their carbon footprint, set reduction targets, and comply with environmental regulations. It ensures accountability and enables organizations to make informed decisions to reduce emissions. By tracking progress toward net-zero goals, it contributes to mitigating climate change and enhances a company’s reputation in sustainability.',
    },
    {
        query: 'How is Net0Trace measured?',
        ans: 'Net0Trace measures emissions by collecting data from various sources within an organization, such as energy consumption, transportation, and waste management. The data is then converted into carbon dioxide equivalent (CO₂e) emissions using internationally recognized standards and emission factors. The platform provides detailed reports and visual dashboards to analyse emission trends.',
    },
    {
        query: 'How can I reduce my emissions using Net0Trace?',
        ans: 'You can reduce your emissions using Net0Trace by identifying key sources of emissions within your operations, setting realistic reduction targets, and implementing strategies such as:',
        list: [
            'Transitioning to renewable energy sources.',
            'Improving energy efficiency in buildings and equipment.',
            'Encouraging sustainable transportation options like carpooling or EVs.',
            'Reducing waste and increasing recycling.',
            'Collaborating with suppliers to lower upstream emissions.'
        ],
        isListObjectPaired: false
    },
    {
        query: 'What is the benefit of using the AQI section in our platform?',
        ans: 'The AQI section provides real-time air quality data, helping you monitor and manage air quality levels. This feature can be particularly useful for identifying areas where improvements are needed and for ensuring compliance with air quality standards. It helps you take proactive measures to improve air quality and ensure a healthier environment. '
    },
    {
        query: 'What is the benefit of using the decarbonization analysis section in our platform?',
        ans: 'The decarbonization analysis section allows you to conduct in-depth analyses to identify opportunities for reducing carbon emissions. It provides insights and recommendations for implementing effective decarbonization strategies. This helps you to not only meet regulatory requirements but also to enhance your sustainability initiatives and move closer to achieving your net zero goals.'
    }
]


const objtemp = {
    query: '',
    ans: ''
}