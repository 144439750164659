import moment from "moment";
import { currencySymbol } from "./currencyData";
import { forwardRef, useState } from "react";
import { FaChargingStation, FaSolarPanel } from 'react-icons/fa'
import { GiPaperBagFolded, GiPlantsAndAnimals, GiStreetLight } from 'react-icons/gi'
import { BsEvStation, BsBuildingFillGear } from 'react-icons/bs'
import { Link } from "react-router-dom";
import { components } from "react-select";
import goal1 from '../../assets/images/sdg-goals/1.png';
import goal2 from '../../assets/images/sdg-goals/2.png';
import goal3 from '../../assets/images/sdg-goals/3.png';
import goal4 from '../../assets/images/sdg-goals/4.png';
import goal5 from '../../assets/images/sdg-goals/5.png';
import goal6 from '../../assets/images/sdg-goals/6.png';
import goal7 from '../../assets/images/sdg-goals/7.png';
import goal8 from '../../assets/images/sdg-goals/8.png';
import goal9 from '../../assets/images/sdg-goals/9.png';
import goal10 from '../../assets/images/sdg-goals/10.png';
import goal11 from '../../assets/images/sdg-goals/11.png';
import goal12 from '../../assets/images/sdg-goals/12.png';
import goal13 from '../../assets/images/sdg-goals/13.png';
import goal14 from '../../assets/images/sdg-goals/14.png';
import goal15 from '../../assets/images/sdg-goals/15.png';
import goal16 from '../../assets/images/sdg-goals/16.png';
import goal17 from '../../assets/images/sdg-goals/17.png';
import draft from '../../assets/images/status-icons/draft.svg';
import submitted from '../../assets/images/status-icons/submit.svg';
import validated from '../../assets/images/status-icons/validated.svg';
import rejected from '../../assets/images/status-icons/rejected.svg';
import unallocated from '../../assets/images/status-icons/inassessment_unallocated.svg';
import allocated from '../../assets/images/status-icons/inassessment_allocated.svg';
import accepted from '../../assets/images/status-icons/accepted.svg';
import returned from '../../assets/images/status-icons/returned.svg';
import submittedRegistry from '../../assets/images/status-icons/Submitted-to-registry.svg';
import approvedRegistry from '../../assets/images/status-icons/approved-by-registry.svg';
import suspended from '../../assets/images/status-icons/SUSPENDED.svg';
import retired from '../../assets/images/status-icons/RETIRED.svg';
import active from '../../assets/images/status-icons/Active.svg';
import EvChargeCalculation from "../Component/BaselineCalculation/EvChargeCalculation";
import { existingProject, groups, locations,dataCapture, projects, trading, wallet, Net0TracePlatform, approvalQueue, submitQueue, progress, net0Planner, actionPlan, executiveDashboard, targetAndActual, emissionInsights, scope1, scope2, scope3, businessInfo, environmental, environMental, environment, social, governance, energy, waters, emission, effluentsAndWaste, reporting, scopePreference, users, downloads, applications, subsidiaries} from "../Layout/FaqPopup/faqContent";
import { Plus } from "react-feather";

export const handleProjectData = (formData, status) => {
    console.log(formData)
    const data = {
        // ...formData,
        "name": formData.projectName,
        "description": formData.description,
        "type": formData.type,
        "status": status,
        "start_date": formData?.startDate ? moment(formData.startDate).format('YYYY-MM-DD') : '',
        "end_date": formData?.endDate ? moment(formData.endDate).format('YYYY-MM-DD') : '',
        "asset_owner_id": null,
        "methodology": formData.methodology,
        "country": formData.projectCountry,
        "region": formData.region,
        "latitude": null,
        "longitude": null,
        "area": "",
        "size": formData.projectsize,
        "average_annual_volume_of_vc_us": Number(formData.avgannualvcu),
        "crediting_period_in_years": "",
        "website": null,
        "ccbstandardname": formData?.ccbstdname,
        "ccbstandard": formData?.ccbstd,
        "isassociatedwithjnr": formData?.isassociatewithjnr,
        "sdVistaProjectType": formData?.sdvistaprojtype,
        "susdevgoalcontribution": formData?.susdevgoalcontri,
        "assessmenttype": formData.assesmentType,
        "periodicassessmenttype": formData.periodicAssesmentType,
        "volume": formData.volume != undefined ? formData.volume : '',
        "frequency": formData.frequency != undefined ? formData.frequency : '',
        "registry": formData.registry,
        "unitType": formData.unitType,
        "creditsIssuance": formData?.creditsIssuance,
        "projectorigin": formData.projectorigin,
        "projectCategoryId": formData?.projectCategoryId,
        "splitValue": formData?.splitValue,
        "registryId": formData?.registryId,
        "projectSubCategoryId": formData?.projectSubCategoryId,
        "subCategoryItemId": formData?.subCategoryItemId,
        "surface_area": formData?.surface_area,
        "carbon_baseline": formData?.carbon_baseline,
        "estimation_carbon_capacity": formData?.estimation_carbon_capacity,
        "total_gross_sequestration_potential": formData?.total_gross_sequestration_potential,
        "operational_impacts": formData?.operational_impacts,
        "project_duration": formData?.project_duration,
        "potential_per_ha_year": formData?.potential_per_ha_year,
        "potential_whole_project_year": formData?.potential_whole_project_year,
        "total_sequestration_potential": formData?.total_sequestration_potential,
        "estimated_carbon_income": formData?.estimated_carbon_income,
        "facility_field_name": formData?.facility_field_name,
        "geo_location": formData?.geo_location,
        "legal_ownership": formData?.legal_ownership,
        "remove_store_carbon": formData?.remove_store_carbon,
        "selectedAsoOrgId": formData?.selectedAsoOrgId,
        "baseline": formData?.baseline,
        "dataCollectionFrequency": formData?.dataCollectionFrequency,
        "emissionFactor": formData?.emissionFactor,
        "monitoringLevel": formData?.monitoringLevel,
        "carbonCreditsCalculationDay": formData?.carbonCreditsCalculationDay,
        "city": formData?.city,
        "zipCode": formData?.zipCode,
        "sustainableDevelopmentGoals": formData?.sustainableDevelopmentGoals,
        "countryCode": formData?.countryCode
    }
    return data;
}


export const handleDraftData = (formData) => {
    const data = {
        "name": formData.projectName,
        "description": formData.description,
        "status": "DRAFT",
        "start_date": moment(formData.startDate).format('YYYY-MM-DD'),
        "end_date": moment(formData.endDate).format('YYYY-MM-DD'),
        "country": formData.projectCountry,
        "region": formData.region,
        "latitude": null,
        "longitude": null,
        "area": 1000,
        "crediting_period_in_years": 5,
        "website": "",
        "volume": formData.volume,
        "periodicassessmenttype": formData.periodicAssesmentType,
        "frequency": formData.frequency,
        "assessmenttype": formData.assesmentTypeValue,
        "projectorigin": formData.projectorigin,
        "projectCategoryId": formData?.projectCategoryId,
        "splitValue": formData?.splitValue,
        "registryId": formData?.registryId,
        "projectSubCategoryId": formData?.projectSubCategoryId,
        "subCategoryItemId": formData?.subCategoryItemId,
        "unitType": formData.unitType,
        "selectedAsoOrgId": formData?.selectedAsoOrgId
    }
    return data;
}

export const defaultStatus = {
    DRAFT: "Draft",
    SUBMITTED: "Submitted",
    VALIDATED: "Validated",
    REJECTED: "Rejected",
    INASSESSMENT_UNALLOCATED: "In Assessment - WA",
    INASSESSMENT_ALLOCATED: "In Assessment",
    RETURNED: "Returned",
    ACCEPTED: "Accepted",
    SUBMITTED_TO_REGISTRY: "Submitted to Registry",
    APPROVED_BY_REGISTRY: "Approved by Registry",
    ACTIVE: "Active",
    SUSPENDED: "Inactive",
    RETIRED: "Retired"
}

export const defaultStatusColorCodes = {
    DRAFT: '#FD5F72',
    SUBMITTED: '#d81689',
    REJECTED: '#FF0000',
    VALIDATED: '#4b0082',
    INASSESSMENT_UNALLOCATED: '#4169E1',
    INASSESSMENT_ALLOCATED: '#4169E1',
    RETURNED: '#B3533A',
    ACCEPTED: '#b300b3',
    SUBMITTED_TO_REGISTRY: '#003868',
    APPROVED_BY_REGISTRY: '#008080',
    ACTIVE: '#2D9E64',
    SUSPENDED: '#009EFB',
    RETIRED: '#306975',
}

export function capitalize(string) {
    if (string == null) {
        return string;
    }
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export const defaultFrequency = {
    "1": "MONTHLY",
    "3": "QUARTERLY",
    "6": "HALFYEARLY",
    "12": "YEARLY"
}


export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const currencyFormatter = (value, currency) => {
    if (!value) {
        if (value === 0) {
            return currencySymbol[currency] + ' ' + '0.00'
        } else
            return value
    }
    // value=value.toString();
    // let lastThree = value.substring(value.length-3);
    // let otherNumbers = value.substring(0,value.length-3);
    // if(otherNumbers != '')
    //     lastThree = ',' + lastThree;
    // value = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

    return currencySymbol[currency] + ' ' + Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}


export const logoutAction = () => {
    sessionStorage.removeItem('profileURL');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('auth_profile');
    sessionStorage.removeItem('Name');
    sessionStorage.setItem('authenticated', false);
    sessionStorage.setItem('login', false);
    sessionStorage.clear();
}

export const defaultRouter = {
    ASR_USER: '/assessor/projects',
    ASM_ADMIN: '/asm-admin/projects',
    ASM_USER: '/asm-user/projects',
    ASR_ADMIN: '/asr-admin/projects',
    ASO_USER: '/aso-user/projects',
    ASO_ADMIN: '/aso-admin/projects',
    SYS_ADMIN: '/admin/projects',
    SYS_USER: '/back-office/projects',
    ANALYST: '/analyst/data-capture',
    APPROVER: '/approver/data-capture'
}

export const defaultUserType = {
    ASR_USER: 'assessor',
    ASM_ADMIN: 'asm-admin',
    ASM_USER: 'asm-user',
    ASR_ADMIN: 'asr-admin',
    ASO_USER: 'aso-user',
    ASO_ADMIN: 'aso-admin',
    SYS_ADMIN: 'admin',
    SYS_USER: 'back-office',
}

export const defaultOrderStatusColorCodes = {
    NEW: "#41a1d5e8",
    OPEN: "#82d4de",
    LOCKED: "#54b4eb",
    FILLED: "#bbd2e6", //yellow
    SETTLED: "#a8e3aa", //green
    EXPIRED: "#f5ba6e", //orange
    RETIRED: "#efa0b5", //pink
    CANCELLED: "#e99191" //red
}

export const defaultOrderFontCode = {
    NEW: "#003868",
    OPEN: "#074a53",
    LOCKED: "#032f48",
    FILLED: "#074276", //yellow
    SETTLED: "#065e09", //green
    EXPIRED: "#89540f", //orange
    RETIRED: "#a30b33", //pink
    CANCELLED: "#850909" //red
}

export const handleProjectDates = (formData) => {
    let data = { ...formData };
    if (formData?.start_date && formData?.end_date) {
        data.start_date = moment(formData.start_date).format('YYYY-MM-DD');
        data.end_date = moment(formData.end_date).format('YYYY-MM-DD');
    } else {
        delete data?.start_date;
        delete data?.end_date;
    }
    return data;
}

export const handleDate = (date) => {
    if (date && typeof date === 'string') {
        var d = date + ' ' + 'UTC'
        return new Date(date).toString()
    } else if (!date) {
        return ''
    }
}

export const isValidUrl = (string) => {
    var res = string.match(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/gm);
    return (res !== null)
}

export const isValidNumber = (number) => {
    var res = number.match(/^[0-9\b]+$/)
    return (res !== null)
}

export const defaultQuarter = {
    1: 'Jan - Mar',
    2: 'Apr - Jun',
    3: 'Jul - Sep',
    4: 'Oct - Dec'
}

export const defaultMonitoringURL = {
    'CHARGER': 'ev-charger-lists',
    'STATION': 'ev-station-lists'
}

export const defaultConsumptionUrl = {
    'CHARGER': 'ev-charging-consumption',
    'STATION': 'ev-station-consumption'
}

export const defaultOrgControlType = {
    "EQUITY": 'Equity',
    "OPERATIONAL": 'Operationa',
    "FINANCIAL": 'Financial'
}

export const defaultOrgType = {
    'ASSET_OWNER': 'Asset Owner',
    'ASSESSOR': 'Assessor',
    'ASSET_MANAGER': 'Asset Manager'
}

export const CustomInput = forwardRef((props, ref) => {
    return (
        <div className='form-control' onClick={props.onClick}>
            <label className="mb-0 f-s-12" ref={ref} >
                {props.value || props.placeholder}
            </label>
            <i className="fa fa-calendar input-calendar-ico" style={{ marginTop: '0.1rem' }}></i>
        </div>
    );
});


export const defaultMonitoringColumnName = {
    'plastic': 'Waste Collection',
    'bms': 'Energy Consumption',
    'solar': 'Energy Generated (MW)',
    'lighting': 'Energy Consumption'
}

export const PlasticRenderer = (params) => {
    return (
        <Link to={`${process.env.PUBLIC_URL}/aso-admin/monitoring/${params.data.id}/${params.data.name}/plastic/${params.data.dataCollectionFrequency}`} className={`${params.data.status === 'ACTIVE' ? '' : 'disable-ev-icon'} `}>
            <i class={`fa-solid fa-bottle-water ${params.data.status === 'ACTIVE' ? 'ev-icon' : 'disable-ev-icon'}`}></i>
            {/* <GiPaperBagFolded className={`${params.data.status === 'ACTIVE' ? 'ev-icon' : 'disable-ev-icon' } `} /> */}
        </Link>
    )
}

export const ChargerRenderer = (params) => {
    return (
        <Link to={`${process.env.PUBLIC_URL}/aso-admin/${defaultConsumptionUrl[params.data.monitoringLevel]}/${params.data.id}/${params.data.name}`} className={`${params.data.status === 'ACTIVE' ? '' : 'disable-ev-icon'} `}>
            <BsEvStation className={`${params.data.status === 'ACTIVE' ? 'ev-icon' : 'disable-ev-icon'} `} />
        </Link>
    )
}

export const SolarRenderer = (params) => {
    return (
        <Link to={`${process.env.PUBLIC_URL}/aso-admin/monitoring/${params.data.id}/${params.data.name}/solar/${params.data.dataCollectionFrequency}`} className={`${params.data.status === 'ACTIVE' ? '' : 'disable-ev-icon'} `}>
            <FaSolarPanel className={`${params.data.status === 'ACTIVE' ? 'ev-icon' : 'disable-ev-icon'} `} />
        </Link>
    )
}

export const BmsRenderer = (params) => {
    return (
        <Link to={`${process.env.PUBLIC_URL}/aso-admin/monitoring/${params.data.id}/${params.data.name}/solar/${params.data.dataCollectionFrequency}`} className={`${params.data.status === 'ACTIVE' ? '' : 'disable-ev-icon'} `}>
            <BsBuildingFillGear className={`${params.data.status === 'ACTIVE' ? 'ev-icon' : 'disable-ev-icon'} `} />
        </Link>
    )
}

export const AgriRenderer = (params) => {
    return (
        <Link className={`${params.data.status === 'ACTIVE' ? 'pointer-none' : 'disable-ev-icon'} `}>
            <GiPlantsAndAnimals className={`${params.data.status === 'ACTIVE' ? 'ev-icon' : 'disable-ev-icon'} `} />
        </Link>
    )
}

export const LightRenderer = (params) => {
    return (
        <Link
            // to={`${process.env.PUBLIC_URL}/aso-admin/monitoring/${params.data.id}/${params.data.name}/lighting/${params.data.dataCollectionFrequency}`} 
            className={`${params.data.status === 'ACTIVE' ? 'pointer-none' : 'disable-ev-icon'} `}>
            <GiStreetLight className={`${params.data.status === 'ACTIVE' ? 'ev-icon' : 'disable-ev-icon'} `} />
        </Link>
    )
}

export const thousandSeparator = (x) => {
    if (x?.toString()?.includes('.')) {
        let firstInteger = x?.toString()?.split('.')[0];
        return `${firstInteger.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${x?.toString()?.split('.')[1]}`;
    } else {
        return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

export const CustomOptionComponent = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input className="checkbox-style" type="checkbox" checked={props.isSelected} />
                <label className="ml-1">{props.label}</label>
            </components.Option>
        </div>
    )
}

export const multiOptions = [
    { value: 1, label: 'No Poverty' },
    { value: 2, label: 'Zero Hunger' },
    { value: 3, label: 'Good Health and Well-being' },
    { value: 4, label: 'Quality Education' },
    { value: 5, label: 'Gender Equality' },
    { value: 6, label: 'Clean Water and Sanitation' },
    { value: 7, label: 'Affordable and Clean Energy' },
    { value: 8, label: 'Decent Work and Economic Growth' },
    { value: 9, label: 'Industry, Innovation and Infrastructure' },
    { value: 10, label: 'Reduced Inequalities' },
    { value: 11, label: 'Sustainable Cities and Communities' },
    { value: 12, label: 'Responsible Consumption and Production' },
    { value: 13, label: 'Climate Action' },
    { value: 14, label: 'Life Below Water' },
    { value: 15, label: 'Life On Land' },
    { value: 16, label: 'Peace, Justice and Strong Institutions' },
    { value: 17, label: 'Partnerships for the Goals' },
]

export const goalDescriptions = {
    1: 'No Poverty',
    2: 'Zero Hunger',
    3: 'Good Health and Well-being',
    4: 'Quality Education',
    5: 'Gender Equality',
    6: 'Clean Water and Sanitation',
    7: 'Affordable and Clean Energy',
    8: 'Decent Work and Economic Growth',
    9: 'Industry, Innovation and Infrastructure',
    10: 'Reduced Inequalities',
    11: 'Sustainable Cities and Communities',
    12: 'Responsible Consumption and Production',
    13: 'Climate Action',
    14: 'Life Below Water',
    15: 'Life On Land',
    16: 'Peace, Justice and Strong Institutions',
    17: 'Partnerships for the Goals',
}

export const goalsObject = {
    1: "End poverty in all its forms everywhere.",
    2: "End hunger, achieve food security and improved nutrition and promote sustainable agriculture.",
    3: "Ensure healthy lives and promote well-being for all at all ages.",
    4: "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.",
    5: "Achieve gender equality and empower all women and girls.",
    6: "Ensure availability and sustainable management of water and sanitation for all.",
    7: "Ensure access to affordable, reliable, sustainable and modern energy for all.",
    8: "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all.",
    9: "Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation.",
    10: "Reduce inequality within and among countries.",
    11: "Make cities and human settlements inclusive, safe, resilient and sustainable.",
    12: "Ensure sustainable consumption and production patterns.",
    13: "Take urgent action to combat climate change and its impacts.",
    14: "Conserve and sustainably use the oceans, seas and marine resources for sustainable development.",
    15: "Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss.",
    16: "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels.",
    17: "Strengthen the means of implementation and revitalize the Global Partnership for Sustainable Development"
}

export const sdgImgList = {
    1: goal1,
    2: goal2,
    3: goal3,
    4: goal4,
    5: goal5,
    6: goal6,
    7: goal7,
    8: goal8,
    9: goal9,
    10: goal10,
    11: goal11,
    12: goal12,
    13: goal13,
    14: goal14,
    15: goal15,
    16: goal16,
    17: goal17,
}

export const statusIconList = {
    DRAFT: draft,
    SUBMITTED: submitted,
    VALIDATED: validated,
    REJECTED: rejected,
    INASSESSMENT_UNALLOCATED: unallocated,
    INASSESSMENT_ALLOCATED: allocated,
    RETURNED: returned,
    ACCEPTED: accepted,
    SUBMITTED_TO_REGISTRY: submittedRegistry,
    APPROVED_BY_REGISTRY: approvedRegistry,
    ACTIVE: active,
    SUSPENDED: suspended,
    RETIRED: retired
}

export const calculationList = {
    'EV Charging Stations': EvChargeCalculation,

}

export const ghgUploadStatus = {
    "UPLOAD_SUCCESS": 'Upload Success',
    "UPLOAD_COMPLETED": "Upload Completed",
    "UPLOAD_FAILED": 'Upload Failed'
}

export const convertTimeformat = (dateVal) => {
    let value = dateVal ? dateVal : parseInt(moment(new Date()).format('YYYY'));
    // let value = dateVal ? dateVal : parseInt(moment(new Date()).subtract(1, 'year').format('YYYY'));
    const date = new Date(Date.UTC(value, 0, 1, 12, 0, 0));
    // Format the date to ISO 8601 format (YYYY-MM-DDTHH:mm:ssZ)
    const isoDateString = date.toISOString();
    return isoDateString;
}

export const landingPagePreferences = {
    '100': 'projects',
    '101': 'project-initiation/Internal',
    '102': 'project-initiation/External',
    '103': 'wallet-summary',
    '104': 'carbon-trading/trading',
    '105': 'carbon-trading/orders',
    '201': 'group-list',
    '202': 'location-list',
    '203': 'data-capture',
    '204': 'target-settings',
    '205': 'ghg-action-plan',
    '206': 'buy-offsets',
    '207': 'ghg-accounting',
    '208': 'target-net-zero-emission',
    '301': 'forecasted-dimming-dashboard',
    '302': 'saved-energy-dashboard',
    '303': 'dimming-manager-dashboard',
    '304': 'forecasted-lifespan-dashboard',
    '501': 'aqi-dashboard'
}

export const deepCopy = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    let copy = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            copy[key] = deepCopy(obj[key]);
        }
    }

    return copy;
}

export const faqContent = {
    'projects': projects,
    'Internal': projects,
    'External': existingProject,
    'wallet-summary': wallet,
    'trading': trading,
    'orders': trading,
    'group-list': groups,
    'location-list': locations,
    'data-capture': dataCapture,
    'approval': approvalQueue,
    'submit': submitQueue,
    'progress': progress,
    'target-settings': net0Planner,
    'ghg-action-plan': actionPlan,
    'buy-offsets': [],
    'ghg-accounting': executiveDashboard,
    'target-net-zero-emission': targetAndActual,
    'emission-insights': emissionInsights,
    'scope-1': scope1,
    'scope-2': scope2,
    'scope-3': scope3,
    'business-info': businessInfo,
    'environmental': environment,
    'social': social,
    'governance': governance,
    'energy': energy,
    'water': waters,
    'emission': emission,
    'waste': effluentsAndWaste,
    'reporting': reporting,
    'scope-configurations': scopePreference,
    'user-list': users,
    'ownership-management': subsidiaries,
    'downloads': downloads,
    'application-settings': applications,
    'forecasted-dimming-dashboard': [],
    'saved-energy-dashboard': [],
    'dimming-manager-dashboard': [],
    'forecasted-lifespan-dashboard': [],
    'aqi-dashboard': [],
}

export const faqContentId = {
    'projects': 'projects',
    'Internal': 'projects',
    'External': 'existingProjects',
    'wallet-summary': 'wallet',
    'trading': 'trading',
    'orders': 'trading',
    'group-list': 'groups',
    'location-list': 'locations',
    'data-capture': 'dataCapture',
    'approval': 'approvalQueue',
    'submit': 'submitQueue',
    'progress':'progress',
    'target-settings': 'net0Planner',
    'ghg-action-plan': 'actionPlan',
    'buy-offsets': '',
    'ghg-accounting': 'executiveDashboard',
    'target-net-zero-emission': 'targetAndActual',
    'emission-insights': 'emissionInsights',
    'scope-1': 'scope1',
    'scope-2': 'scope2',
    'scope-3': 'scope3',
    'business-info': 'businessInfo',
    'environmental': 'environment',
    'social': 'social',
    'governance': 'governance',
    'energy': 'energy',
    'water': 'waters',
    'emission': 'emission',
    'waste': 'effluentsAndWaste',
    'reporting': 'reporting',
    'scope-configurations': 'scopePreference',
    'user-list': 'users',
    'ownership-management': 'subsidiaries',
    'downloads': 'downloads',
    'application-settings': 'applications',
    'forecasted-dimming-dashboard': '',
    'saved-energy-dashboard': '',
    'dimming-manager-dashboard': '',
    'forecasted-lifespan-dashboard': '',
    'aqi-dashboard': '',
}


export const CustomFooter = ({ handleAdd }) => {
    const [count, setCount] = useState(1);


    return (
        <div className="ag-custom-footer">
            <div className="d-flex ">
                <button className="add-row-button" onClick={handleAdd}>
                    <Plus className="mr-10" color="#9da6ab" fontSize={13} height={20} width={20} strokeWidth={3} />
                    Add</button>
                <input
                    type="number"
                    className="ml-1 input-number"
                    value={count}
                    disabled
                    onChange={(e) => setCount(e.target.value)}
                />
                <h5 className="ml-1">rows</h5>
            </div>
        </div>
    )
}

export const populateFiscalYear = () => {
    const startYear = 1970, endYear = moment(new Date()).subtract(1, 'year').format('YYYY');
    const financialYears = Array.from({ length: endYear - startYear }, (_, i) => `FY ${startYear + i}`);
    return financialYears.sort((a, b) => parseInt(b.split(" ")[1]) - parseInt(a.split(" ")[1]))
}

export const populateFiscalTwoYears = () => {
    const startYear = 1970, endYear = moment(new Date()).subtract(1, 'year').format('YYYY');
    const fiscalYears = Array.from({ length: endYear - startYear }, (_, i) => `FY ${startYear + i} - ${String(startYear + i + 1).slice(2)}`);
    return fiscalYears.sort((a, b) => parseInt(b.split(" ")[1]) - parseInt(a.split(" ")[1]))
}

export const convertFloat = (value) => {
    return parseFloat(value?.toFixed(2))
}

export function hexToColorObject(hex) {
    // Remove the '#' if it's there
    hex = hex.replace(/^#/, '');

    // Convert hex to RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Calculate HSV values
    let h, s, v;

    let rNormalized = r / 255;
    let gNormalized = g / 255;
    let bNormalized = b / 255;

    let max = Math.max(rNormalized, gNormalized, bNormalized);
    let min = Math.min(rNormalized, gNormalized, bNormalized);
    let delta = max - min;

    // Hue calculation
    if (delta === 0) {
        h = 0;
    } else if (max === rNormalized) {
        h = ((gNormalized - bNormalized) / delta) % 6;
    } else if (max === gNormalized) {
        h = (bNormalized - rNormalized) / delta + 2;
    } else {
        h = (rNormalized - gNormalized) / delta + 4;
    }

    h = Math.round(h * 60);
    if (h < 0) h += 360;

    // Saturation calculation
    s = max === 0 ? 0 : (delta / max);

    // Value calculation
    v = max;

    // Create the color object
    return {
        hex: `#${hex}`,
        rgb: {
            r: r,
            g: g,
            b: b,
            a: 1
        },
        hsv: {
            h: h,
            s: s,
            v: v,
            a: 1
        }
    };
};

export const getYearFromMonth = () => {
    const financialYear = "FY 2021 - 22";
    const month = "Jan"; // Input month

    // Define the start and end months of the financial year
    const startMonth = "Apr";
    const endMonth = "Mar";

    // Map months to their numerical values for easy comparison
    const monthMap = {
        Jan: 1,
        Feb: 2,
        Mar: 3,
        Apr: 4,
        May: 5,
        Jun: 6,
        Jul: 7,
        Aug: 8,
        Sep: 9,
        Oct: 10,
        Nov: 11,
        Dec: 12,
    };

    // Extract the start and end years
    const match = financialYear.match(/(\d{4})\s*-\s*(\d{2})/);
    if (match) {
        const startYear = parseInt(match[1], 10);
        const endYear = startYear + 1;

        // Determine the year based on the month
        const inputMonthValue = monthMap[month];
        const startMonthValue = monthMap[startMonth];
        const endMonthValue = monthMap[endMonth];

        // Check if the month belongs to the first or second year of the FY
        const resultYear =
            inputMonthValue >= startMonthValue || inputMonthValue <= endMonthValue
                ? endYear
                : startYear;

        return resultYear;
    } else {
        console.log("Invalid financial year format.");
    }

}